/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
View Order Button
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       27th April 2022

*******************************************************************************************/

import React                    from 'react';
import { Button }               from '@mui/material';
import ShopIcon                 from '@mui/icons-material/Shop';
import {
    Order,
    DraggableDialog,
}                               from '.';

const noop = () => {}

const OrderDialog = ({
    open                        = false,
    orderId                     = undefined,
    deliveryId                  = undefined,
    onClose     : handleClose   = noop,
    onOk        : handleOk      = noop,
    scrollDelay                 = 1000,
    ...props
}) => {

    React.useEffect(() => {

        // Short Circuit
        if(!orderId || !deliveryId || !open)
            return;

        // Determine the delay in timout
        const timeoutDelay = Math.max(
            Number.isFinite(scrollDelay) ? scrollDelay : 1000, 0
        );

        // Run the scroll operation after timout
        const timeout = setTimeout(() => {
            const el = document.getElementById(deliveryId);
            if (el) el.scrollIntoView({ behavior: 'smooth'});
        }, timeoutDelay) 
        
        // Cleanup timout
        return () => {
            clearTimeout(timeout);
        }

    },[deliveryId, orderId, open, scrollDelay]);

    if(!orderId)
        return null;

    return (
        <DraggableDialog 
            title               = "Order Record"
            open                = {open} 
            onClose             = {handleClose} 
            onOk                = {handleOk}
            fullScreen          = {true}
            showButtonsCancel   = {false}
            disableBackdropClick
            {...props}
        >
            <Order orderId={orderId}/>
        </DraggableDialog>
    )
}

const defaultDialogProps = {};
const defaultComponentProps = {
    size        : "small",
    variant     : "text",
    color       : "secondary",
    startIcon   : <ShopIcon/>
}

export const ViewOrderButton = ({
    children, 
    orderId, 
    deliveryId      = undefined, 
    component       : Component = Button,
    componentProps  = defaultComponentProps,
    dialogProps     = defaultDialogProps,
    ...props
}) => {

    // Dialog Open or Not
    const [open, setOpen] = React.useState(false); 

    // Event handler for opening/closing dialog
    const handleDialogToggle = React.useCallback((open) => {
        return (event) => {
            if (event) event.stopPropagation();  // Prevent click event propagation
            setOpen(open);
        };
    }, []);

    if(!orderId)
        return null;

    return (
        <>
            <Component onClick={handleDialogToggle(true)} disabled={!orderId} {...componentProps} {...props}>
                {children || 'View Order'}
            </Component>
            <OrderDialog 
                open        = { open }
                orderId     = { orderId } 
                deliveryId  = { deliveryId }
                onOk        = { handleDialogToggle(false) } 
                onClose     = { handleDialogToggle(false) }
                {...dialogProps}
            />
        </>
    )
}

export default ViewOrderButton;