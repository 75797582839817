/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Photo Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                        from 'react';
import {
    Box,
}                                   from '@mui/material';

export const ExamplePhotoContainer = ({visible = true, children}) => {
    if(!visible) return null;
    return (
        <Box sx={{overflow:'hidden', mr:1, mt:1, float:'left', position:'relative'}}>
            <Box sx={{position:'relative'}}>
                {children}
            </Box>
        </Box>
    )
};

export default ExamplePhotoContainer;
