/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Admin Data Components
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
}                               from '@mui/material';
import { 
    JSONViewer,
    Title,
}                               from 'components';
import { withTranslation }      from 'components/hoc';

// Higher-order component to inject title translation and display JSON data
const withTranslatedTitleAndData = (titleKey) => withTranslation(({ t, data }) => {
    return (
        <Box>
            <Title variant="h5" component="h3">
                {t(titleKey)}
            </Title>
            <JSONViewer src={data} />
        </Box>
    );
});

// Components using the HOC with specific translation keys
const ExampleAdminRawData       = withTranslatedTitleAndData('common.rawData');
const ExampleAdminHoroscopeData = withTranslatedTitleAndData('components.example.horoscopeData');

export { 
    ExampleAdminRawData, 
    ExampleAdminHoroscopeData 
};

export default withTranslatedTitleAndData