/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Review
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th April 2022

*******************************************************************************************/
import React                            from 'react';
import { Box }                          from '@mui/material';
import { 
    DeliveryReviewAlert,
    DeliveryReview
}                                       from 'components/order';
import { useLibraryViewer }             from 'contexts';
import { withTranslation }              from 'components/hoc';

const useDeliveryId = () => {
    // DeliveryID State Variable
    const [deliveryId, setDeliveryId] = React.useState(undefined);

    // Library Viewer Context
    const { data, isAvailable}  = useLibraryViewer();

    // Side Effect, Set Delivery ID
    React.useEffect(() => {
        setDeliveryId( isAvailable ? data?.delivery?.id : undefined )
    }, [data?.delivery?.id, isAvailable])

    return deliveryId;
}


export const LibraryReviewReminder = withTranslation(({t, ...props}) => {

    const deliveryId                = useDeliveryId();
    const { isOwner, isAvailable}   = useLibraryViewer();

    if(!deliveryId)
        return null;

    if(!isOwner || !isAvailable)
        return null;

    return (
        <DeliveryReviewAlert deliveryId  = {deliveryId} />
    )
})

export const LibraryReview = withTranslation( ({t, ...props}) => {

    const deliveryId = useDeliveryId();

    // No Delivery ID, Return Nothing
    if(!deliveryId)
        return null;

    // Return the Delivery Review
    return (
        <Box id="libraryReview">
            <DeliveryReview 
                {...props} 
                title       = {t('components.library.libraryReview.ownerReview')}
                deliveryId  = {deliveryId}
            />
        </Box>
    )
})

export default LibraryReview;