/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
ExampleSEO
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2026

*******************************************************************************************/
import React                    from 'react';
import startCase                from 'lodash/startCase';
import { 
    Seo,
}                               from 'components';
import { withTranslation }      from 'components/hoc';
import { 
    ExamplesLocation, 
}                               from 'router/locations/Locations';
import { useExample }           from './ExampleContext';

const obj = {};

export const ExampleSEO = withTranslation(({t}) => {

    // Context Data
    const { data, photo, descriptor } = useExample();

    // Process name, Slug and tags
    const [name, slug, tags] = React.useMemo(() => ([
        data?.name, 
        data?.slug || data?.id, 
        data?.tags
    ]), [data?.name, data?.slug, data?.id, data?.tags])

    const toUrl = ({ slug, absolute = true } = obj) => {
        const { origin } = window.location;
        return (
            !slug
                ? origin 
                : [
                    absolute ? origin : undefined, 
                    ExamplesLocation.toUrl({ slug })
                ]
                    .filter(Boolean)
                    .join('')
        );
    }

    return (
        <Seo
            title           = { name || t('common.example') }
            description     = { 
                name 
                    ? [ 
                        t('components.example.astrologyForName', { name }),
                        descriptor
                    ].filter(Boolean).join(' - ')
                    : t('components.example.astrologyForPeopleOfInterest') 
                }
            keywords        = { 
                [ t('components.example.birthChartAnalysis'), t('components.example.celebrityHoroscopes') , name, ...(tags || [])]
                    .filter(Boolean)
                    .map(startCase)
                    .join(", ")
            }
            canonical       = { toUrl({slug, absolute: true}) }
            image           = { photo }
        />
    )
})

export default ExampleSEO;