/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Photo
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
}                               from '@mui/material';
import { 
    Skeleton,
    CardMediaWithLoading,
}                               from 'components';
import { useExample }           from './ExampleContext';

export const ExamplePhoto = ({...props}) => {
    const { data, queried, photo } = useExample();
    return (
        <>
            {
                (!queried || !photo) && 
                <Box style={{aspectRatio:'1'}}>
                    <Skeleton />
                </Box>
            }
            {
                (queried && photo) &&
                <CardMediaWithLoading
                    component           = "img"
                    src                 = {photo}
                    alt                 = {data?.name}
                    loading             = "lazy"
                    skeletonAspectRatio = "1"
                    style               = {{...props?.style}}
                    {...props}
                />
            }
        </>
    )
};

export default ExamplePhoto;