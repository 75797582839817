/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Terms and Conditons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th April 2023

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import {
    useTheme,
    Box,
    Button as ButtonMUI,
    Typography,
    FormGroup
}                                       from '@mui/material';
import { 
    Title,
    DraggableDialog,
    ContentfulRichText,
    Skeleton,
    FormAlert,
    CheckBoxWithLabel
}                                       from 'components';
import {
    withDisabledOffline
}                                       from 'components/hoc';
import {
    useContentful
}                                       from 'contexts';

const Button = withDisabledOffline(ButtonMUI);

const noop = () => {};

export const TermsAndConditionsButton = ({agreed = false, interactive = false, open = false, onOpen : handleOpen = noop, onClose : handleClose, onAgreed : handleAgreed = noop}) => {
    const theme                     = useTheme();
    const {getLegal}                = useContentful();
    
    const [queried, setQueried]     = React.useState(undefined);
    const [loading, setLoading]     = React.useState(false);
    const [data,    setData]        = React.useState(undefined);
    const [scrolled, setScrolled]   = React.useState(false);
    const [pcnt,     setPcnt]       = React.useState(0);

    const  handleScroll = React.useCallback( (e) => {
        const {scrollHeight,scrollTop,clientHeight } = e.target;
        // console.log(scrollHeight, clientHeight, scrollTop, clientHeight + scrollTop, bottom);
        const range = scrollHeight - clientHeight;
        if(range !== 0){
            const pcntComplete = Math.max(Math.min(100 * scrollTop / range ,100),0);
            setPcnt( pcntComplete )
            if(pcntComplete >= 97.5)
                setScrolled(true);
        }else {
            setScrolled(true);
        }
    },[]);

    React.useEffect(() => {
        if(open){
            setLoading(true);
            setScrolled(false);
            setPcnt(0);
            getLegal('termsAndConditons')
                .then(items => setData(items[0]))
                .finally(() => {
                    setLoading(false)
                    setQueried(moment())
                })
        }else{
            setData(undefined)
        }
    },[getLegal, open])

    return (
        <>
            <Button color={theme.palette.mode === 'dark' ? 'secondary' :'primary'} onClick={handleOpen} sx={{textTransform:'none', fontWeight:400, fontSize:'inherit', p:0}}>
                Terms and Conditions
            </Button>
            {
                open &&
                <DraggableDialog
                    open                = {open}
                    title               = {[
                        'Terms and Conditions ', 
                        interactive ? `(${pcnt.toFixed(1)}%)` : undefined
                    ].filter(Boolean).join(' ')}
                    onCancel            = {handleClose}
                    onClose             = {handleClose}
                    onOk                = {() => {
                        handleClose();
                        handleAgreed();
                    }}
                    okenabled           = {!interactive || scrolled || agreed}
                    disableBackdropClick= {!agreed && interactive}
                    showButtonsCancel   = {false}
                    width               = "md"
                    fullWidth           = {true}
                    oklabel             = {interactive && !agreed ? "I Agree" : "OK"}
                    dialogContentProps  = {{onScroll:handleScroll}}
                    progress            = {pcnt}
                >   
                    <Box mr={2}>
                        {
                            agreed && 
                            <FormAlert severity="warning">
                                You have already agreed to the following terms and conditions
                            </FormAlert>
                        }
                        { 
                            (!queried || loading || !open) && 
                            <Box width="100%" sx={{aspectRatio:'1.41'}}>
                                <Skeleton width="100%" height="100%" />
                            </Box>
                        }
                        {
                            open && queried && !loading &&
                            <ContentfulRichText content={data?.fields?.body} />
                        }
                    </Box>
                </DraggableDialog>
            }
        </>
    )
}

const obj = {};

export const TermsAndConditions = ({
    agreed                  = false, 
    onChange : handleChange = noop, 
    action                  = "placing this order",
    title                   = "Terms and Conditions",
    showCheckbox            = true,
    titleProps              = obj,
    typographyProps         = obj,
    formControlLabelProps   = obj,
}) => {

    const [open, setOpen]       = React.useState(false);
    const handleOpen            = React.useCallback( () => setOpen(true), []);
    const handleClose           = React.useCallback( () => setOpen(false), []);

    const handleCheckboxChange  = React.useCallback(e => {
        if(e.target.checked){
            handleOpen();
        }else{
            handleChange(e.target.checked)
        }    
    },[handleChange,handleOpen])

    return (
        <>
            {
                title &&
                <Title variant="h5" {...titleProps}>
                    {title}
                </Title>
            }

            <Typography variant={"body2"} {...typographyProps}>
                By {action} you agree to our <TermsAndConditionsButton agreed={agreed} interactive={showCheckbox} open={open} onOpen={handleOpen} onClose={handleClose} onAgreed={() => handleChange(true)}/>
            </Typography>

            {
                showCheckbox &&
                <FormGroup>
                    <CheckBoxWithLabel
                        checked                 = { agreed }
                        onChange                = { handleCheckboxChange }
                        formControlLabelProps   = { {
                            ...formControlLabelProps, 
                            sx : {
                                ml : 'auto', 
                                mr : 0, 
                                ...formControlLabelProps?.sx
                            }
                        }}
                    >
                        I agree to the terms and conditions
                    </CheckBoxWithLabel>
                </FormGroup>
            }
        </>
    )
}

export default TermsAndConditions;