/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
No Data
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th November 2024

*******************************************************************************************/
import React                            from 'react';
import { 
    styled,
    Box,
    Typography 
}                                       from '@mui/material';
import { withTranslation }              from './hoc';

const RelativeContainer = styled(Box)({
    position        : 'relative',
    width           : '100%'
});

const CenteredContainer = styled(Box)({
    position        : 'absolute',
    textAlign       : 'center',
    top             : '50%',
    transform       : 'translateY(-50%)',
    left            : 0,
    right           : 0
});

const obj = {};

export const NoData = withTranslation(({t, children, typographyProps = obj, ...props}) => (
    <RelativeContainer {...props}>
        <Typography component={CenteredContainer} variant="body2" { ...typographyProps } sx={{ fontWeight:400, ...typographyProps?.sx }}>
            {
                children || 
                t('common.noData')
            }
        </Typography>
    </RelativeContainer>
));

export default NoData;