/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Rodden Rating
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Typography
}                               from '@mui/material';
import { 
    Title,
    FormAlert,
    RoddenRatingDialogButton,
    RoddenRatingStamp, 
    VerticalSpaceContainer
}                               from 'components';
import { withTranslation }      from 'components/hoc';
import { useExample }           from './ExampleContext';

const noop = () => {};

export const RoddenRatingSummary = withTranslation(({t, code, name = undefined, render = noop}) => {
    return (
        <VerticalSpaceContainer>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Box display="flex">
                        <Title variant="h5" component="h3">
                            {t('common.roddenRating')}
                        </Title>
                        <Box ml={1}>
                            <RoddenRatingDialogButton />
                        </Box>
                    </Box>
                    {
                        (render && render !== noop) && 
                        render({code})
                    }
                    {
                        (!render || render === noop) &&
                        <Typography component="div">
                            { t('components.example.accuracyRoddenRating', { roddenRating : code}) }
                        </Typography>
                    }
                </Box>
                <Box sx={{ml:1}}>
                    <RoddenRatingStamp code={code}/>
                </Box>
            </Box>
            {
                /x/i.test(code) &&
                <FormAlert severity="error">
                    {
                        name 
                            ? t('components.example.roddenRatingWarningXName',{name}) 
                            : t('components.example.roddenRatingWarningXNoName')
                    }
                </FormAlert>
            }

            {
                /dd/i.test(code) &&
                <FormAlert severity="warning">
                    {t('components.example.roddenRatingWarningDD', {roddenRating : code})}
                </FormAlert>
            }    
        </VerticalSpaceContainer>
    )
});

export const ExampleRoddenRating = withTranslation(({t}) => {
    const {data} = useExample();
    if(!data?.roddenRating)
        return null;
    return (
        <RoddenRatingSummary code={data?.roddenRating} name={data?.name} />
    )
});

export default ExampleRoddenRating;