/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Vertical Grow Box
********************************************************************************************

A Box that grows in height as the child content expands. 
Once grown, it doesn't shrink unless min height is reset.

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th December 2024

*******************************************************************************************/

import React    from "react";
import { Box }  from "@mui/material";

const OBSERVER_ARGS = {  
    childList   : true, 
    subtree     : true 
};

export const VerticalGrowBox = React.forwardRef(({ children, ...props }, ref) => {

    const containerRef              = React.useRef(null);
    const observerRef               = React.useRef(null);
    const [minHeight, setMinHeight] = React.useState(0);

    // Connect Observer
    const connectObserver = React.useCallback(() => {
        if (containerRef.current && observerRef.current) {
            observerRef.current.observe(containerRef.current, OBSERVER_ARGS );
        }
    },[]);

    // Disconnect Observer
    const disconnectObserver = React.useCallback(() => {
        if (observerRef.current) {
            observerRef.current.disconnect(); // Pause the observer
        }
    },[]);

    // Reset Min Height to Zero, Disconnecting and Reconnecting the Objserver before and after.
    const resetMinHeight = React.useCallback(() => {
        disconnectObserver();
        setMinHeight(0);
        requestAnimationFrame(connectObserver);
    },[connectObserver, disconnectObserver]);

    // Expose a reset method to the parent component
    React.useImperativeHandle(ref, () => ({
        resetMinHeight,
        connectObserver,
        disconnectObserver
    }));

    // Set up Mutation Observer
    React.useEffect(() => {

        const updateMinHeight = () => {
            const container = containerRef.current;
            if (container) {
                const currentHeight = container.offsetHeight;
                setMinHeight((prevMinHeight) => currentHeight > prevMinHeight ? currentHeight : prevMinHeight);
            }
        };

        // Set the Mutation Observer
        observerRef.current = new MutationObserver(updateMinHeight);

        // Connect
        updateMinHeight();
        requestAnimationFrame(connectObserver);

        // Cleanup
        return () => {
            disconnectObserver();
        };

    }, [connectObserver, disconnectObserver]);

    return (
        <Box
            ref = {containerRef}
            {...props}
            sx = {{ ...props?.sx, minHeight: `${minHeight}px` }}
        >
            {children}
        </Box>
    );
});

export default VerticalGrowBox;