/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Glyph
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th October 2024

*******************************************************************************************/
import React                            from 'react';
import { 
    styled, 
    Tooltip, 
    CardMedia as CardMediaMUI 
}                                       from '@mui/material';
import { titleCase }                    from 'title-case';

const CardMedia = styled(CardMediaMUI)({
    mx      : 0.5,
    height  : 10,
    width   : 'initial',
    display : 'inline',
});

export const Glyph = ({src, alt, tooltip : tooltipIn = undefined, ...rest}) => {
    
    if(!src)
        return null;

    const content = (
        <CardMedia 
            component   = 'img' 
            src         = {src} 
            alt         = {alt} 
            {...rest} 
            sx          = {rest?.sx} 
        />
    );

    const tooltip = tooltipIn || alt;

    return (
        (tooltip && typeof tooltip === "string") 
            ? <Tooltip title={titleCase(tooltip)} arrow> {content} </Tooltip>
            : content
    );
}

export default Glyph;
