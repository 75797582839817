/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
use Example Viewed
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th October 2024

*******************************************************************************************/
import React                        from 'react';
import { useLocation }              from 'react-router-dom';
import {
    useNetwork,
}                                   from 'contexts';
import {
    useCancelToken,
}                                   from 'hooks';     

export const useExampleViewed = (id) => {

    const {cancelToken}                                 = useCancelToken();
    const {axios, isNetworkReady}                       = useNetwork();
    const location                                      = useLocation();
    
    const [hasViewed, setHasViewed]                     = React.useState(false);
    React.useEffect( () => {
        if(!hasViewed && isNetworkReady && id){
            axios.post(`/api/examples/${id}/view`, { cancelToken } )
                .then(() => setHasViewed(true))
                .catch()
        }
    },[axios, cancelToken, id, isNetworkReady, hasViewed]);

    // Reset `hasViewed` on material change in URL (e.g., pathname)
    React.useEffect(() => setHasViewed(false), [location.pathname]);
    
    return hasViewed
}

export default useExampleViewed;
