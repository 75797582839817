/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Chips Socials
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {titleCase}              from 'title-case';
import { useHistory}            from "react-router-dom";
import {
    Box,
    Chip,
}                               from '@mui/material';
import { 
    SocialSharePage,
    Like,
}                               from 'components';
import { 
    ExamplesCollectionLocation,
}                               from 'router/locations/Locations';
import {
    useBreakpointQueries
}                               from 'hooks';     
import { useExample }           from './ExampleContext';

export const ExampleChipsSocials = () => {
    const history   = useHistory();
    const {lgUp}    = useBreakpointQueries();
    const { data }  = useExample();
    const reference = data?.id;
    const gender    = data?.gender;
    const tags      = data?.tags || [];
    const chipData  = [
        ...(tags || []).map(tag => ({ key: tag, label: titleCase(tag), color: 'primary', param: 'tags' })),
        gender && { key: gender, label: titleCase(gender), color: 'default', param: 'gender' }
    ].filter(Boolean);

    return (
        <Box>
            <Box display="flex" width="100%" alignItems="center">
                {/* Chips Section */}
                <Box flexGrow={1} display="flex" flexWrap="wrap">
                    {
                        chipData.reduce((acc, {label, color, param, key}, ix) => {
                        acc.push(
                            <Chip
                                key     = {ix}
                                label   = {label}
                                sx      = {{ m: 0.15 }}
                                color   = {color}
                                onClick={() => {
                                    history.push(`${ExamplesCollectionLocation.path}?${param}=${key}`);
                                }}
                            />
                        );
                        return acc;
                    }, [])}
                </Box>

                {/* Like Button */}
                <Box ml={2}>
                    <Like reference={reference} size="small" />
                </Box>

                {/* SocialSharePage only visible on larger viewports */}
                {
                    lgUp && (
                    <Box ml={2}>
                        <SocialSharePage />
                    </Box>
                )}
            </Box>

            {/* SocialSharePage for smaller viewports */}
            {
                !lgUp && (
                <Box mt={2} textAlign="right">
                    <SocialSharePage />
                </Box>
            )}
        </Box>
    );
};

export default ExampleChipsSocials;