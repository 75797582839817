/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Email Preferences Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2021

*******************************************************************************************/
import React                            from 'react';
import flat                             from 'flat';
import { titleCase }                    from "title-case";
import { 
    useTheme, Box, Grid, Typography 
}                                       from '@mui/material';
import CloudUploadIcon                  from '@mui/icons-material/CloudUpload';
import {
    Form,
    Title,
    EmailConfirmationWarning,
    JSONViewer
}                                       from 'components';
import { useTranslation }               from 'contexts';
import { Checkboxes }                   from 'mui-rff';

// Topics
const topics = {
    "system" : {
        "readOnly"                      : true,
        "description"                   : "components.forms.emailPreferencesForm.topics.system.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.system.label"
    },
    "order" : {
        "readOnly"                      : false,
        "description"                   : "components.forms.emailPreferencesForm.topics.order.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.order.label"
    },
    "deliveryVirtual" : {
        "readOnly"                      : false,
        "description"                   : "components.forms.emailPreferencesForm.topics.deliveryVirtual.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.deliveryVirtual.label"
    },
    "transaction" : {
        "readOnly"                      : false,
        "description"                   : "components.forms.emailPreferencesForm.topics.transaction.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.transaction.label"
    },
    "marketing" : {
        "readOnly"                      : false,
        "description"                   : "components.forms.emailPreferencesForm.topics.marketing.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.marketing.label"
    },
    "ticket" : {
        "readOnly"                      : false,
        "description"                   : "components.forms.emailPreferencesForm.topics.ticket.description",
        "label"                         : "components.forms.emailPreferencesForm.topics.ticket.label"
    }
}

// Descriptions
const descriptions = flat({
    "system" : {
        "notifications"                 : "components.forms.emailPreferencesForm.descriptions.system.notifications",
        "emailPreferences"              : "components.forms.emailPreferencesForm.descriptions.system.emailPreferences",              
    },
    "order" : {
        "invoice"                       : "components.forms.emailPreferencesForm.descriptions.order.invoice"
    },
    "deliveryVirtual" : {
        "userInputReceived"             : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.userInputReceived",
        "userInputConfirmed"            : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.userInputConfirmed",
        "userInputCancelled"            : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.userInputCancelled",
        "userInputRequiredReminder"     : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.userInputRequiredReminder",
        "userInputConfirmationReminder" : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.userInputConfirmationReminder",
        "processing"                    : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.processing",
        "release"                       : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.release",
        "cancel"                        : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.cancel",
        "reset"                         : "components.forms.emailPreferencesForm.descriptions.deliveryVirtual.reset"
        
    },
    "transaction" : {
        "paymentReceived"               : "components.forms.emailPreferencesForm.descriptions.transaction.paymentReceived",
        "paymentFailed"                 : "components.forms.emailPreferencesForm.descriptions.transaction.paymentFailed",
        "refundIssued"                  : "components.forms.emailPreferencesForm.descriptions.transaction.refundIssued"
    },
    "marketing" : {
        "newsletter" : {
            "subscribed"                : "components.forms.emailPreferencesForm.descriptions.marketing.newsletter.subscribed"
        },
        "promotions" : {
            "subscribed"                : "components.forms.emailPreferencesForm.descriptions.marketing.promotions.subscribed"
        }
    },
    "ticket" : {
        "assigned"                      : "components.forms.emailPreferencesForm.descriptions.ticket.assigned",
        "submitted"                     : "components.forms.emailPreferencesForm.descriptions.ticket.submitted",
        "closed"                        : "components.forms.emailPreferencesForm.descriptions.ticket.closed",
        "message"                       : "components.forms.emailPreferencesForm.descriptions.ticket.message"
    }
}, { maxDepth : Infinity })


const noop              = () => {};
const defaultFormData  = {};
const defaultRawData   = {};
const defaultFormProps = {};

export const EmailPreferencesForm = ({
    formData                = defaultFormData,
    rawData                 = defaultRawData,
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    sticky                  = false,
    FormProps               = defaultFormProps,
    ...rest
}) => {

    const {t}           = useTranslation();
    const theme         = useTheme();

    const validate      = React.useCallback( (values) => {
        let errors = {};
        return errors;
    }, []);

    // Initial Values
    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)]);

    const rawDataFlat = flat(rawData, { maxDepth : Infinity });

    // Fields
    const fields    = Object.keys(flat(initialValues, { maxDepth : Infinity }));
    const groups    = fields.reduce((acc,cur) => {
        let x   = cur.split('.')
        let k   = x[0];
        let ex  = acc[k];
        return {
            ...acc,
            [k] : [...(ex || []),cur]
        }
    },{})

    return (
        <Form
            debug                   = {false}
            disabled                = {disabled}
            onSubmit                = {handleSubmit}
            onCancel                = {handleCancel}
            initialValues           = {initialValues}
            validate                = {validate}
            sticky                  = {sticky}
            successMessage          = {t('components.forms.emailPreferencesForm.successMessage')}
            {...FormProps}
            SubmitButtonProps       = {{
                startIcon : <CloudUploadIcon/>,
                ...rest.SubmitButtonProps
            }}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={1}>
                            {
                                Object.keys(groups).map((group) => {
                                    const flatData = groups[group];
                                    const {readOnly = false, hidden = false, description : translationKeyDescription = undefined, label : translationKeyLabel = undefined} = topics[group] || {};
                                    return (
                                        <React.Fragment key={group}>
                                            {
                                                !hidden && 
                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingBottom:theme.spacing(2)}}>
                                                    {
                                                        translationKeyLabel &&
                                                        <Title variant="h5" gutterBottom>
                                                            { t(translationKeyLabel) }
                                                        </Title>
                                                    }
                                                    {
                                                        translationKeyDescription && 
                                                        <Typography gutterBottom>
                                                            { t(translationKeyDescription) }
                                                        </Typography>
                                                    }
                                                    {
                                                        [...flatData]
                                                            .sort((a,b) => Object.keys(descriptions).findIndex(x => x === a) - Object.keys(descriptions).findIndex(x => x === b))
                                                            .map((name,ix) => {

                                                                // Make the lable, translate if possible
                                                                const label = descriptions[name] 
                                                                    ? t(descriptions[name]) 
                                                                    : titleCase((name || '').split('.').slice(1).join(' ')); // Remove Subject
                                                                
                                                                // Check if it is required confirmation
                                                                const nameConfirmed             = name.replace('subscribed','confirmed'); // Only Some
                                                                const requiresConfirmation      = name.includes('subscribed') && Object.keys(rawDataFlat).includes(nameConfirmed);
                                                                const isSubscribed              = requiresConfirmation && Boolean(rawDataFlat[name]);
                                                                const isConfirmed               = requiresConfirmation && Boolean(rawDataFlat[nameConfirmed]);
                                                                const showWarning               = requiresConfirmation && isSubscribed && !isConfirmed;
                                                                return (
                                                                    <Box display="flex" key={`${group}.${ix}`} ml={4}>
                                                                        <Box flexGrow={1}>
                                                                            {
                                                                                false && 
                                                                                <JSONViewer src={descriptions} /> 
                                                                            }
                                                                            <Checkboxes
                                                                                disabled    = {disabled || readOnly || showWarning}
                                                                                name        = {name}
                                                                                required    = {true}
                                                                                data        = {{label, value: true}}
                                                                            />
                                                                        </Box>
                                                                        { 
                                                                            showWarning && 
                                                                            <EmailConfirmationWarning />
                                                                        }
                                                                    </Box>
                                                                )
                                                            })
                                                    }   
                                                </Grid>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default EmailPreferencesForm;