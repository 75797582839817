/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Payment Call To Action
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th July 2023

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography, useTheme }    from '@mui/material';
import {
    Title,
    RootContainer,
    ItemPaper,
    JSONViewer,
    MakePayment
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { PayButton }                    from 'components/buttons'
import { 
    useLocale,
    useUser,
}                                       from 'contexts';
import { useOrderViewer }               from './context/OrderViewerContext';
import { useInvoices }                  from './context';


export const OrderPaymentCallToAction = withTranslation( ({t}) => {
    const {data:order}                  = useOrderViewer();
    const { 
        working,
        invoices:invoicesRaw, 
        refresh
    }                                   = useInvoices();
    const theme                         = useTheme();
    const {userId, isAuthenticated }    = useUser();
    const { 
        currency,
        currencyFactor,
        formatCurrency
    }                                   = useLocale();
    
    const invoices = React.useMemo(() => {
        return (
            (invoicesRaw || []).filter(invoice => ['OPEN', 'DRAFT'].includes(invoice.state) && invoice.amountToPay > 0)
        )
    },[invoicesRaw])

    const isOwner = React.useMemo(() => (
        isAuthenticated && userId === order?.user
    ), [isAuthenticated, order?.user, userId])

    if(!(invoices || []).length)
        return null

    return (
        <RootContainer component={ItemPaper} id="orderPaymentCallToAction">
            <Title variant="h5" gutterBottom>
                { t('components.order.orderPaymentCallToAction.paymentsRequired') }
            </Title>
            <Typography>
                { t('components.order.orderPaymentCallToAction.requiresPaymentQuantity', { quantity : invoices.length }) }
            </Typography>
            <Box display="flex" flexWrap="wrap">
                {
                    invoices.map((invoice,ix) => {
                        const {amountToPay,processing}  = invoice;
                        const payable                   = ['OPEN', 'DRAFT'].includes(invoice.state); // SHOULD BE TRUE
                        return (
                            <Box key={invoice?.id || ix} sx={{mr:2,my:1}}>
                                <MakePayment
                                    invoiceId           = {invoice?.id}
                                    title               = { t('components.order.orderPaymentCallToAction.makePayment') }
                                    currency            = {currency}
                                    amount              = {amountToPay}
                                    fixed               = {true}
                                    allowChangeDefault  = {false}
                                    onClose             = {refresh}
                                    render              = {({handleMakePayment})=>(
                                        <PayButton 
                                            disabled    = {!isOwner || working || amountToPay <= 0 || !payable || processing} 
                                            pulse       = {true} 
                                            onClick     = {handleMakePayment} 
                                            variant     = "contained" 
                                            color       = "primary" 
                                            size        = "large" 
                                            style       = {{
                                                whiteSpace  : 'nowrap',
                                                margin      : theme.spacing(-0.125)
                                            }}
                                        >
                                            { 
                                                t('components.order.orderPaymentCallToAction.payInvoice', 
                                                    { invoice : invoice?._number, amount : formatCurrency(amountToPay/currencyFactor,currency) }
                                                ) 
                                            }
                                        </PayButton>
                                    )}
                                />
                            </Box>
                        )
                    })
                }
            </Box>
            {
                false && 
                <JSONViewer src={invoices} />
            }
        </RootContainer>
    )
})


export default OrderPaymentCallToAction;