/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Comments Header
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { 
    styled,
    lighten,
    useTheme, 
    Chip, 
    Box, 
    Typography, 
    Tooltip 
}                                       from '@mui/material';
import NewReleasesIcon                  from '@mui/icons-material/NewReleases';
import SupervisorAccountIcon            from '@mui/icons-material/SupervisorAccount';
import { 
    useUser,
    useLocale,
    useCommentSubscription,
    useTranslation
}                                       from 'contexts';

// Local Components
import CommentsDetachButton             from './CommentsDetachButton';
import CommentsAlert                    from './CommentsAlert';
import PublicUserName                   from '../PublicUserName';

const noop              = () => {};

const CommentsTitleContainer = styled(Box)(({theme}) => ({
    width           : '100%',
    marginBottom    : 2,
    '& > * + *'     : {
        marginLeft : theme.spacing(0.5)
    }
}));

const TypographyHeaders = styled(Typography)(({theme}) => ({
    color : theme.palette.mode === 'light' ? theme.palette.primary.dark : lighten(theme.palette.primary.light,0.5),
    [theme.breakpoints.down('lg')] : {
            fontSize    : '0.8rem'
    },
    [theme.breakpoints.down('md')] : {
        fontSize        : '0.75rem'
    }
}));



export const CommentsHeader = ({data, loading = false, newComment = false, expandable = true, onChange : handleChange = noop, ...props}) => {
    
    const {t}                           = useTranslation();
    const theme                         = useTheme();
    const {userId }                     = useUser();
    const {formatDateTime}              = useLocale();
    const {rootId}                      = useCommentSubscription();
    const {
        metadata : {
            roles = []
        } = {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }                                   = React.useMemo(() => data?.user || {}, [JSON.stringify(data?.user)]);
    const isRoot                        = React.useMemo(() => rootId && data?.id === rootId, [data?.id, rootId]);
    const isAdmin                       = React.useMemo(() => roles.includes('admin'), [roles]);
    const isOwner                       = React.useMemo(() => Boolean(data?.user?.id && data?.user?.id === userId), [data?.user?.id, userId]);
    const isDeleted                     = React.useMemo(() => data?.deleted,    [data?.deleted]);
    const isEdited                      = React.useMemo(() => data?.edited,     [data?.edited]);
    const createdAt                     = React.useMemo(() => data?.createdAt,  [data?.createdAt]);

    return (
        <Box width="100%">
            
            {
                [data?.deleted, data?.censored, data?.moderated].some(Boolean) &&
                <Box pt={1}>
                    { 
                        data?.deleted && 
                        <CommentsAlert severity="error">     
                            { t('components.comments.deleted') }
                        </CommentsAlert> 
                    }
                    { 
                        data?.censored && 
                        <CommentsAlert severity="error"> 
                            { t('components.comments.removed') }
                        </CommentsAlert> 
                    }
                    { 
                        data?.moderated && 
                        <CommentsAlert severity="warning"> 
                            { t('components.comments.moderated') }
                        </CommentsAlert> 
                    }
                </Box>
            }

            <CommentsTitleContainer display="flex" pt={1} pb={0.25}>
                <Box> 
                    <TypographyHeaders component="span" sx={{fontWeight:400}}>
                        <PublicUserName user={data?.user} />
                    </TypographyHeaders>
                </Box>

                {
                    isEdited && 
                    <Box m="auto">
                        <TypographyHeaders style={{fontStyle:'italic',fontSize:'0.5rem'}}>
                            { t('components.comments.edited') }
                        </TypographyHeaders>
                    </Box>
                }

                {
                    isDeleted &&
                    <Box m="auto">
                        <TypographyHeaders style={{fontStyle:'italic',fontSize:'0.5rem',color:theme.palette.error.main}}>
                            { t('components.comments.deleted') }
                        </TypographyHeaders>
                    </Box>
                }

                {
                    isAdmin && !newComment &&
                    <Chip 
                        icon    = {<SupervisorAccountIcon/>} 
                        size    = "small" 
                        label   = "Admin" 
                        style   = {{transform:'translatey(-2px)'}}
                    /> 
                }

                {  
                    newComment && 
                    <Chip 
                        icon    = {<NewReleasesIcon />} 
                        size    = "small" 
                        label   = "New" 
                        color   = "primary"
                        style   = {{transform:'translatey(-2px)'}}
                    />
                }

                <Box flexGrow={1}/>

                {
                    createdAt &&
                    <Box flexShrink={1}> 
                        <Tooltip title={ t('components.comments.messagePostedAt', {timestamp:formatDateTime(moment(createdAt)) })}>
                            <TypographyHeaders sx={{fontWeight:400}}>    
                                {t('components.comments.messagePostedWhen', {when : moment.duration(moment().diff(moment(createdAt))).humanize()})}
                            </TypographyHeaders>
                        </Tooltip>
                    </Box>
                }

                {
                    !newComment && !isOwner && !isRoot &&
                    <Box flexShrink={1}>
                        <CommentsDetachButton 
                            parentId    = {data?.id} 
                            disabled    = {!expandable} 
                            onChange    = {handleChange} 
                        />
                    </Box>
                }
            </CommentsTitleContainer>
        </Box>
    )
}

export default CommentsHeader;