/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Tips
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th February 2023

*******************************************************************************************/
import React                            from 'react';
import { 
    lighten,
    alpha,
    useTheme,
    Box,
    CardMedia,
    Typography
}                                       from '@mui/material';
import MoneyIcon                        from '@mui/icons-material/AttachMoney';
import {
    Title,
    MakePayment,
    JSONViewer,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { 
    useLocale,
    useUser
}                                       from 'contexts';
import { useOrderViewer }               from './context/OrderViewerContext';
import thankyou                         from 'resources/images/thankyou.png'
import { useImageCDN } from 'hooks';

// Invoice States: DRAFT, OPEN, UNCOLLECTIBLE, PAID, VOID
const DEBUG                     = false;
const INVOICE_STATE_PAID        = "PAID";
const INVOICE_STATE_OPEN        = "OPEN";
const INVOICE_ITEM_TYPE_TIP     = "TIP";
const DEFAULT_TIP_AMOUNT        = 2000
const DEFAULT_PRESETS           = [500,1000,1500,2000,2500,5000,10000];
const DELIVERY_STATUS_COMPLETE  = "COMPLETE"
const ORDER_TRANSACTED_OPTIONS  = [ 'PAID_FULL', 'PAID_FREE', 'PAID_PARTIAL', 'REFUND_FULL', 'REFUND_PARTIAL'];

const RootContainer = ({children,...props}) => {
    const theme = useTheme();
    const bgcolor = React.useMemo(() => (
        theme.palette.mode === 'light' 
            ? lighten(theme.palette.primary.main, 0.90) 
            : alpha(theme.palette.primary.main, 0.20)
    ), [theme])

    return (
        <ItemPaper 
            id = "orderTipsContainer" 
            {...props}
            sx = {{ 
                bgcolor, 
                "& > * + *" : { mt : 2 }, 
                ...props?.sx 
            }}
        > 
            {children}
        </ItemPaper>
    )
}

export const OrderTips = withTranslation( ({t}) => {
    
    // Order Details
    const {isAuthenticated,userId}  = useUser();
    const {data:order, orderId}     = useOrderViewer();
    const convert                   = useImageCDN();

    // Locale Details
    const {currency, formatCurrency, currencyFactor} = useLocale();

    // Extract List of Tips
    const tips = React.useMemo(() => (
        (order?.items || []).filter(({__t}) => __t === INVOICE_ITEM_TYPE_TIP)
    ), [order?.items]);

    // Has Any of the Deliveries been Marked as Complete
    const isAnyComplete = React.useMemo(() => (
        Boolean((order?.deliveries || []).filter(delivery => delivery?.status === DELIVERY_STATUS_COMPLETE).length > 0)
    ), [order?.deliveries])

    // Has Order Transacted
    const hasTransacted = React.useMemo(() => (
        Boolean(order && ORDER_TRANSACTED_OPTIONS.includes(order?.paymentStatus))
    ),[order])

    // How much tips already paid
    const tipAmountStatePaid = React.useMemo(() => (
        tips
            .filter(({metadata}) => metadata?.invoice?.state === INVOICE_STATE_PAID)
            .reduce((acc,cur) => acc + (cur?.total || 0),0)
    ), [tips]);

    // How much tips are pending processing
    const tipAmountStateOpen = React.useMemo(() => (
        tips
            .filter(({metadata}) => metadata?.invoice?.state === INVOICE_STATE_OPEN)
            .reduce((acc,cur) => acc + (cur?.total || 0),0)
    ), [tips]);

    // Has Tipped, PAID
    const hasTippedPaid = React.useMemo(() => (
        Boolean(tipAmountStatePaid > 0)
    ), [tipAmountStatePaid]);

    // Has Tipped, OPEN (PROCESSING)
    const hasTippedOpen = React.useMemo(() => (
        Boolean(tipAmountStateOpen > 0)
    ), [tipAmountStateOpen])

    // Has Tipped OPEN or PAID
    const hasTipped = React.useMemo(() => (
        Boolean(hasTippedPaid /* || hasTippedOpen*/)
    ), [hasTippedPaid]);

    const isOwner = React.useMemo(() => isAuthenticated && order.user === userId, [isAuthenticated, order.user, userId])

    // No data, or not orderId provided, abort
    if(!orderId || !order) 
        return null;

    // Order has not Transacted
    if(!hasTransacted)
        return null;

    // Only Show Tip once At Least One Delivery is Complete (or if has tipped already)
    if(!isAnyComplete && !hasTipped)
        return null;

    // Render
    return (
        <RootContainer>           
            <Box display="flex">
                <Box flexGrow={1} sx={{"& > * + *" : { mt : 2}}}>

                    <Title variant="h5" gutterBottom>
                        { t('components.order.orderTips.leaveTip') }
                    </Title>

                    {
                        !hasTippedPaid && !hasTippedOpen &&
                        <Typography sx={{fontWeight:400}}>
                            { t('components.order.orderTips.leaveTipPrompt') }
                        </Typography>
                    }
                    {
                        hasTippedPaid && 
                        <Typography>
                            {t('components.order.orderTips.youHaveTipped', {currency, amount : formatCurrency(tipAmountStatePaid/currencyFactor) })}
                        </Typography>
                    }
                    {
                        hasTippedOpen && 
                        <Typography>
                            {t('components.order.orderTips.youAreProcessing', {currency, amount : formatCurrency(tipAmountStateOpen/currencyFactor) })}
                        </Typography>
                    }

                    {
                        hasTippedOpen && !hasTippedPaid &&
                        <Typography>
                            { t('components.order.orderTips.thankyouForGenerosity') }
                        </Typography>
                    }
                    {
                        hasTippedPaid &&
                        <CardMedia 
                            component   = "img"
                            src         = {convert(thankyou)} 
                            alt         = { t('components.order.orderTips.thankyouForGenerosity') }
                            sx          = {{
                                maxWidth    : 150,
                                mx          : 'auto'
                            }}
                        />
                    }

                    <MakePayment 
                        title               = { t('components.order.orderTips.leaveTip') }             // Modal Title
                        disabled            = {hasTipped || !isOwner}   // Disabled if any tip already placed, or if user is not owner of order
                        hideButton          = {hasTippedPaid}           // Hide button completely if tipped and tip has paid successfully
                        invoiceId           = {undefined}               // No Invoice, One will be created
                        orderId             = {orderId}                 // Order Specified
                        isTip               = {true}                    // Declaring payment is a tip for the order
                        fixed               = {false}                   // Allow User to Change the Amount
                        amount              = {DEFAULT_TIP_AMOUNT}      // DEFAULT
                        pulse               = {!false}                   // No Pulse Button
                        currency            = {currency}                // System Currency
                        multipleAttempts    = {false}                   // Close on Failure, Since Invoice will have been created
                        allowChangeDefault  = {false}                   // Prevent user from modifying the default payment method
                        presets             = {DEFAULT_PRESETS}

                        // Button Props
                        variant             = "contained" 
                        color               = {"primary"} 
                        size                = "large"
                        startIcon           = { <MoneyIcon/> }
                    >
                        { t('components.order.orderTips.tipThisOrder') }
                    </MakePayment>

                </Box>
            </Box>

            {
                DEBUG && 
                <JSONViewer src={order} />
            }
        </RootContainer>
    )
});

export default OrderTips;