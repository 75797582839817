/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Photo Title
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    alpha,
    Box,
}                               from '@mui/material';
import { 
    Title,
}                               from 'components';
import { withTranslation }      from 'components/hoc';

export const ExamplePhotoTitle = withTranslation( ({t, title}) => {
    if(!title)
        return null;
    return (
        <Box 
            sx={{
                position    : 'absolute',
                top         : 0,
                left        : 0,
                right       : 0,
                bgcolor     : theme => alpha(theme.palette.background.paper,0.5),
                p           : 1
            }}
        >
            <Title component="h1" align="center">
                {title || t('common.example') }
            </Title>
        </Box>
    )
});

export default ExamplePhotoTitle;