/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Descriptor
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Typography
}                               from '@mui/material';
import { 
    Title,
}                               from 'components';
import { withTranslation }      from 'components/hoc';
import { useExample }           from './ExampleContext';

export const ExampleDescriptor = withTranslation(({t}) => {
    const {data, descriptor} = useExample();
    if(!descriptor || typeof descriptor !== 'string')
        return null;
    return (
        <Box>
            <Box display="flex">
                <Title variant="h5" component="h3">
                    {t('common.summary')}
                </Title>
            </Box>
            <Typography component="div">
                {[data?.name, descriptor].filter(Boolean).join(' has ')}
            </Typography>
        </Box>
    )
});

export default ExampleDescriptor;