/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Photo Attribution
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Typography
}                               from '@mui/material';
import { 
    Linkify,
}                               from 'components';
import { withTranslation }      from 'components/hoc';
import { useExample }           from './ExampleContext';

const stripSarasStupidAttributionChevrons = x => {
    if(!x || typeof x !== 'string') return x;
    const pattern = /<((?:https?:\/\/|www\.)[^\s<]+)>/g;
    return  x.replaceAll(pattern, '$1');
}

export const ExamplePhotoAttribution = withTranslation( ({t, visible = true, compact = false}) => {
    const {data}    = useExample();
    const content   = (data?.photo && data?.photoAttribution) ? data?.photoAttribution : undefined
    if(!content || !visible)
        return null;
    return (
        <Box {...(compact ? {mt:0,ml:0} : {})}>
            <Linkify>
                <Typography sx={compact ? {fontSize:10,textAlign:'left'} : {}}>
                    {t('components.example.photoAttribution', { photoAttribution : stripSarasStupidAttributionChevrons(content) })}
                </Typography>
            </Linkify>
        </Box>
    )
});

export default ExamplePhotoAttribution;