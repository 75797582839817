/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Sample Reading
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd November 2022

*******************************************************************************************/
import React                            from 'react';
import { Box, Grid, Typography, Alert } from '@mui/material';
import {
    Title,
    Playlist,
    ProductCrossSell,
    VerticalSpaceContainer,
    JSONViewer,
    AspectsGrid,
    Radix,
    ArchiveCrossSell,
    AddToCartButton
}                                       from 'components';
import { NatalDataForm }                from 'components/forms';
import SkeletonRadix                    from 'components/skeletons/SkeletonRadix';
import { useNetwork }                   from 'contexts';
import { useCancelToken, useSize}       from 'hooks';
import { FORM_ERROR }                   from 'final-form';
import { RoddenRatingSummary }          from 'components/example/ExampleRoddenRating';

const USE_RODDEN_RATING         =  false;
const USE_GEOLOCATION_BUTTON    = true;
const BASE_API_URL              = '/api/public/preview/reading'
const FORM_PROPS                = {
    submitText              : "Generate Preview" ,
    disabledAfterSubmit     : false,
    changeRequiredToSubmit  : false,
    showSubmitButton        : true,
    showCancelButton        : false,
    SubmitButtonProps       : {
        size : "large"
    }
}

const DefaultAlertComponent = ({children}) => <Alert severity="info"> {children} </Alert>

export const SampleReading = ({
    productId           = undefined, 
    scrollToResult      = true,
    allowCrossSell      = true,
    showTitle           = true,
    showTitleResult     = true,
    showSummaryTitle    = true,  
    showSummary         = true,
    showGraphics        = true,
    natalArgs           = undefined,
    AlertComponent      = DefaultAlertComponent
}) => {

    const [parameters,  setParameters]  = React.useState(natalArgs || {roddenRating:'AA'});   
    const [result,      setResult]      = React.useState(undefined);
    const [working,     setWorking]     = React.useState(false); 
    const resultBoxRef                  = React.useRef(null);
    const { axios, isNetworkReady}      = useNetwork();
    const { cancelToken, isCancel}      = useCancelToken();

    const productIdRef                  = React.useRef(productId);
    const ref                           = React.useRef(null);
    const {width}                       = useSize(ref);

    const handleScrollToResult          = React.useCallback((offset = 100) => {
        if(!scrollToResult)
                return;
        if(resultBoxRef.current){
            const elementPosition   = resultBoxRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition    = elementPosition - offset;
            window.scrollTo({
                top         : offsetPosition,
                behavior    : 'smooth'
            });
        }
    },[scrollToResult])

    const queryUrl = React.useMemo(() => (
        [
            BASE_API_URL,
            productId ? `productId=${productId}` : undefined
        ].filter(Boolean).join('?')
    ),[productId])

    const reset = React.useCallback(() => {
        setResult(undefined);
    },[])

    const handleSubmitNatalData         = React.useCallback((formData) => {
        setParameters(formData);
        setWorking(true);
        reset();
        return new Promise((resolve) => { 
            axios
                .post(
                    queryUrl,
                    formData, 
                    {cancelToken}
                )
                .then(({data}) => {
                    setResult(data);
                    setTimeout(handleScrollToResult,200) // Will be short circuited if !scrollToResult
                    resolve({})
                })
                .catch((err)=> {
                    if(isCancel(err)) 
                        return resolve({});
                    setResult(undefined)
                    resolve({[FORM_ERROR] : err.message, ...err.errors});
                })
                .finally(() => {
                    setWorking(false);
                })
        })
    }, [reset, axios, queryUrl, cancelToken, handleScrollToResult, isCancel])

    React.useEffect(() => {
        if(natalArgs){
            handleSubmitNatalData(natalArgs);
        }
    },[handleSubmitNatalData, natalArgs])

    // Clear Result when Parameters Change
    React.useEffect(() => {
        setResult(undefined);
    },[parameters])

    // Reset when Product Changes
    React.useEffect(() => {
        if(productId !== productIdRef.current){
            productIdRef.current = productId;
            reset();
        }
    },[productId, reset]);

    // Count Records
    const [numberAvailable, numberTotal] = React.useMemo(() => {
        if (!result || working) return [0, 0];

        const {audioFiles = []} = result;
        const availableCount = audioFiles.reduce((count, file) => {
            return count + (file?.fileGetSignedUrl && file?.canListen ? 1 : 0);
        }, 0);

        return [availableCount, audioFiles.length];
    }, [result, working]);

    return (
        <React.Fragment>
            {
                showTitle &&
                <Title gutterBottom>
                    Input Natal Data
                </Title>
            }

            {
                false && 
                <JSONViewer src={parameters} />
            }

            {
                false && natalArgs && 
                <JSONViewer src={natalArgs} />
            }

            {
                !natalArgs && 
                <NatalDataForm 
                    disabled            = { working }
                    formData            = { parameters } 
                    onSubmit            = { handleSubmitNatalData }
                    useGender           = {false}
                    usePhoto            = {false}
                    useDescription      = {false}
                    useIsUser           = {false}
                    useGeolocateButton  = {USE_GEOLOCATION_BUTTON}
                    useRoddenRating     = {USE_RODDEN_RATING}
                    FormProps           = {FORM_PROPS}
                />
            }

            <Box ref={resultBoxRef} />
            
            <VerticalSpaceContainer sx={{mt:2,width:'100%'}}>
                {
                    result && showSummary &&
                    <>
                        {
                            showSummaryTitle && 
                            <Title gutterBottom>
                                Your Reading Preview
                            </Title>
                        }
                        {
                            Boolean(numberTotal > 0) && !working &&
                            <AlertComponent>
                                <Box display="flex" alignItems="center">
                                    <Box flexGrow={1}>
                                        <Typography gutterBottom fontWeight='bold'>
                                            This sample reading includes {numberAvailable} of a total of {numberTotal} audio files.
                                        </Typography>
                                        <Typography>
                                            To gain access to the complete set, please purchase a reading from our online store.
                                        </Typography>
                                    </Box>
                                    {
                                        result?.product?._id &&
                                        <Box sx={{ml:1}}>
                                            <AddToCartButton productId={result?.product?._id}/>
                                        </Box>
                                    }
                                </Box>
                            </AlertComponent>
                        }
                    </>
                }
                {
                    USE_RODDEN_RATING && result && 
                    <RoddenRatingSummary 
                        code    = {result?.parameters?.roddenRating} 
                        name    = {'your reading preview'}
                        render  = {({code}) => (
                            <Typography component="div">
                                The accuacy of the natal data for your preview is rated as {code}
                            </Typography>
                        )}
                    />
                }

                {
                    showGraphics && 
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            {
                                result &&
                                <>
                                    <Title variant='h5' gutterBottom>
                                        Radix
                                    </Title>
                                    {
                                        !isNetworkReady || working
                                            ? <SkeletonRadix />
                                            : <Radix data={result?.horoscopeData} width={width}/>
                                    }
                                </>
                            }
                            <Box ref={ref} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={8} xl={9}>
                            {
                                result &&
                                <>
                                    <Title variant='h5' gutterBottom>
                                        Aspects
                                    </Title>
                                    <AspectsGrid dataHoroscope={result?.horoscopeData} />
                                </>
                            }
                        </Grid>
                    </Grid>
                }

                {
                    (result || working) && 
                    <Box>
                        {
                            showTitleResult && result && 
                            <Title variant='h5' gutterBottom>
                                Audio Chapters
                            </Title>
                        }
                        <Playlist 
                            playlistId          = {result?.product?._id} 
                            data                = {result?.audioFiles} 
                            loading             = {working}
                            autoscroll          = {true}
                            allowComments       = {false}
                            crossSellQuantity   = {0}
                            sticky              = {false}
                            showResumeButtons   = {false}
                        />
                    </Box>
                }
                {
                    false && result &&
                    <JSONViewer src={result} />    
                }
                    {
                    false && result &&
                    <JSONViewer src={result?.audioFiles} />    
                }
                
                {
                    allowCrossSell && result?.product?._id && 
                    <Box>
                        <ProductCrossSell 
                            title               = {"Order a Reading"} 
                            productId           = {result?.product?._id} 
                            omitInCart          = {false}
                            omitProductId       = {false}
                            quantity            = {1}
                        />
                    </Box>
                }

                {
                    false && isNetworkReady &&
                    <Box mt={2}>
                        <ArchiveCrossSell title={"Examples"} quantity={5} />
                    </Box>
                }

            </VerticalSpaceContainer>
        </React.Fragment>
    )
}

export default SampleReading;