/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public User Name
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th June 2024

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { EditIconButton }       from './iconButtons';
import { Profile }              from 'views/user/settingsComponents/Profile'
import { useHistory }           from 'react-router-dom';
import { useUserPublicName }    from 'hooks';
import{
    UserSettingsLocation_TabUserProfile as targetLocation
}                               from 'router/locations';

export const PublicUserName = ({
    user : userData = undefined, 
    showEditIcon    = false, 
    ...props
}) => {
    const history                   = useHistory();
    const {
        name,
        isCurrentUser
    }                               = useUserPublicName(userData);
    const [isHovered, setIsHovered] = React.useState(false); // State to track hover status

    // Navigate to user edit
    const handleEdit        = () => { 
        if(isCurrentUser) 
            history.push(targetLocation.path)
    };

    // Mouse Enter/Exit
    const handleMouseEnter  = () => setIsHovered(true);
    const handleMouseLeave  = () => setIsHovered(false);

    // No User or Visible Name
    if(!userData || !name)
        return null;

    // Visible Name
    return (
        <Box
            component       = "span" 
            {...props} 
            onMouseEnter    = { handleMouseEnter }
            onMouseLeave    = { handleMouseLeave }
            onClick         = { handleEdit }
            style           = {{ 
                cursor      : isCurrentUser ? 'pointer' : 'default', 
                position    : 'relative', 
                display     : 'inline-block', 
                ...props?.style
            }}
        >
            { name } 
            { showEditIcon && isHovered && isCurrentUser && 
                <Profile 
                    render = {( /*{openEdit, closeEdit, editing}*/ ) => (
                        <EditIconButton 
                            IconProps   = {{fontSize : "small"}} 
                            size        = "small" 
                            color       = "primary" 
                            onClick     = {handleEdit}
                        />
                    )} 
                />
            }
        </Box>
    )
}

export default PublicUserName;