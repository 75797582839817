/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Page Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2021

*******************************************************************************************/
import { Box, styled} from '@mui/material';

// Create a styled Box component
const Root = styled(Box)(({ theme }) => ({
    flexGrow        : 1,
    display         : 'flex',
    flexDirection   : 'column',
    height          : '100%',
}));

export const ContainerWithFooter = ({ children, render, renderFooter, renderHeader }) => (
    <Root id="containerWithFooter">
        {
            renderHeader && (
                <Box>
                    {
                        typeof renderHeader === 'function' 
                            ? renderHeader() 
                            : renderHeader
                    }
                </Box>
            )
        }
        <Box flexGrow={1} sx={{ position: 'relative' }}>
            {children}
            {
                render && (
                    typeof render === 'function' 
                        ? render() 
                        : render
                    )
            }
        </Box>
        {
            renderFooter && (
                <Box>
                    { 
                        typeof renderFooter === 'function' 
                            ? renderFooter() 
                            : renderFooter
                    }
                </Box>
            )
        }
    </Root>
);

export default ContainerWithFooter;