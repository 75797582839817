/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Last queried
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       15th May 2021

*******************************************************************************************/

import React                    from 'react';
import moment                   from 'moment';
import { styled, Typography }   from '@mui/material';
import FormattedMoment          from 'components/FormattedMoment';
import { withTranslation }      from './hoc';

// Styled Typography component
const StyledTypography = styled(Typography)(({ theme }) => ({
    color       : theme.palette.text.primary,
    paddingTop  : theme.spacing(2),
    textAlign   : 'right',
}));

export const LastQueried = withTranslation(({ t : tBase, quantity = 0, timestamp = undefined, ...props }) => {

    // Convert Date to Moment
    if (timestamp instanceof Date)
        timestamp = moment(timestamp);

    // If not Moment, Short-Circuit
    if (!moment.isMoment(timestamp)) 
        return null;

    // Enforce Numeric
    quantity = Number.isFinite(quantity) && quantity >= 0 ? Math.floor(quantity) : 0;

    // Convenience wrapper
    const t = React.useMemo(() => (key,args) => tBase(`components.lastQueried.${key}`,args), [tBase]);

    // Translation prefix based on quantity
    const translationPrefix =  React.useMemo(() => (
        quantity > 1 
            ? t('recordQuantityPlural', { quantity : quantity })
            : t(
                quantity === 1 
                    ? 'recordQuantitySingle'    // quantity === 1
                    : 'recordQuantityNone'      // quantity <=  0
            )
    ),[quantity, t]);

    return (
        <StyledTypography component = "div" variant = "caption" {...props} sx={{ ...props.sx }} > 
            {translationPrefix}. <FormattedMoment prefix={t('lastQueried')} time={timestamp} />
        </StyledTypography>
    );

});

export default LastQueried;