/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Comments
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import { 
    CommentsThread,
    NoticeBlock,
}                               from 'components';
import {
    CommentsThreadProvider,
}                               from 'contexts';
import { withTranslation }      from 'components/hoc';
import { useExample }           from './ExampleContext';

export const ExampleComments = withTranslation(({t}) => {
    
    const {data} = useExample();
    
    if(!data?.id)
        return null;

    if(data?.comments){
        return (
            <CommentsThreadProvider>
                <CommentsThread 
                    reference       = {data?.id} 
                    privateThread   = {false} 
                />
            </CommentsThreadProvider>
        )
    }

    return (
        <NoticeBlock title={t('common.comments')} mt={2}>
            {t('components.example.commentsAreDisabled')}
        </NoticeBlock>
    )
});

export default ExampleComments;