/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- Use Script Hook
********************************************************************************************
Injects a script resource

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/

import React from 'react';

export const useScript = (publicFile) => {

    const [loaded, setLoaded] = React.useState(false);

    // Add the astrochart library
    React.useEffect(()=>{

        // Build the script element
        const script  = document.createElement('script');
        script.src    = publicFile;
        script.async  = true;

        // Add to the document
        document.body.appendChild(script);

        setLoaded(true);

        // Cleanup
        return () => {
            document.body.removeChild(script);
            setLoaded(false);
        }
        
    },[publicFile])

    return {
        loaded
    }
}

export default useScript;