/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Routes
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/

import React                            from 'react';
import { titleCase }                    from 'title-case';
import { Redirect }                     from 'react-router-dom';
import { ErrorAuthLocation }            from 'router/locations';
import { 
    useQueryParams,
    StringParam,
}                                       from 'hooks/useQueryParams';

const AUTHENTICATION_ERROR_TITLE = "Authentication Error";

export const AuthInterceptor = () => {

    const [ {state, error, error_description : description} ] = useQueryParams({
        state               : StringParam,
        error               : StringParam,
        error_description   : StringParam
    });
    
    const redirect = React.useMemo(() => {
        return state && error ? {
            pathname : ErrorAuthLocation.path, 
            state : {
                title   : AUTHENTICATION_ERROR_TITLE, 
                message : [error,description].filter(Boolean).map(titleCase).join(', ')
            }
        } : undefined
    }, [error, state, description])  

    if(!redirect) 
        return null;
    
    return (
        <Redirect to={redirect} />  
    )
}

export default AuthInterceptor;