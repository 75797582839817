/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Playlist Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       25th October 2022

*******************************************************************************************/
import React                    from 'react';
import { v4 as uuidv4 }         from 'uuid';
import moment                   from 'moment';
import {titleCase}              from 'title-case';
import isNil                    from 'lodash/isNil';
import pickBy                   from 'lodash/pickBy';
import {
    styled,
    alpha,
    lighten,
    darken,
    useTheme,
    useMediaQuery,
    Box,
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    Tooltip,
    Typography,
    CardMedia,
    MenuItem
}                               from '@mui/material';
import AudioFileIcon            from '@mui/icons-material/AudioFile';
import PlayIcon                 from '@mui/icons-material/PlayCircleFilled';
import PauseIcon                from '@mui/icons-material/PauseCircleFilled';
import {
    JSONViewer,
    LibraryJinkePlayer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    PublicBooksAdvertisement,
    EditLibraryButton,
    IconMenu,
    CompletionBar,
    PlayResumeOverlay,
    FastCollapse,
    COLLAPSE_DURATION
}                               from 'components';
import { withTranslation }      from './hoc';
import SkeletonPlaylist         from 'components/skeletons/SkeletonPlaylist';
// import { SkeletonFancyNoLibrariesSelected } from 'components/skeletons';

import {
    useUser,
    useNetwork,
    MasterLibraryProvider,
}                               from 'contexts';
import { 
    useCanQuery, 
    useImageCDN,
    withClickHandler,
    useStateEphemeral,
    useGlyphs
}                               from 'hooks';
import astrolabe                from 'resources/wiki/PersianAstrolabes.jpeg'

const SCROLL_BEHAVIOUR              = { 
    behavior    : 'smooth',
    block       : 'start',
    inline      : 'nearest',
}
const STORAGE_KEY                   = "playprogress";
const DURATION_FORMATTER_DEFINITION = undefined; //"hh:mm:ss"
const FEATS                         = ['angle', 'aspect', 'celestialPoint', 'celestialBody', 'celestialPoint2', 'celestialBody2', 'house', 'house2', 'sign', 'sign2'];
const ICONS_IMAGE_HEIGHT            = 12;

const arr = [];
const obj = {};

const ProgressBar = styled(Box,{
    shouldForwardProp : prop => !['pcnt'].includes(prop)
})(({theme, pcnt = 0}) => {
    const   ceA = alpha(theme.palette.primary.main,0.50), 
            ceB = alpha(theme.palette.primary.main,0.65),
            ceC = alpha(theme.palette.primary.main,0.75);
    const   ceW = 5;
    return {
        position                : 'absolute', 
        left                    : 0, 
        bottom                  : 0, 
        height                  : theme.spacing(1.0), 
        // borderTopRightRadius    : theme.spacing(0.5), 
        width                   :`${pcnt}%`, 
        //background              : theme.palette.primary.main,
        background              : `repeating-linear-gradient( 45deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
        '&:hover' : {
            background : `repeating-linear-gradient( 45deg, ${ceB} 0px, ${ceB} ${ceW}px, ${ceC} ${ceW}px, ${ceC} ${2*ceW}px)!important`
        }
    }
});

const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => !['invalid','selected','playing','lastRow','pointer'].includes(prop)
})(({theme,invalid = false, selected = false, playing = false, lastRow = false, pointer = false, unauthorized = false}) => {
    const   ceA     = alpha(theme.palette.error.light,0.50/2), 
            ceB     = alpha(theme.palette.error.light,0.65/2),
            ceC     = alpha(theme.palette.error.light,0.75/2);
    const   ceAA    = alpha(theme.palette.divider,0.05), 
            ceBB    = alpha(theme.palette.divider,0.10),
            ceCC    = alpha(theme.palette.divider,0.15);
    const   ceW     = 5;
    return {
        cursor : pointer ? 'pointer' : 'default',
        background : `${alpha(theme.palette.background.paper,0.10)}!important`,
        '&:hover' : {
            background : `${darken(theme.palette.background.paper,0.125)}!important`,
        },
        ...(invalid && {
            // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
            background : `repeating-linear-gradient( 45deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
            '&:hover' : {
                background : `repeating-linear-gradient( 45deg, ${ceB} 0px, ${ceB} ${ceW}px, ${ceC} ${ceW}px, ${ceC} ${2*ceW}px)!important`
            }
            /*
            background : alpha(theme.palette.error.light,0.50),
            '&:hover' : {
                background : `${alpha(theme.palette.error.light,0.65)}!important`,
            }
            */
        }),
        ...(!invalid && unauthorized &&{
            // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
            background : `repeating-linear-gradient( 45deg, ${ceAA} 0px, ${ceAA} ${ceW}px, ${ceBB} ${ceW}px, ${ceBB} ${2*ceW}px)`,
            '&:hover' : {
                background : `repeating-linear-gradient( 45deg, ${ceBB} 0px, ${ceBB} ${ceW}px, ${ceCC} ${ceW}px, ${ceCC} ${2*ceW}px)!important`
            }
            /*
            background : alpha(theme.palette.error.light,0.50),
            '&:hover' : {
                background : `${alpha(theme.palette.error.light,0.65)}!important`,
            }
            */
        }),
        ...(selected && !invalid && {
            // border      : `2px solid ${theme.palette.primary.light}`,
            background  : `${alpha(theme.palette.primary.light,0.5)}!important`,
            '&:hover' : {
                background  : alpha(theme.palette.primary.light,0.25),
                // background : `${darken(theme.palette.info.light,0.05)}!important`,
            }
        }),
        ...(playing && !invalid &&{
            fontWeight : 600
        }),
        ...(lastRow && {
            borderBottom : 'none'
        }) 
    }
});

const TableCell = styled(TableCellMUI)(({theme}) => ({
    verticalAlign       : 'top', 
    borderBottom        : 'inherit',
    background          : 'transparent',
    "&.MuiTableCell-root" : {
        paddingTop      : theme.spacing(1.0),
        padding         : theme.spacing(0.5),
        paddingLeft     : theme.spacing(0.5),
        paddingRight    : theme.spacing(0.5),

        fontSize: theme.typography.body1.fontSize,
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.body2.fontSize, 
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.caption.fontSize,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.caption.fontSize,
        }
    }
}));

const ChaptersContentsResume = styled(Box,{
    shouldForwardProp : prop => prop !== 'conceal'
})(({conceal = false}) => ({
    position : 'relative',
    // borderRadius : 8,
    // overflow:'hidden',
    ...(conceal && {
        // maxHeight       : 'calc(100vh - 240px)', 
        aspectRatio     : '1.41',
        overflow        : 'hidden'
    })
}));

const TypographyNotReady = styled(Typography)(({theme}) => ({
    color           : theme.palette.error.main,
    fontWeight      : 400
}));

const Gradient = styled(Box)(({theme}) => ({
    position        :'absolute',
    top             : 0,
    bottom          : 0,
    left            : 0,
    right           : 0, 
    background      : `linear-gradient(to bottom, rgba(255, 255, 255, 0.0), ${darken(theme.palette.action.active,0.75)})`
}));


const OverlayButtonContainer = styled(Box)(({theme}) => {
    const   ceA = theme.palette.mode === 'light' 
                    ? lighten(theme.palette.primary.light,0.90) 
                    : darken(theme.palette.primary.light,0.50), 
            ceB = theme.palette.mode === 'light' 
                    ? lighten(theme.palette.primary.light,0.95) 
                    : darken(theme.palette.primary.light,0.55) 
    const   ceW = 20;
    return {
        position        : 'absolute', 
        top             : 0,
        left            : 0,
        right           : 0,
        bottom          : 0,
        // backgroundImage : `url("${astrolabe}")`,
        // background      : theme.palette.background.paper,
        // background      : alpha(theme.palette.background.paper, 0.50), 
        // backdropFilter  : 'blur(4px)', 
        border          : `1px solid ${theme.palette.divider}`,
        borderRadius    : theme.spacing(1), 
        // margin          : theme.spacing(-0.5),
        // https://dev.to/snkds/how-to-create-striped-backgrounds-with-css-5dfn
        background : `repeating-linear-gradient( 135deg, ${ceA} 0px, ${ceA} ${ceW}px, ${ceB} ${ceW}px, ${ceB} ${2*ceW}px)`,
    }
});

const FeatureIconImage = styled(CardMedia, {
    shouldForwardProp : prop => prop !== 'iconHeight'
})(({iconHeight = 0}) => ({
    height          : iconHeight,
    width           : 'auto'
}));

const noop = () => {};

const FeatureIcons = ({
    metadata = obj
}) => {
    const glyphs        = useGlyphs();                          // Images State
    const featsKeys     = FEATS.filter(key => key in metadata); // The features and subset keys
    const featsArray    = React.useMemo(() => (
        featsKeys
            .map(feat => {
                let m       = metadata[feat] || {};
                let name    = (m?.name || '').toLowerCase();
                let src     = glyphs[name];
                return src && name 
                    ? [src, name] 
                    : null;
            })
            .filter(Boolean)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [featsKeys, glyphs, JSON.stringify(metadata)]);

    // No Features
    if(!Array.isArray(featsArray) || featsArray.length <= 0)
        return null

    // Render Features
    return (
        <Box display="flex" sx={{"& > * + *" : { ml : 1}}}>
            {
                featsArray.map(([src, name], ix ) => {
                    const title = name ? titleCase(name) : `Feature ${ix + 1}`;
                    return (
                        <Box key={ix}>
                            <Tooltip title={title}>
                                <FeatureIconImage 
                                    iconHeight  = {ICONS_IMAGE_HEIGHT} 
                                    component   = "img" 
                                    src         = {src} 
                                    alt         = {name} 
                                />
                            </Tooltip>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

const stripQuery = x => (x || '').split('?').shift() 

const Chapters = withTranslation( ({
    t,
    parentId        = undefined,
    playing         = false, 
    isAvailable     = false,
    autoscroll      = true,
    playList        = arr, 
    files           = arr, 
    playListIndex   = 0, 
    currentTime     = 0, 
    onClick         : handleClick, 
    onDoubleClick   : handleDoubleClick, 
}) => {
    
    const theme                         = useTheme();
    const {isAdmin}                     = useUser();
    const {allowQueryForAdmin}          = useCanQuery();
    const [fileClicked, setFileClicked] = useStateEphemeral(undefined,5000);
    const filePlaying                   = React.useMemo(() => stripQuery(playList[playListIndex]?.file), [playList, playListIndex])

    const scrollToCurrent   = React.useCallback( () => {
        if(parentId){
            const el = document.getElementById(parentId);    
            el.style.scrollMargin = '5px';
            el.scrollIntoView();
        }
        const divElement = document.getElementById('isPlaying');
        if(divElement){ 
            divElement.style.scrollMargin = '50px';
            divElement.scrollIntoView({ block : 'nearest', inline : 'start' });
        }
    }, [parentId]);

    // Scroll when playListIndex Changes
    React.useEffect(() => {
        if(fileClicked && fileClicked === filePlaying)
            return;
        if(autoscroll && playing){
            const timeout = setTimeout(scrollToCurrent, COLLAPSE_DURATION + 50);
            return () => {
                clearTimeout(timeout);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[autoscroll, playListIndex, playing, scrollToCurrent]);

    const mdDown    = useMediaQuery(theme => theme.breakpoints.down('md'));
    const lgUp      = useMediaQuery(theme => theme.breakpoints.up('lg'));
    
    return (
        <>
            <TableContainer >
                <Table>
                    <TableHead />
                    <TableBody>
                        { 
                            files.map(({fileGetSignedUrl : file, description, name, metadata, masterLibrary, duration, permission, canListen},ix) => {
                                const   baseFile    = stripQuery(file);
                                const   isThisFile  = baseFile && baseFile === filePlaying; // Comparison minus query parameters
                                const   valid       = Boolean(file);
                                const   categories  = (
                                    canListen 
                                        ? (
                                            Object.keys( 
                                                pickBy(permission, Boolean) 
                                            )
                                                .filter(x => (!(['everyone','sample'].includes(x))))
                                                .map(x=> titleCase(x.replace('owner','customer')))
                                                .join(', ') 
                                        ) : ['Client / Purchased']
                                );
                                const formattedDuration = (
                                    duration > 0 
                                        ? moment.duration(duration,'seconds').format(DURATION_FORMATTER_DEFINITION) 
                                        : ''
                                )
                                return (
                                    <>
                                        {
                                            false && 
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    {
                                                        JSON.stringify(permission)
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                        <TableRow 
                                            id              = {isThisFile ? 'isPlaying' : undefined} 
                                            onClick         = {
                                                (isAvailable || isAdmin) && file 
                                                    ? () => {
                                                        setFileClicked(baseFile);
                                                        handleClick(file);
                                                    }
                                                    : undefined
                                            } 
                                            onDoubleClick   = {
                                                (isAvailable || isAdmin) && file 
                                                    ? () => {
                                                        setFileClicked(baseFile);
                                                        handleDoubleClick(file) 
                                                    }
                                                    : undefined
                                            } 
                                            key             = {ix} 
                                            unauthorized    = {!canListen}
                                            invalid         = {canListen && !valid}
                                            selected        = {isThisFile}
                                            playing         = {isThisFile && playing}
                                            lastRow         = {ix >= files.length - 1}
                                            pointer         = {valid && (isAvailable || isAdmin)}
                                            hover 
                                            sx              = {{position:'relative'}}
                                        >
                                            <TableCell sx={{whiteSpace:'nowrap', textAlign:'right',pl:theme => `${theme.spacing(1)}!important`}}>
                                                {ix + 1}
                                            </TableCell>
                                            <TableCell sx={{px:theme => `${theme.spacing(0)}!important`}}>
                                                {
                                                    <AudioFileIcon 
                                                        sx      = {{
                                                            fontSize    : 18, 
                                                            transform   : 'translatey(2px)'
                                                        }} 
                                                        color   = {
                                                            valid 
                                                                ? (
                                                                    isThisFile 
                                                                        ? "primary" 
                                                                        : "default"
                                                                )
                                                                : !canListen ? "black" : "error"
                                                        }
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell style={{minWidth:150}} width="100%">
                                                <Typography component="div" sx={{fontSize:'inherit', fontWeight:400}}>
                                                    {description || titleCase(name)}
                                                </Typography>

                                                {
                                                    !canListen &&
                                                    <Typography component="div" sx={{fontSize:'inherit', fontStyle: 'italic', color : theme.palette.text.secondary}}>
                                                        { t('components.playlist.availableFor', { categories } )}
                                                    </Typography>
                                                }
                                                {
                                                    canListen && !valid && 
                                                    <Typography component="div" sx={{fontSize:'inherit', fontStyle: 'italic', color : theme.palette.text.secondary}}>
                                                        { t('components.playlist.topicUnavailable') }
                                                    </Typography>
                                                }
                                                {
                                                    mdDown &&
                                                    <Box sx={{mt:1}}>
                                                        <FeatureIcons metadata={metadata} />
                                                    </Box>
                                                }    
                                            </TableCell>

                                            {
                                                !mdDown &&
                                                <TableCell id="iconsCell" align="left" sx={{px:0}}>
                                                    <Box sx={{pt:1}}>
                                                        <FeatureIcons metadata={metadata} />
                                                    </Box>
                                                </TableCell>
                                            }           

                                            {
                                                (false && !mdDown) &&
                                                <TableCell sx={{whiteSpace:'nowrap',verticalAlign:'middle'}}>
                                                    {
                                                        isThisFile && isAvailable && 
                                                        <Chip 
                                                            icon    = {!playing ? <PlayIcon/> : <PauseIcon/>} 
                                                            size    = "small" 
                                                            color   = "primary" 
                                                            label   = {playing ? "Pause" : "Play"}
                                                            sx      = {{cursor:'pointer'}}
                                                        />
                                                    }
                                                </TableCell>
                                            }

                                            <TableCell align="right" sx={{verticalAlign:'middle', px:0, minWidth:lgUp ? 100 : 'initial'}}>
                                                {
                                                    isAvailable && canListen && duration > 0
                                                        ? (
                                                            isThisFile && lgUp
                                                                ? (
                                                                    <CompletionBar 
                                                                        icon            = {!playing ? <PlayIcon sx={{color:"white",fontSize : '1.5rem'}}/> : <PauseIcon sx={{color:"white" ,fontSize : '1.5rem'}}/>} 
                                                                        background      = {theme.palette.primary.light}
                                                                        color           = {theme.palette.primary.main}
                                                                        borderColor     = {null}
                                                                        pcntComplete    = {100 * currentTime/duration} 
                                                                        typographyProps = {{
                                                                            variant : 'body2',
                                                                            sx : {
                                                                                py          : 0.25,
                                                                                pl          : 0.50,
                                                                                pr          : 0.00,
                                                                                color       : theme.palette.getContrastText(theme.palette.primary.main),
                                                                                fontSize    : theme.typography.body2.fontSize 
                                                                            }
                                                                        }}
                                                                        sx = {{ 
                                                                            borderRadius    : theme.spacing(2), 
                                                                            whiteSpace      :'nowrap',
                                                                            pl              : 0.00,
                                                                            pr              : 1.00
                                                                        }}
                                                                    >
                                                                        {
                                                                            [
                                                                                currentTime < 1 
                                                                                    ? '0:00' :
                                                                                    moment.duration(currentTime,'seconds').format(DURATION_FORMATTER_DEFINITION).replace(/millisecond(s?)/g,'ms').replace(/second(s?)/g,'s') ,
                                                                                formattedDuration
                                                                            ].join(' / ')
                                                                        }
                                                                    </CompletionBar>
                                                                )
                                                                : <Chip size = "small" label = { formattedDuration } />
                                                        )
                                                        : null
                                                }
                                            </TableCell>

                                            <TableCell align="right" sx={{pl:0,pr: allowQueryForAdmin ? 'initial' : 3}} onClick={e => e.stopPropagation()}>
                                                {
                                                    allowQueryForAdmin &&
                                                    <MasterLibraryProvider>
                                                        <IconMenu>
                                                            <EditLibraryButton libraryId={masterLibrary} ButtonProps={{color:'inherit', component:MenuItem, sx:{fontSize:'0.6rem',whiteSpace:'nowrap'}}}>
                                                                {t('common.editMaster')}
                                                            </EditLibraryButton>
                                                        </IconMenu>
                                                    </MasterLibraryProvider>
                                                }
                                            </TableCell>

                                            {
                                                isThisFile &&
                                                <ProgressBar pcnt={100 * (currentTime / duration)} />
                                            }
                                        </TableRow>
                                    </>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
});

const LibraryDetails = withTranslation( ({t, currentIndex = 0, totalQuantity : quantity = 0, hasFiles = true}) => {
    const index = currentIndex + 1;
    return (
        <>
            {
                hasFiles 
                    ? t("components.playlist.currentFileIsOf", {index, quantity})
                    : t("components.playlist.currentFileIs",   {index})
            }
        </>
    )
});

const LibraryPlayingSummary = withTranslation( ({
    t, 
    loading         = true, 
    isAvailable     = false, 
    playing         = false, 
    variant         = "body2", 
    currentIndex    = 0, 
    files           = arr, 
    includeDetails  = true, 
    compact         = false
}) => {
    const totalQuantity             = React.useMemo(() => Array.isArray(files) ? files.length : 0, [files]);
    const hasFiles                  = React.useMemo(() => totalQuantity > 0, [totalQuantity]);
    const totalDuration             = React.useMemo(() => hasFiles ? files.reduce((acc,cur) => acc + ((cur?.canListen ? cur.duration : 0) || 0),0) : 0, [files, hasFiles]);
    const durationLabel             = React.useMemo(() => (
        totalDuration !== 0 
            ? (
                moment
                    .duration(totalDuration,'seconds')
                    .format(DURATION_FORMATTER_DEFINITION) 
            ) : '0:00'
    ),[totalDuration]);


    if(loading || !hasFiles)
        return null;

    return (
        <>
            {
                isAvailable &&
                <Typography component="div" variant={variant} gutterBottom>
                    { !compact
                        ? t('components.playlist.includesTopicsWithDuration', { quantity : totalQuantity, duration : durationLabel} )
                        : <Chip size="small" label={durationLabel} />
                    }
                    {
                        includeDetails && !isNil(currentIndex) && playing &&  
                        <>
                            , <LibraryDetails 
                                hasFiles            = {hasFiles}
                                currentIndex        = {currentIndex}
                                totalQuantity       = {totalQuantity}
                            />
                        </>
                    } 
                </Typography>
            }
        </>
    )
});

const AccordionMod = withClickHandler(Accordion);

const ChaptersCollapseable = withTranslation( ({
    t,
    data                                    = arr,
    autoscroll                              = true,
    playing                                 = false, 
    playList                                = arr, 
    files                                   = arr, 
    playListIndex                           = 0, 
    currentTime                             = 0, 
    onClick         : handleClick           = noop, 
    onDoubleClick   : handleDoubleClick     = noop, 
    onPlayToggle    : handlePlayToggle      = noop,
    force                                   = false,
    loading                                 = true, 
    showResumeButtons                       = false, 
    isAvailable                             = false,
    allowComments                           = false,
    crossSellQuantity                       = 5, 
    sticky                                  = true,
    ...props
}) => {
    const theme                         = useTheme();
    
    const chapters                      = React.useMemo(() => [...new Set(files.map(f => f.metadata.chapterTitle))], [files]);
    const [playInThis,  setPlayInThis]  = React.useState(false);
    const [expanded,    setExpanded]    = React.useState(false);
    const accordionRefs                 = React.useRef({}); // Array of refs to attach to each Accordion

    const handleChange                  = React.useCallback(chapter => (event, isExpanded) => setExpanded(isExpanded ? chapter : false), []);

    const hnndleEntered                 = React.useCallback((chapter) => () => {
        if(!autoscroll) return;
        if (expanded !== false && accordionRefs.current[expanded])
            accordionRefs.current[expanded].scrollIntoView(SCROLL_BEHAVIOUR);
    }, [autoscroll, expanded])

    const handleExited = React.useCallback((chapter) => () => {
        if(!autoscroll) return;
        if (!expanded && chapter && accordionRefs.current[chapter])
            accordionRefs.current[chapter].scrollIntoView(SCROLL_BEHAVIOUR);
    },[autoscroll, expanded])

    const handleToggleClick = React.useCallback( (e) => {
        e.stopPropagation();
        handlePlayToggle();
    }, [handlePlayToggle]);

    // Set Expaned if Files Index Changes, or if playing state is changed
    React.useEffect(() => {
        if(!Array.isArray(data) || playListIndex < 0 || playListIndex > playList.length - 1) 
            return;
        const allFilesIndex = (data || []).findIndex(({fileGetSignedUrl:file}) => file && file === playList[playListIndex].file)
        if(allFilesIndex >= 0){
            let ex = data[allFilesIndex]?.metadata?.chapterTitle;
            if(playing) 
                setExpanded(ex);            // Allows expanded to change based on clicking accordion
            setPlayInThis(ex);              // Retain value regardless on if expanded or not
        }else{
            setPlayInThis(false);
        }
    },[data, playing, playList, playListIndex])

    // Count Chapters and Appendixes
    const allow = React.useMemo(() => (isAvailable || force), [force, isAvailable]);

    let ch      = 0, 
        apx     = 0;

    return (
        <>
        {
            chapters.map((chapter,ix) => {
                const filesSubset   = files.filter(f => chapter && f?.metadata?.chapterTitle === chapter)
                const {metadata = undefined} = filesSubset[0] || {};
                const isAppendix    = Boolean(metadata?.appendix)
                const isPrologue    = Boolean(metadata?.prologue)
                const isEpilogue    = Boolean(metadata?.epilogue)
                const isExpanded    = chapter === expanded && !showResumeButtons;
                const isPlayInThis  = chapter === playInThis;
                const [
                    darkValue,
                    lightValue
                ]                   = isExpanded ? [0.5,0.85] : [0.5,0.85];
                const colorBase     = isPrologue || isEpilogue ? theme.palette.success : theme.palette.info;
                const background    = (
                    theme.palette.mode === 'dark' 
                        ? darken( colorBase.dark, darkValue) 
                        : lighten(colorBase.light,lightValue)
                );
                const summaryArgs   = allow ? {} : {expandIcon:null};
                // const topId         = `${uid}-${ix}`
                const chapterPrefix = isAppendix 
                    ? t('components.playlist.appendixIndex',{index : ++apx})
                    : (
                        isPrologue 
                            ? t('components.playlist.prologue') 
                            : (
                                isEpilogue 
                                    ? t('components.playlist.epilogue')
                                    : t('components.playlist.chapterIndex',{index : ++ch})
                            )
                    );
                const Icon      = playing ? PauseIcon : PlayIcon;
                const parentId  = `chapter${ix}`;
                return (
                    <AccordionMod 
                        key                     = {ix}
                        ref                     = {(el) => (accordionRefs.current[chapter] = el)}
                        expanded                = {allow && isExpanded} 
                        onChange                = {allow ? handleChange(chapter) : undefined}
                        TransitionComponent     = {FastCollapse}
                        TransitionProps         = {{ 
                            unmountOnExit   : true,
                            onEntered       : hnndleEntered(chapter),
                            onExited        : handleExited(chapter)
                        }}
                    >
                        <AccordionSummary 
                            id          = {parentId}
                            first       = {Boolean(ix === 0)}
                            last        = {Boolean(ix === chapters.length - 1)}
                            expanded    = {isExpanded}
                            background  = {background} 
                            sticky      = {sticky} 
                            {...summaryArgs} 
                            style       = {{ 
                                cursor : isAvailable ? 'pointer' : 'default'
                            }}
                        >
                            <Box display='flex' width="100%">
                                <Box flexShrink={1} sx={{my:'auto'}}>
                                    <Typography variant="body1" sx={{color:'inherit'}}>
                                        <strong>{chapterPrefix}</strong> - {chapter} {allow && <>({filesSubset.length})</>}
                                    </Typography>
                                </Box>
                                {
                                    isPlayInThis && allow &&
                                    <Box pl={1} flexShrink={1} sx={{my:'auto',transform:'translatey(3px)'}}>
                                        { <Icon onClick = {handleToggleClick} style   = {{fontSize:20}} /> }
                                    </Box>
                                }
                                <Box flexGrow={1} sx={{my:'auto'}}/>
                                {
                                    !allow && 
                                    <TypographyNotReady component="div" variant="body1">
                                        {t('components.playlist.notReadyPreparing')}
                                    </TypographyNotReady>
                                }
                                {
                                    isExpanded && 
                                    <Box flexShrink={1} sx={{my:'auto',transform:'translatey(3px)'}}>
                                        <LibraryPlayingSummary
                                            loading         = {loading}
                                            isAvailable     = {isAvailable}
                                            compact         = {true}
                                            playing         = {playing} 
                                            currentIndex    = {playListIndex} 
                                            files           = {filesSubset} 
                                            includeDetails  = {false}
                                            variant         = "body1"
                                        />
                                    </Box>
                                }
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails spacing={0}>
                            <Chapters
                                parentId        = {parentId}
                                isAvailable     = {isAvailable}
                                playing         = {playing}
                                playList        = {playList}
                                files           = {filesSubset}
                                playListIndex   = {playListIndex}
                                currentTime     = {currentTime}
                                onClick         = {handleClick} 
                                onDoubleClick   = {handleDoubleClick}
                                autoscroll      = {autoscroll} // no dup
                                allowComments   = {allowComments}
                                {...props}
                            />
                        </AccordionDetails>

                        {
                            isExpanded && (ix+1) % 3 === 0 && ix > 0 && crossSellQuantity > 0 &&
                            <Box mt={2}>
                                <PublicBooksAdvertisement quantity={crossSellQuantity} />
                            </Box>
                        }
                        
                    </AccordionMod>
                )
            })
        }
        </>
    )
});

const ChaptersCollapseableMod   = withClickHandler(ChaptersCollapseable);
const ChaptersMod               = withClickHandler(Chapters);

export const Playlist = withTranslation( ({
    t,
    playlistId                      = undefined, 
    data                            = arr, 
    component : Component           = Box, 
    componentProps                  = obj, 
    force                           = false, 
    isAvailable                     = true,
    loading                         = false,
    autoscroll                      = false,
    onAudioInstanceChange           = noop,
    onPlayChange                    = noop,
    onPlayIndexChange               = noop,
    crossSellQuantity               = 5,
    sticky                          = true,
    showResumeButtons : showOverlay = true,
}) => {

    const {userId, isAuthenticated}                 = useUser();
    const {isNetworkReady}                          = useNetwork();
    const convert                                   = useImageCDN();

    // The current time    
    const [currentTime,     setCurrentTime]         = React.useState(undefined);

    // Player Instance
    const [instance,        setInstance]            = React.useState(undefined);

    // The actual playlist
    const [playing,         setPlaying]             = React.useState(false);
    const [playList,        setPlayList]            = React.useState(undefined);
    const [playListIndex,   setPlayListIndex]       = React.useState(undefined);
    const [resumed,         setResumed]             = React.useState(false);
    const [resumeFrom,      setResumeFrom]          = React.useState(undefined);
    const [resumePristine,  setResumePristine]      = React.useState(true);

    // Assign new playlistid when component mountts, and destroy when it unmounts ..
    const [uuid, setUuid]               = React.useState(undefined);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const newId = uuidv4();
            setUuid(newId);
        }, 1000)
        return () => {
            setUuid(undefined);
            clearTimeout(timeout);
        }
    },[])

    // Check has files
    const hasFiles                                  = React.useMemo(() => Array.isArray(data) && data?.length > 0, [data]);
    const hasPlayList                               = React.useMemo(() => Array.isArray(playList) && playList.length > 0, [playList]);
    const hasChapters                               = React.useMemo(() => hasFiles && (data || []).map(f => f?.metadata?.chapterTitle).map(Boolean).every(Boolean), [data, hasFiles]);
    const files                                     = React.useMemo(() => hasFiles ? data : [], [data, hasFiles]);
    const available                                 = React.useMemo(() => isAvailable || force, [force, isAvailable]);
    const sequence                                  = React.useMemo(() => (available || hasChapters) ? files : files.slice(0,8),[available, files, hasChapters])
    const showPlayer                                = React.useMemo(() => force || (!loading && hasPlayList && available), [available, force, hasPlayList, loading]);
    const storageKey                                = React.useMemo(() => userId && isAuthenticated ? `${STORAGE_KEY}/${userId}` : STORAGE_KEY, [isAuthenticated, userId])
    const showResumeButtons                         = React.useMemo(() => showOverlay && !playing && resumeFrom && !resumed, [playing, resumeFrom, resumed, showOverlay]);

    // Overlay with resume buttons
    const showOverlayWithButtons                    = React.useMemo(() => Boolean(!loading && !resumePristine && showResumeButtons && resumeFrom && available), [available, loading, resumeFrom, resumePristine, showResumeButtons]); 

    React.useEffect(() => { onAudioInstanceChange(instance) },  [onAudioInstanceChange, instance])
    React.useEffect(() => { onPlayIndexChange(playListIndex) }, [onPlayIndexChange, playListIndex])
    React.useEffect(() => { onPlayChange(playing) },            [onPlayChange, playing])

    const handlePlayToggle = React.useCallback(() => {
        try{
            if(!instance) 
                throw new Error(t('components.playlist.noPlayerInstance'));
            playing 
               ? instance.pause() 
               : instance.play();
            // alert('toggle called')
        }catch(err){
            // silent
        }
    },[t, instance, playing]);

    const handleClick = React.useCallback(fileName => {
        if(hasPlayList){
            let ixNew = playList.findIndex(x => x.file === fileName)
            if(ixNew >= 0){
                if(ixNew !== playListIndex){
                    setPlayListIndex(ixNew);
                }
                handlePlayToggle();
            }
        }
    },[handlePlayToggle, hasPlayList, playList, playListIndex])

    // Override Jinke Styles
    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            .music-player-panel {
                z-index: 10000!important;
            }
        `
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        }
    },[showResumeButtons]);

    const removeSessionStorage = React.useCallback(() => {
        if(playlistId){
            let prev = JSON.parse(window.localStorage.getItem(storageKey));
            delete prev[playlistId];
            window.localStorage.setItem(storageKey, JSON.stringify(prev));
        }
    },[playlistId, storageKey])

    // Update Identifyer reference
    const playlistIdRef = React.useRef(playlistId);
    React.useEffect(() => { 
        playlistIdRef.current = playlistId 
    }, [playlistId])

    // Update Resume Time, without dependency on identifier
    React.useLayoutEffect(() => {
        if(playlistIdRef.current){
            const prev = JSON.parse(window.localStorage.getItem(storageKey)) || {};
            // Set Resume From
            if(!playing){
                let thisProgress = prev[playlistIdRef.current];
                setResumeFrom(
                    thisProgress 
                        ? { playListIndex : thisProgress.playListIndex, currentTime : thisProgress.currentTime }
                        : undefined
                )
            }
            // Update play progress
            else {
                window.localStorage.setItem(storageKey, JSON.stringify({...prev, [playlistIdRef.current] : {playListIndex, currentTime}}));
            }
        }
        setResumePristine(false);
    },[playing, playListIndex, currentTime, storageKey])

    // Set Resumed flag to True for this Instance
    React.useEffect(() => {
        if(playing) 
            setResumed(true);
    },[playing])

    const handleAudioPlay       = React.useCallback( () => setPlaying(true), []);
    const handleAudioPause      = React.useCallback( () => setPlaying(false), []);
    const handleAudioEnded      = React.useCallback( () => {
        removeSessionStorage();
        setPlaying(false);
    }, [removeSessionStorage]);

    const handleAudioAbort      = React.useCallback( () => setPlaying(false),   []);
    const handlePlayIndexChange = React.useCallback( (playIndex) => setPlayListIndex(playIndex), []);
    const handleAudioProgress   = React.useCallback( ({currentTime,playIndex,paused,ended}) => {
        setCurrentTime(currentTime);
        setPlayListIndex(playIndex);
        setPlaying(!paused && !ended);
    }, []);

    const handleClickPlay       = React.useCallback( () => {
        setResumed(true);
        setPlayListIndex(0);
        if(instance){
            setTimeout(() => {
                instance.currentTime = 0;
                instance.play();
            },250);
        }
    }, [instance]);

    const handleClickResume     = React.useCallback(() => {
        setResumed(true);
        setPlayListIndex(resumeFrom?.playListIndex || 0);
        setTimeout(() => {
            if(instance){
                instance.currentTime = resumeFrom?.currentTime || 0
                instance.play();
            }
        },250)
    }, [instance, resumeFrom?.currentTime, resumeFrom?.playListIndex])

    const handleClickDismissOverlay = React.useCallback(() => {
        setResumed(true);
    }, [])

    React.useEffect(() => {
        // setPlayListIndex(0); // QUERY
        setResumed(false);
        // setCurrentTime(0);
        // setPlaying(false);
    }, [playlistId])

    React.useEffect(() => {
        let hasNewData  = false, 
            playList    = [];
        if(data && playlistId){
            playList = ((isAvailable || force ? data : undefined) || [])
                .map(({fileGetSignedUrl,file,name,description,canListen}, ix) => ({
                    ix, 
                    file        : fileGetSignedUrl || file, 
                    name        : name, 
                    description : description,
                    canListen   : canListen
                }))
                .filter(({file,canListen}) => Boolean(file && canListen))
            hasNewData = playList.length > 0;
        }
        setPlaying(false);
        setPlayListIndex(hasNewData ? 0          : undefined); // QUERY
        setPlayList(hasNewData      ? playList   : undefined);
    },[data, force, playlistId, isAvailable])

    return (
        <Box width = "100%" id='playlist'>
            
            {   
                (!playlistId || loading || !isNetworkReady) && 
                <Box id="librarySetLoading" height="100%" display="flex" flexDirection="column" position="relative">
                    <Box>               
                        <SkeletonPlaylist quantity={10}/>
                    </Box>
                </Box>
            }

            {   
                playlistId && !loading && isNetworkReady &&
                <Component {...componentProps} >
                    {false && <JSONViewer src={data} />}
                    {
                        hasFiles &&
                        <Box id="chapters" sx={{scrollSnapType: "y mandatory", scrollPadding: '50%'}}>
                            <ChaptersContentsResume conceal={showOverlayWithButtons}>
                                {
                                    !isAvailable && !hasChapters &&
                                    <Gradient />
                                }
                                {
                                    hasChapters
                                        ?   <ChaptersCollapseableMod
                                                data                = { data }
                                                isAvailable         = { isAvailable }
                                                playing             = { playing }
                                                playList            = { playList }
                                                files               = { sequence }
                                                playListIndex       = { playListIndex }
                                                currentTime         = { currentTime }
                                                onClick             = { handleClick } 
                                                onDoubleClick       = { handleClick } 
                                                onPlayToggle        = { handlePlayToggle }
                                                force               = { force }
                                                showResumeButtons   = { showResumeButtons }
                                                loading             = { loading }
                                                autoscroll          = { autoscroll }
                                                crossSellQuantity   = { crossSellQuantity }
                                                sticky              = {sticky}
                                            />

                                        :   <ChaptersMod
                                                playing             = { playing }
                                                isAvailable         = { isAvailable }
                                                playList            = { playList }
                                                files               = { sequence }
                                                playListIndex       = { playListIndex }
                                                currentTime         = { currentTime }
                                                onClick             = { handleClick } 
                                                onDoubleClick       = { handleClick } 
                                                force               = { force }
                                                autoscroll          = { autoscroll }
                                            />
                                }
                                {
                                    showOverlayWithButtons &&
                                    <OverlayButtonContainer id="overlay" sx={{aspectRatio:'1.41',overflow:'hidden'}}>
                                        <CardMedia 
                                            component   = "img" 
                                            src         = {convert(astrolabe, {operation:'width',width:500})} 
                                            sx          = {{
                                                position : 'absolute', top:0, left:0, minWidth:'100%', minHeight:'100%',
                                                filter   : 'blur(1px) opacity(25%);'
                                            }}
                                        />
                                        <PlayResumeOverlay 
                                            resumeNumber    = {(resumeFrom?.playListIndex || 0) + 1} 
                                            resumeTime      = { resumeFrom?.currentTime || 0}
                                            onClickPlay     = {handleClickPlay}
                                            onClickResume   = {handleClickResume}
                                            onClickDismiss  = {handleClickDismissOverlay}
                                        />
                                    </OverlayButtonContainer>
                                }
                            </ChaptersContentsResume>    

                            {false && JSON.stringify(resumeFrom)}
                        </Box>
                    }
                </Component>
            }

            {
                playlistId && uuid && isNetworkReady && !loading && // PREVENT AUTOPLAY ON CHANGE PLAYLIST BUG ..
                <LibraryJinkePlayer 
                    hidden                  = { !showPlayer || showResumeButtons}
                    playList                = { playList }
                    playListIndex           = { playListIndex }
                    onAudioInstanceChange   = { setInstance }
                    onAudioPlay             = { handleAudioPlay}  
                    onAudioPause            = { handleAudioPause }
                    onAudioEnded            = { handleAudioEnded }
                    onAudioAbort            = { handleAudioAbort}   
                    onPlayIndexChange       = { handlePlayIndexChange }
                    onAudioProgress         = { handleAudioProgress }
                    onAudioListChange       = { handleAudioPause }
                />
            }   

            {   
                false && 
                <JSONViewer src={data} />
            }

            {
                false &&
                <JSONViewer 
                    src={{
                        showResumeButtons,
                        resumeFrom,
                        resumePristine,
                        isAvailable,
                        showOverlayWithButtons,
                        loading,
                        playing,
                        resumed,
                        force
                    }} 
                />
            }

        </Box>
    )
});

export default Playlist;