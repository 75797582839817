/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Play/Resume Overlay
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th September 2024

*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import ReplayIcon               from '@mui/icons-material/Replay';
import PlayCircleOutlineIcon    from '@mui/icons-material/PlayCircleOutline';
import CloseIcon                from '@mui/icons-material/Close';
import {
    Button,
    FatButton,
}                               from 'components';
import { withTranslation }      from './hoc';
import { CancelIconButton }     from './iconButtons';

/*
const secToTimeOld = (seconds, separator = undefined) => (
    [
        seconds / 60 / 60,
        seconds / 60 % 60,
        seconds % 60
    ]
        .map(Math.floor)
        //.map(parseInt)
        .join(separator ? separator : ':')
        .replace(/\b(\d)\b/g, "0$1")
        .replace(/^00:/,'')
);
*/

const secToTime = (seconds, separator = ':') => {

    // Check if seconds is a number; if not, set it to 0
    if (typeof seconds !== 'number' || isNaN(seconds))
        seconds = 0;
    
    // Ensure we are working with a whole number for time calculations
    const totalSeconds = Math.max(0, Math.floor(seconds))

    // Calculate hours, minutes, and seconds
    const [h, m, s] = [
        totalSeconds / 60 / 60, // Total hours
        totalSeconds / 60 % 60, // Remaining minutes
        totalSeconds % 60       // Remaining seconds
    ].map(Math.floor);          // Round down to nearest whole number

    // Build the time parts array and join them immediately with the separator or default to ''
    return [
        h > 0 ? h : undefined,
        String(m).padStart(h > 0 ? 2 : 1, '0'),
        String(s).padStart(2, '0')
    ]
    .filter(Boolean)        // Remove undefined values (when there are no hours)
    .join(separator || ''); // Use empty string if separator is null or undefined
};

const noop = () => {};

export const PlayResumeOverlay = withTranslation( ({
    t,
    resumeNumber, 
    resumeTime, 
    onClickPlay     : handleClickPlay       = noop, 
    onClickResume   : handleClickResume     = noop, 
    onClickDismiss  : handleClickDismiss    = noop,
}) => (
    <Box display="flex" flexDirection={"column"} width="100%" height="100%">
        {
            handleClickDismiss !== noop && 
            <Box sx={{position:'absolute',top:0,right:0,p:1}}>
                <CancelIconButton Icon={CloseIcon} onClick={handleClickDismiss} TooltipProps={{title:'Dismiss'}}/>
                {
                    false && 
                    <Button startIcon={<CloseIcon/>} size="small" onClick={handleClickDismiss} color={'error'}>
                        {t('common.dismiss')}
                    </Button>
                }
            </Box>
        }
        <Box flexGrow={1}/>
        <Box align="center" display="flex" width="100%">
            <Box flexGrow={1}/>
            <Box m={2}>
                <FatButton
                    icon    = {ReplayIcon}
                    onClick = {handleClickPlay}
                >
                    {t('components.playResumeOverlay.playFromTheStart')}
                </FatButton>
            </Box>
            <Box m={2}>
                <FatButton 
                    icon    = {PlayCircleOutlineIcon}
                    onClick = {handleClickResume}
                >
                    {   
                        Boolean(resumeNumber && resumeTime) &&
                        t("components.playResumeOverlay.resumeNumberFromTime", { index : resumeNumber, timeStamp : secToTime(resumeTime) })
                    }   
                </FatButton>
            </Box>
            <Box flexGrow={1}/>
        </Box>
        <Box flexGrow={1}/>
    </Box>
));

export default PlayResumeOverlay;