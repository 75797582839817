/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Icon Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       17th January 2021

*******************************************************************************************/

import React                            from 'react';
import { Tooltip, IconButton }          from '@mui/material';
import WarningIcon                      from '@mui/icons-material/Warning';
import { DraggableDialog }              from 'components';
import { withTranslation }              from 'components/hoc';

const obj   = {};

export const IconModal = withTranslation( ({
    t,
    children, 
    Icon            = WarningIcon, 
    title           = undefined, 
    tooltip         = undefined, 
    iconprops       = obj, 
    dialogprops     = obj, 
    render          = undefined, 
    iconButtonProps = obj,
    ...props
}) => {
    
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback( () => setOpen(true), []);
    const handleClose       = React.useCallback( () => setOpen(false), []);

    return (
        <IconButton {...iconButtonProps} onClick={handleOpen} size="small" >

            {
                !render && tooltip && 
                <Tooltip title={tooltip}>
                    <Icon  {...iconprops} />
                </Tooltip>
            }

            {
                !render && !tooltip && 
                <Icon {...iconprops} />
            }

            {
                render && 
                render({title, tooltip, onClick : handleOpen})
            }

            <DraggableDialog 
                open                = {open}
                title               = {title || t('components.modals.iconModal.title')} 
                showButtonsOk       = {true} 
                showButtonsCancel   = {false} 
                onOk                = {handleClose} 
                onClose             = {handleClose} 
                {...props} 
                {...dialogprops} 
            >
                {children}
            </DraggableDialog>
        </IconButton>
    )
})

export default IconModal;