/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Nakshatra Image Set Form
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th November 2023

*******************************************************************************************/
import React                            from 'react';
import { Grid }                         from '@mui/material';
import { Form, JSONViewer }             from 'components';
import { useTranslation }               from 'contexts';
import { 
    TextField,
    Autocomplete,
    showErrorOnChange   as showError,
    Switches
}                                       from 'mui-rff';
import { 
    useArtstyles, 
    useSymbolsModels
}                                       from 'hooks';

const defaultFormData = {
    _id         : undefined,
    enabled     : true,
    name        : undefined,
    style       : undefined,
    modifier    : undefined,
    model       : undefined,
}

const noop  = () => {};
const obj   = {};

const MODIFIER_OPTIONS = [
    "Artwork",
    "Photo",
    "Sketch"
];

export const NakshatraImageSetForm = ({
    formData                = defaultFormData, 
    disabled                = false,
    onSubmit : handleSubmit = noop,
    onCancel : handleCancel = noop,
    FormProps               = obj,
    editing                 = false,
}) => {

    const {data : models }      = useSymbolsModels();
    const {data : artstyles }   = useArtstyles();
    const {t}                   = useTranslation();
    const validate              = React.useCallback( (values) => {
        let errors = {};

        // Required Fields
        let requiredFields  = ['name','style','modifier', 'model'];
        requiredFields.forEach(item=>{
            if(values[item] === undefined || values[item] === '')
                errors[item] = errors[item] || t('components.forms.nakshatraGroupForm.required');
        })

        // Errors
        return errors;
    }, [t]);

    const initialValues = React.useMemo(() => ({
        ...formData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [JSON.stringify(formData)])

    const modelsOptions = React.useMemo(() => (
        Object
            .keys(models)
            .map((cur) => ({
                value : cur,
                label : models[cur]?.name || cur
            })
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [JSON.stringify(models)]);

    return (
        <Form
            debug           = {false}
            disabled        = {disabled}
            onSubmit        = {handleSubmit}
            onCancel        = {handleCancel}
            initialValues   = {initialValues}
            validate        = {validate}
            showObjectId    = {false}
            {...FormProps}
            render          = {({disabled, form, error, dirtySinceLastSubmit, submitFailed, submitSucceeded, errors, handleSubmit, values, ...rest}) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container>
                            {
                                false &&
                                <Grid item xs={12}>
                                    <JSONViewer src={models} />
                                </Grid>
                            }
                            <Grid item xs={9}>
                                <Autocomplete
                                    name                    = "model"
                                    label                   = { t('components.forms.nakshatraGroupForm.model') }
                                    helperText              = { t('components.forms.nakshatraGroupForm.selectRenderModel') }
                                    options                 = {modelsOptions}
                                    disabled                = {disabled}
                                    getOptionValue          = {option => option.value}
                                    getOptionLabel          = {option => option?.label}
                                    isOptionEqualToValue    = {(option,value) => option.value === value?.value }
                                    showError               = {showError}
                                    freeSolo                = {false}
                                    textFieldProps          = {{
                                        InputLabelProps : { 
                                            shrink: true 
                                        }
                                    }}
                                />  
                            </Grid>
                            <Grid item xs={3}>
                                <Switches
                                    disabled    = {disabled} 
                                    name        = "enabled"
                                    data        = {{
                                        label   : values.enabled
                                            ? t('components.forms.nakshatraGroupForm.enabled') 
                                            : t('components.forms.nakshatraGroupForm.disabled'), 
                                        value   : true
                                    }}
                                />
                            </Grid>
                            
                            <Grid item xs={6}>
                                <Autocomplete
                                    name                    = "modifier"
                                    label                   = { t('components.forms.nakshatraGroupForm.modifier') }
                                    helperText              = { t('components.forms.nakshatraGroupForm.immutableOnceSet') }
                                    options                 = {MODIFIER_OPTIONS}
                                    disabled                = {disabled || editing}
                                    showError               = {showError}
                                    freeSolo                = {true}
                                    textFieldProps          = {{
                                        InputLabelProps : { 
                                            shrink: true 
                                        }
                                    }}
                                />  
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    name                    = "style"
                                    label                   = { t('components.forms.nakshatraGroupForm.style') }
                                    helperText              = { t('components.forms.nakshatraGroupForm.immutableOnceSet') }
                                    options                 = {artstyles}
                                    disabled                = {disabled || editing}
                                    showError               = {showError}
                                    freeSolo                = {true}
                                    textFieldProps          = {{
                                        InputLabelProps : { 
                                            shrink: true 
                                        }
                                    }}
                                />  
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name                    = "name"
                                    label                   = { t('components.forms.nakshatraGroupForm.name') }
                                    disabled                = {disabled}
                                    showError               = {showError}
                                />  
                            </Grid>
                        </Grid>
                    </form>
                )
            }}
        />
    )
}

export default NakshatraImageSetForm;