/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use isTouch Screen Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd August 2022

*******************************************************************************************/
import React from 'react';

export const useIsTouchScreen = () => {
  const isTouch = React.useMemo(() => {
    try {

      // Use `window` to ensure the environment is client-side
      if (typeof window !== 'undefined' && 'ontouchstart' in window) {
        return true; // This property exists for touch screens
      }

      // Fall back to creating a TouchEvent if the property doesn't exist
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  return isTouch;
};

export default useIsTouchScreen;