/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Review
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       4th December 2021

*******************************************************************************************/
import React                    from 'react';
import { 
    styled,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    Typography
}                               from '@mui/material';
import HelpIcon                 from '@mui/icons-material/Help';

import { useTranslation }       from 'contexts';
import { ItemPaper }            from './ItemPaper';
import { Linkify }              from './Linkify';
import { IconModal }            from './modals';

export const RODDEN_CLASSIFICATION_DATA = [
    {code : "AA", summary : "components.roddenRating.summary.AA", unknownTime:false, unknownDate:false, translationKey: "components.roddenRating.description.AA" },
    {code : "A",  summary : "components.roddenRating.summary.A" , unknownTime:false, unknownDate:false, translationKey: "components.roddenRating.description.A"  },
    {code : "B",  summary : "components.roddenRating.summary.B" , unknownTime:false, unknownDate:false, translationKey: "components.roddenRating.description.B"  },
    {code : "C",  summary : "components.roddenRating.summary.C" , unknownTime:false, unknownDate:false, translationKey: "components.roddenRating.description.C"  },
    {code : "DD", summary : "components.roddenRating.summary.DD", unknownTime:false, unknownDate:false, translationKey: "components.roddenRating.description.DD" },
    {code : "X",  summary : "components.roddenRating.summary.X" , unknownTime:true,  unknownDate:false, translationKey: "components.roddenRating.description.X"  },
    {code : "XX", summary : "components.roddenRating.summary.XX", unknownTime:true,  unknownDate:true,  translationKey: "components.roddenRating.description.XX" },
];

export const RODDEN_CODES = RODDEN_CLASSIFICATION_DATA.map(x => x.code);

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'header'
})(({theme, header = false}) => ({
    fontSize        : '0.65rem',
    padding         : theme.spacing(0.5),
    paddingTop      : 2,
    paddingBottom   : 2, 
    verticalAlign : 'top',
    ...( header && {
        fontWeight : 400
    })
}));

const CodeContainer = styled(Box, {
    shouldForwardProp : prop => prop !== 'code'
})(({theme, code = undefined}) => {
    const COLORS = {
        'AA'    : '#A65CCE',//theme.palette.success.light,
        'A'     : '#424493',//A[300],
        'B'     : '#54C1D5',//theme.palette.info.light,
        'C'     : '#29B135',//theme.palette.warning.light,
        'DD'    : '#F4CD0C',//theme.palette.error.light,
        'X'     : '#B57A04',//theme.palette.divider,
        'XX'    : '#E14D12',//theme.palette.divider
    };
    const background = (code ? COLORS[code] : undefined) || 'inherit';
    return {
        borderRadius    : theme.spacing(1),
        padding         : theme.spacing(1),
        paddingTop      : theme.spacing(0.5),
        paddingBottom   : theme.spacing(0.5),
        width           : 'fit-content',
        minWidth        : 75,
        aspectRatio     : '1.67',
        textAlign       : 'center',
        border          : '2px solid black',
        fontSize        : '2rem',
        fontWeight      : 400,
        background      : background,
        //color           : 'black',
        color           : theme.palette.getContrastText(background)
    }
})

export const RoddenRatingStampSmall = ({code = undefined}) => {
    if(!RODDEN_CODES.includes(code))
        return null;
    return (
        <CodeContainer code={code} sx={{fontSize:'0.75rem',minWidth:40,padding:0.25,border:'1px solid black'}}>
            {code}
        </CodeContainer>
    )
}

export const RoddenRatingStamp = ({code = undefined}) => {
    if(!RODDEN_CODES.includes(code))
        return null;
    return (
        <CodeContainer code={code}>
            {code}
        </CodeContainer>
    )
}

const RoddenRatingTable = () => {
    const {t} = useTranslation();
    return (
        <ItemPaper sx={{"& > * + *" : {mt : 1}}}>
            <Typography variant="body2">
                {t('components.roddenRating.table.introduction')}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell header={true}>
                                {t('components.roddenRating.table.code')}
                            </TableCell>
                            <TableCell header={true}>
                                {t('components.roddenRating.table.summary')}
                            </TableCell>
                            <TableCell header={true}>
                                {t('components.roddenRating.table.description')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            RODDEN_CLASSIFICATION_DATA.map(({code,summary,translationKey},ix) => (
                                <TableRow key={ix}>
                                    <TableCell>
                                        <RoddenRatingStampSmall code={code} />
                                    </TableCell>
                                    <TableCell>
                                        {t(summary)}
                                    </TableCell>
                                    <TableCell sx={{textAlign:'justify'}}>
                                        {t(translationKey)}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box align="right" sx={{fontSize:'0.6rem'}}>
                <Linkify>
                    { t('components.roddenRating.reference', { reference : "https://www.astro.com/astro-databank/Help:RR" } ) }
                </Linkify>
            </Box>
        </ItemPaper>
    )
}

export const RoddenRatingDialogButton = ({Icon = HelpIcon, render = undefined,...props}) => {
    const {t} = useTranslation();
    return (
        <IconModal 
            {...props}
            Icon            = {Icon} 
            title           = { t('components.roddenRating.title') } 
            maxWidth        = {'md'} 
            fullWidth       = {true}
            render          = {render}
        >
            <RoddenRatingTable />
        </IconModal>
    )
}

export default RoddenRatingDialogButton;