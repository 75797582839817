/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use Breakpoint QUeries
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st October 2024

*******************************************************************************************/
import {
    useMediaQuery, 
    useTheme
}   from '@mui/material';

const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];

// Helper function to create media query hooks
const createQueries = (breakpoints, theme) => {
    const queries = {};
    breakpoints.forEach(bp => {
        queries[`${bp}Up`]      = useMediaQuery(theme.breakpoints.up(bp));
        queries[`${bp}Down`]    = useMediaQuery(theme.breakpoints.down(bp));
    });
    return queries;
};
  
export const useBreakpointQueries = () => {
    const theme             = useTheme();
    const breakPointQueries = createQueries(BREAKPOINTS, theme);
    return breakPointQueries
}

export default useBreakpointQueries;