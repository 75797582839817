
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Review Summary
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th April 2022

*******************************************************************************************/
import React                    from 'react';
import { 
    Box, 
    Typography 
}                               from '@mui/material';
import config                   from '../../config';
import { 
    StarRating,
    LoadingData
}                               from 'components';
import { withTranslation }      from '../hoc';
import { 
    useNetwork,
    useUser
}                               from 'contexts';

const STAR_STEPS    = config.reviews.scoreMax;

export const DeliveryReviewSummary = withTranslation( ({t, deliveryId, compact=false, ...props}) => {
    const {
        axios, 
        cancelToken, 
        isNetworkReady,
        socketUsers : socket
    }                                   = useNetwork();
    const { isAuthenticated }           = useUser();
    const [ working,    setWorking]     = React.useState(false);
    const [ review,     setReview]      = React.useState(undefined);
    const resetReview                   = React.useCallback( () => setReview(undefined), []);

    /**
     * Function to query the review
     */
    const queryReview = React.useCallback(() => {
        if(deliveryId && isNetworkReady && isAuthenticated){
            setWorking(true);
            axios.get(`/api/product/review/delivery/${deliveryId}?origin=deliveryReviewSummary`, {cancelToken})
                .then(({data}) => data)
                .then(setReview)
                .catch(resetReview)
                .finally(() => {
                    setTimeout(() => setWorking(false), 500)
                })
        }else{
            resetReview();
        }
    },[axios, cancelToken, deliveryId, isAuthenticated, isNetworkReady, resetReview]);

    // Query the review
    React.useEffect(queryReview,[queryReview]);

    // Re-Query if Receive Push Notice
    React.useEffect(() => {
        if(socket){
            const handleRefresh = (id) => {
                if(id && id === deliveryId)
                    queryReview()
            }
            socket.on('refresh_delivery_review', handleRefresh);
            return () => {
                socket.off('refresh_delivery_review', handleRefresh);
            }
        }
    }, [deliveryId, queryReview, socket])

    if(working)
        return <LoadingData/>
    
    return (
        <Box display="flex">
            {   
                !review && !compact &&
                <Box mr={0.5} sx={{my:'auto'}}>
                    <Typography variant="body2"> 
                        {t('components.deliveryReviewSummary.noReview')}
                    </Typography>
                </Box>
            }
            <Box>
                <StarRating 
                    steps       = {STAR_STEPS}
                    align       = "center" 
                    size        = {25} 
                    disabled    = {true} 
                    showTips    = {false} 
                    value       = {
                        review 
                            ? review?.scoresAverage || 0
                            : 0
                    }
                    {...props}
                />
            </Box>
        </Box>
    )
})

export default DeliveryReviewSummary;