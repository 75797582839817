
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
LibraryHoroscopeData Context
********************************************************************************************
Boilerplate context, consumer, provider and hook

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       2nd November 2024

*******************************************************************************************/
import React                            from 'react';
import { 
    useLibrary,
    useUser,
    useNetwork
}                                       from 'contexts';
import { useLibraryViewer }             from 'contexts';

// The Product Context 
const LibraryHoroscopeDataContext = React.createContext({});

// LibraryHoroscopeData Provider
const LibraryHoroscopeDataProvider = ({ children }) => {

    const {isNetworkReady}              = useNetwork();
    const {ready}                       = useUser();
    const {getHoroscopeDataById}        = useLibrary();
    const {libraryId}                   = useLibraryViewer();

    const [loading,     setLoading]     = React.useState(true);
    const [data,        setData]        = React.useState(undefined);

    const clear                         = React.useCallback(() => setData(undefined), []);

    // Refresh Data
    const refresh = React.useCallback( (reset = true) => {
        setLoading(true);
        if(isNetworkReady && ready && libraryId){
            if(reset) 
                clear();
            getHoroscopeDataById(libraryId)
                .then(setData)
                .catch(clear)
                .finally(() => {
                    setTimeout(() => setLoading(false), 100);
                });
        }else{
            clear();
        }
    },[clear, getHoroscopeDataById, isNetworkReady, libraryId, ready])

    // Execute Refresh when it is redefined
    React.useEffect(refresh, [refresh]);

    // Context values
    const value = {
        loading,
        data,
    };

    return (
        <LibraryHoroscopeDataContext.Provider value={value}>
            {children}
        </LibraryHoroscopeDataContext.Provider>
    )
}

// LibraryHoroscopeData Consumer
const LibraryHoroscopeDataConsumer = ({children}) => {
    return (
        <LibraryHoroscopeDataContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('LibraryHoroscopeDataConsumer must be used within LibraryHoroscopeDataProvider');
                }
                return children(context)
            }}
        </LibraryHoroscopeDataContext.Consumer>
    )
}

// useLibraryHoroscopeData Hook
const useLibraryHoroscopeData = () => {
    const context = React.useContext(LibraryHoroscopeDataContext);
    if(context === undefined)
        throw new Error('useLibraryHoroscopeData must be used within LibraryHoroscopeDataProvider');
    return context;
}

export {
    LibraryHoroscopeDataProvider,
    LibraryHoroscopeDataConsumer,
    useLibraryHoroscopeData
}