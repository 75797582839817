/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
CheckBox with Label
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th September 2024

*******************************************************************************************/
import React                            from 'react';
import {
    styled,
    Checkbox as CheckboxMUI,
    FormControlLabel,
    Typography,
}                                       from '@mui/material';

const Checkbox = styled(CheckboxMUI)(({theme, padding = 0}) => ({
    padding : theme.spacing(padding)
}));

const CheckBoxLabel = styled(Typography)(({fontSize = 10}) => ({
    fontSize
}));

const noop = () => {};

const obj = {};

export const CheckBoxWithLabel = ({
    disabled                = false,
    checked                 = false,
    onChange : handleChange = noop,
    formControlLabelProps   = obj,
    checkBoxProps           = obj,
    checkBoxLabelProps      = obj,
    fontSize                = 'inherit',
    padding                 = 0,
    ...props
}) => {
    
    // Deconstruct props, and checkBoxLabelProps
    const { children = undefined, ...restProps } = props;
    const { children : checkBoxLabelChildren = undefined, ...restCheckBoxLabelProps } = checkBoxLabelProps

    return (
        <FormControlLabel
            {...formControlLabelProps}
            control = { 
                <Checkbox
                    {...restProps}
                    {...checkBoxProps}
                    disabled    = { Boolean(disabled) } 
                    checked     = { Boolean(checked) } 
                    onChange    = { handleChange }
                    padding     = { padding } // Passed to the styled component
                    sx          = {{
                        ...restProps?.sx,
                        ...checkBoxProps?.sx
                    }}
                /> 
            }
            label = {
                <CheckBoxLabel 
                    {...restCheckBoxLabelProps} 
                    fontSize = { fontSize } // Passed to the styled component
                    sx = {{ml : 0.5, ...restCheckBoxLabelProps?.sx}}
                >
                    {children || checkBoxLabelChildren}
                </CheckBoxLabel>
            }
        />
    )
}

export default CheckBoxWithLabel;