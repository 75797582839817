/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Generate Reading
********************************************************************************************
Admin Only

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th October 2022

*******************************************************************************************/
import React                        from 'react';
import { titleCase }                from 'title-case';
import { 
    pick, 
    isEqual, 
    isEmpty, 
    isNull, 
    isUndefined
}                                   from 'lodash';
import moment                       from 'moment';
import { 
    Link,
}                                   from 'react-router-dom'
import {
    styled,
    useTheme,
    Box,
    Checkbox,
    Chip,
    Grid,
    Paper as PaperMUI,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    TableContainer,
    alpha
}                                   from '@mui/material';
import CheckIcon                    from '@mui/icons-material/CheckCircleOutline';
import PlayArrowIcon                from '@mui/icons-material/PlayArrow';
import PauseIcon                    from '@mui/icons-material/Pause';
import UndoIcon                     from '@mui/icons-material/Undo';
import MergeTypeIcon                from '@mui/icons-material/MergeType';
import CheckBoxIcon                 from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon     from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon    from '@mui/icons-material/IndeterminateCheckBox';
import ReportProblemIcon            from '@mui/icons-material/ReportProblem';
import { AdminLibraryLocation }     from 'router/locations';
import { 
    RootContainer,
    Button,
    ConfirmationButton,
    RefreshIcon,
    FormAlert,
    Radix,
    ProductCard,
    JSONViewer,
    SkeletonProductCard as SkeletonProductCardRaw,
    NatalSummary,
    SkeletonPlaylist as SkeletonPlayListRaw,
    CopyField,
    NotFound,
    PickReadingVariables,
    EditLibraryButton
}                                   from 'components';
import {LibraryJinkePlayer}         from 'components/library/LibraryJinkePlayer';
import { 
    useNatalData, 
    useProduct,
    useNetwork,
    useUser,
    MasterLibraryProvider
}                                   from 'contexts';
import { 
    useStateEphemeral, 
    useSize
}                                   from 'hooks';
import {
    withFeatureTypography
}                                   from 'components/hoc'
import { withTranslation }          from './hoc';

const SkeletonProductCard   = withFeatureTypography(SkeletonProductCardRaw);
const SkeletonPlaylist      = withFeatureTypography(SkeletonPlayListRaw);

const SelectProductReminder = withTranslation( ({t}) => (
    <Typography align="center">
        {t('components.generateReading.selectProductPrompt')}
    </Typography>
));

const SelectPlaylistReminder = withTranslation( ({t}) => (
    <Typography align="center">
        {t('components.generateReading.selectPlaylistPrompt')}
    </Typography>
));

const prettySeconds = (seconds,trim = true) => (
    moment
        .duration(seconds, "seconds")
        .format("hh:mm:ss", { trim })
);

const defaultNatalRecordData = {
    lat             : undefined,
    lng             : undefined,
    address         : undefined,
    birthDateTime   : undefined,
    unknownTime     : undefined,
    localTime       : undefined,
    gender          : undefined,
    roddenRating    : 'AA'
};

const defaultProductData = {
    id              : undefined,
};

const defaultZodiacData = {
    zodiac          : "tropical"
}

const defaultHouseSystemData = {
    houseSystem     : "auto"
}


const TableRow = styled(TableRowMUI,{
    shouldForwardProp : prop => prop !== 'current' && prop !== 'hasFile'
})(({theme, current = false, hasFile = false}) => ({
    ...(hasFile && {
        '&:hover' : {
            background : [alpha(theme.palette.secondary.main,0.15),'!important']
        },
    }),
    ...(current && {
        background : alpha(theme.palette.secondary.main,0.25),
        '&:hover' : {
            background : [alpha(theme.palette.secondary.main,0.35),'!important']
        }
    })
}))

const StickyContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'stickySpacing'
})(({theme, stickySpacing = 2}) => ({
    position    : 'sticky',
    top         : theme.spacing(stickySpacing)
}))

const TableCell = styled(TableCellMUI)(({theme}) => ({
    padding         : theme.spacing(0.25),
    paddingLeft     : theme.spacing(0.50),
    paddingRight    : theme.spacing(0.50)
}))

const HeaderContent = styled(Typography)({
    fontWeight : 800
})

const Paper = styled(PaperMUI)(({theme}) => ({
    border          : `1px solid ${theme.palette.divider}`,
    padding         : theme.spacing(1),
    height          : '100%',
}))

const MYAuto = styled(Box)({
    marginTop       :'auto',
    marginBottom    :'auto'
})

const noop = () => {};

const PlayList = withTranslation( ({t, disabled = false, data, onChange = noop, onChangeFile = noop, onClick = noop, currentTime=0, playing=false, playingItem=undefined, chapterName = "Chapter", prefix=undefined, ...props}) => {
    
    const dataGrouped   = React.useMemo(
        () => data.reduce((acc,cur,ix) => {
            const chap  = cur?.metadata?.chapter || "No Chapter"
            acc[chap]   = [...(acc[chap] || []), {...cur,originalIndex:ix} ];
            return acc;
        },{}),
        [data]
    );
    const handleChangeSelected = React.useCallback( 
        (originalIndices,value) => {
            originalIndices = !Array.isArray(originalIndices) ? [originalIndices] : originalIndices;
            if(!originalIndices.length) 
                return;
            const newData = data.map((d,ix) => {
                return (originalIndices.includes(ix)) 
                    ? {...d, selected : Boolean(value)} 
                    : d;
            })
            onChange(newData);
        }, 
        [data, onChange]
    );

    return (
        <RootContainer>
            {
                Object.entries(dataGrouped).map(([key,value],ix) => {
                    const someChecked   = value.map(x => x.selected).some(Boolean);
                    const allChecked    = value.map(x => x.selected).every(Boolean);
                    const allIndices    = value.map(x => x.originalIndex);
                    return (
                        <Box key={ix}>
                            {chapterName} {[value[0]?.metadata?.chapter || (ix + 1), value[0]?.metadata?.chapterTitle].filter(Boolean).join(' - ') }
                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell  >
                                                <Checkbox 
                                                    disabled            = {disabled}
                                                    onClick             = {(e) => {
                                                        e.stopPropagation();
                                                        handleChangeSelected(allIndices, !someChecked ? true : false);
                                                    }}
                                                    checked             = {someChecked} 
                                                    indeterminate       = {someChecked && !allChecked} 
                                                    checkedIcon         = {<CheckBoxIcon fontSize="medium"/>}
                                                    icon                = {<CheckBoxOutlineBlankIcon fontSize="medium"/>}
                                                    indeterminateIcon   = {<IndeterminateCheckBoxIcon fontSize="medium"/>}
                                                />
                                            </TableCell>
                                            <TableCell width="100%">
                                                <HeaderContent>
                                                    { t('common.topic') }
                                                </HeaderContent>
                                            </TableCell>
                                            <TableCell style={{minWidth:100}} align="right">
                                                <HeaderContent>
                                                    { t('common.duration') }
                                                </HeaderContent>
                                            </TableCell>
                                            <TableCell align="right">
                                                { t('common.actions') }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            value.map(({file,duration,name,description,metadata,selected,originalIndex, masterLibrary},ixB) => {

                                                const hasFile   = Boolean(file);
                                                const isCurrent = file && file === playingItem?.file;

                                                return (
                                                    <TableRow 
                                                        key     = {`${ix}-${ixB}`} 
                                                        onClick = {() => {
                                                            if(!file) return;
                                                            isCurrent 
                                                                ? onClick()
                                                                : onChangeFile(file);
                                                        }} 
                                                        hover       = {Boolean(file)}
                                                        style       = {{cursor : file ? 'pointer' : 'default'}}
                                                        hasFile     = {hasFile}
                                                        current     = {hasFile && isCurrent}
                                                    >
                                                        <TableCell style={{minWidth:40}} align="right">
                                                            <Typography>
                                                                {prefix}{ix+1}.{ixB + 1}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox 
                                                                disabled            = {disabled}
                                                                checked             = {selected} 
                                                                onClick             = {(e) => {
                                                                    e.stopPropagation();
                                                                    handleChangeSelected(originalIndex,!selected);
                                                                }}
                                                                checkedIcon         = {<CheckBoxIcon fontSize="medium"/>}
                                                                icon                = {<CheckBoxOutlineBlankIcon fontSize="medium"/>}
                                                                indeterminateIcon   = {<IndeterminateCheckBoxIcon fontSize="medium"/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell width="100%">
                                                            <Typography component="div">
                                                                {description}{" "} 
                                                                {isCurrent
                                                                    ? <> 
                                                                        { 
                                                                            playing 
                                                                                ? <PlayArrowIcon    color="secondary" sx={{transform :'translatey(3px)', fontSize : 12}} /> 
                                                                                : <PauseIcon        color="secondary" sx={{transform :'translatey(3px)', fontSize : 12}} />
                                                                        }
                                                                    </> 
                                                                    : null
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{minWidth:75}} align="right">
                                                            <Typography component="div">
                                                                {!isNaN(duration) ? prettySeconds(duration) : duration} {!file && <ReportProblemIcon sx={{color:'error.main'}}/> }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell onClick={e => e.stopPropagation()} style={{minWidth:75}} align="right">
                                                            <EditLibraryButton libraryId={masterLibrary} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )
                })
            }
        </RootContainer>
    )
});

const MasterLibraryLink = withTranslation( ({t}) => (
    <Link to={AdminLibraryLocation.path} style={{color:'inherit'}}>
        { t('common.masterLibrary') }
    </Link>
))

const API_BASE_URL = "/api/admin/reading";


const defaultFormData = {
    productData : defaultProductData,
    natalRecord : defaultNatalRecordData,
    houseSystem : defaultHouseSystemData,
    zodiac      : defaultZodiacData
};

export const GenerateReading = withTranslation( ({
    t,
    formData                    = defaultFormData,
    result      : resultIn      = undefined,
    allowStitching              = true,
    auto                        = true,
    onWorking   : handleWorking = noop, 
    onChange    : handleChange  = noop,
    stickySpacing               = 2
}) => {
    
    const theme                                 = useTheme();
    const {data:nd}                             = useNatalData()
    const {data:pd}                             = useProduct();
    const {axios}                               = useNetwork();
    const {isAuthenticated, isAdmin}            = useUser();
    const [error, setError]                     = useStateEphemeral(undefined,5000);
    const ref                                   = React.useRef();
    const {width}                               = useSize(ref);

    const [productData,     setProductData]     = React.useState( formData.productData );
    const [natalRecord,     setNatalRecord]     = React.useState( formData.natalRecord );
    const [houseSystem,     setHouseSystem]     = React.useState( formData.houseSystem );
    const [zodiac,          setZodiac]          = React.useState( formData.zodiac );

    const [instance,        setInstance]        = React.useState(undefined);
    const [working,         setWorking]         = React.useState(false);
    const [result,          setResult]          = React.useState(resultIn);
    const [playList,        setPlayList]        = React.useState([]);
    const [playing,         setPlaying]         = React.useState(false);
    const [stitching,       setStitching]       = React.useState(false);
    const [playListIndex,   setPlayListIndex]   = React.useState(undefined);
    const [currentTime,     setCurrentTime]     = React.useState(0);
    const [chapters,        setChapters]        = React.useState(() => []);
    const [appendices,      setAppendices]      = React.useState(() => []);
    const [downloadUrl,     setDownloadUrl]     = React.useState(undefined);
    const [fileName,        setFileName]        = React.useState(undefined);
    const hasPlaylist                           = React.useMemo(() => Array.isArray(playList) && playList.length > 0, [playList])

    // Reset Result and Signed URL
    const handleClear = React.useCallback( () => {
        setResult(undefined);
        setDownloadUrl(undefined);
    }, []);

    
    const handleSubmitProductData               = React.useCallback( (formData) => {
        setProductData(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitHouseSystem               = React.useCallback( (formData) => {
        setHouseSystem(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitZodiac                    = React.useCallback( (formData) => {
        setZodiac(       prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);
    const handleSubmitNatalRecord                 = React.useCallback( (formData) => {
        setNatalRecord(  prev => ({...prev,...formData}))
        handleClear();
    }, [handleClear]);

    // When Working
    React.useEffect(() => (
        handleWorking(working)
    ), [handleWorking, working]);

    // If update natal data
    React.useEffect(() => {
        if(isEqual(natalRecord,defaultNatalRecordData)){
            const proposed = pick((nd || []).find(x => x.isUser), Object.keys(defaultNatalRecordData));
            if(proposed){
                setNatalRecord(proposed)
            }
        }
    },[nd, natalRecord])

    // If update product data
    React.useEffect(() => {
        if(isEqual(productData,defaultProductData)){
            const proposed = pick((pd || [])[0],  Object.keys(defaultProductData));
            if(proposed)
                setProductData(proposed)
        }
    },[pd, productData])

    React.useEffect(() => {
        try{
            const proposed = [
                'DoB',
                moment.utc(natalRecord?.birthDateTime).format('yyyyMMDD-HHmmss'),
                '-',
                natalRecord?.unknownTime ? 'BTUnknown' : 'BTKnown',
                '-',
                'Geo',
                parseFloat(Math.abs(natalRecord.lat)).toFixed(2).replace('.','p'), natalRecord.lat >= 0 ? 'N' : 'S',
                parseFloat(Math.abs(natalRecord.lng)).toFixed(2).replace('.','p'), natalRecord.lng >= 0 ? 'E' : 'W',

                '-',
                'System',titleCase(houseSystem.houseSystem),
                '-',
                "Zodiac",titleCase(zodiac.zodiac),
                '.mp3'
            ].join('')
            setFileName(proposed);
        }catch(err){
            setFileName(undefined);
        }
    },[productData,natalRecord,houseSystem,zodiac])

    const handleSubmit = React.useCallback( () => {
        setResult(undefined)
        setWorking(true);
        const userInput = {
            ...houseSystem,
            ...zodiac,
            natalRecord
        };
        axios.post(
            API_BASE_URL, 
            { productId : productData.id, userInput : userInput }
        )
            .then(({data}) => setResult(data))
            .catch(err => {
                const {message,errors} = err;
                setError(message + " " + (!isEmpty(errors) ? JSON.stringify(errors) : ""))
                console.error(err)
            })
            .finally(() => {
                setWorking(false);
            })
    },[axios, houseSystem, zodiac, natalRecord, productData.id, setError]);

    const handleStitch = React.useCallback(async () => {

        try{

            setStitching(true);
            setDownloadUrl(undefined);
            
            const files             = [...chapters,...appendices].filter(x => x.selected && x.file).map(x => x.file);
            const download          = false;
            const fileNameDefault   = "output.mp3"

            // Post the files to stitch and extract the data and headers
            const res               = await axios.post(
                `${API_BASE_URL}/stitch`, 
                { files, fileName : fileName || fileNameDefault, download }, 
                { responseType : download  ? "arraybuffer" : "application/json" }
            );

            // Get info from data
            if(download){

                const {data, headers} = res.raw;

                // Build Object URL
                const blob      = new Blob([data], { type: headers["content-type"] });
                const blobUrl   = window.URL.createObjectURL( blob );

                const getFileNameFromContentDisposition = (contentDisposition) => {
                    if (!contentDisposition) return null;
                    const match = contentDisposition.match(/filename="?([^"]+)"?/);
                    return match ? match[1] : null;
                }

                // Create link and download
                const actualFileName    = getFileNameFromContentDisposition( headers["content-dispositon"]);
                const link              = document.createElement("a");
                link.href               = blobUrl;
                link.setAttribute("download", actualFileName || fileNameDefault);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

            }else{
                const {signedUrl,shortUrl} = res.data;
                setDownloadUrl(shortUrl || signedUrl);
            }
        }
        catch(err){
            setError(err.message)
            console.error(err);
        }
        finally{
            setStitching(false);
        }

    },[appendices, axios, chapters, fileName, setError]);

    React.useEffect(() => {
        if(!productData?.id)            return;
        if(!houseSystem?.houseSystem)   return;
        if(!zodiac?.zodiac)             return;
        if(!natalRecord)                return;
        if(Object.keys(defaultNatalRecordData).map(
            (key) => isNull(natalRecord[key]) || isUndefined(natalRecord[key])
        ).some(Boolean)) return;

        // Om submit
        if(auto) handleSubmit();
    },[houseSystem?.houseSystem, zodiac?.zodiac, natalRecord, handleSubmit, productData?.id, auto])

    // Call Reset when Critical Things Change
    /*
    React.useEffect(() => {
        setResult(resultIn || undefined);
        setDownloadUrl(undefined);
    }, [houseSystem, natalRecord, productData, resultIn, zodiac])
    */

    React.useEffect(()=>{
        if(result || downloadUrl){
            let files = [
                ...(result?.files || []), 
                downloadUrl 
                    ? {
                        file        : downloadUrl,
                        name        : 'Stitched Audio',
                        description : 'Stitched Audio'
                    } 
                    : undefined
            ].filter(Boolean)
            const pl = (files || [])
                .map(({file,name,description}, ix) => ({ix, file, name, description}))
                .filter(d => Boolean(d.file))
            setPlayList(pl);
        }else{
            setPlayList([])
        }

    },[downloadUrl, result])

    // Play if Stopped or Stop if Playing
    const handlePlayToggle = React.useCallback(() => {
        try{
            if(!instance) throw new Error('No Instance');
            playing ? instance.pause() : instance.play();
        }catch(err){
            // silent
        }
    },[instance, playing]);

    const handleAudioPlay       = React.useCallback( () => setPlaying(true),    []);
    const handleAudioPause      = React.useCallback( () => setPlaying(false),   []);
    const handleAudioEnded      = React.useCallback( () => setPlaying(false),   []);
    const handleAudioAbort      = React.useCallback( () => setPlaying(false),   []);
    const handlePlayIndexChange = React.useCallback( (playIndex) => setPlayListIndex(playIndex), []);
    const handleAudioProgress   = React.useCallback( (progress) => {
        let {currentTime,playIndex,paused,ended} = progress;
        setCurrentTime(currentTime);
        setPlayListIndex(playIndex);
        setPlaying(!paused && !ended);
    }, []);

    const handleChangeFile      = React.useCallback( (file) => {
        if(file){
            const newIndex = playList.findIndex(x => x.file === file)
            setPlayListIndex(newIndex);
        }
    }, [playList]);

    const handleAudioListChange = React.useCallback( () => {
        
    }, []);

    // Chapters and Appendices
    React.useEffect(() => {
        const newChapters = (result?.files || []).filter(x => !x?.metadata?.appendix).map(x => ({...x,selected:true}));
        setChapters(prev => !isEqual(prev,newChapters) ? newChapters : prev);
        const newAppendices = (result?.files || []).filter(x =>  x?.metadata?.appendix).map(x => ({...x,selected:false}))
        setAppendices(prev => !isEqual(prev,newAppendices) ? newAppendices : prev);
    },[result?.files])

    // When Result Changes
    React.useEffect(() => (
        handleChange({
            result : {
                ...result,
                files : [
                    ...chapters.filter(x => x.selected), 
                    ...appendices.filter(x => x.selected)
                ],
            },
            formData : {
                productData,
                natalRecord,
                houseSystem,
                zodiac
            },
            downloadUrl
        })
    ), [handleChange, downloadUrl, result, chapters, appendices, productData, natalRecord, houseSystem, zodiac]);

    const quantitySelected = React.useMemo(() => (
        chapters.filter(x => x.selected).length + appendices.filter(x => x.selected).length
    ), [appendices, chapters]);

    // Memoised common playlist arguments
    const playListArgs = React.useMemo(() => ({
        playing         : playing,
        currentTime     : currentTime,
        playingItem     : playList[playListIndex],
        disabled        : working || stitching,
        onClick         : handlePlayToggle,
        onChangeFile    : handleChangeFile,
    }),[currentTime, handleChangeFile, handlePlayToggle, playList, playListIndex, playing, stitching, working])

    // Determine if Has Files or Not
    const hasFiles      = React.useMemo(() => (
        Boolean(result && (result?.files || []).map(x => x.file).filter(Boolean).length > 0)
    ),[result]);

    // Determine Quantity Missing
    const qtyMissing    = React.useMemo(() => (
        result 
            ? (result?.files || []).map(x => !x.file).filter(Boolean).length 
            : 0
    ),[result]); 

    // Is there any missing
    const hasMissing    = React.useMemo(() => qtyMissing > 0, [qtyMissing]);

    return (
        <>
            <Grid container>
                {
                    (!isAuthenticated || !isAdmin) &&
                    <NotFound>
                        { t('components.generateReading.noPermission') }
                    </NotFound>
                }
                {
                    false && 
                    <JSONViewer src={natalRecord} />
                }
                {
                    isAuthenticated && isAdmin && 
                    <>
                        <Grid item xs={12} sm={12} md={5} lg={4}>
                            <StickyContainer stickySpacing={stickySpacing}>
                                <Paper sx={{height:'60vh',overflow:'scroll', border : theme => `1px solid ${theme.palette.divider}`, p : 1 }}>
                                    <PickReadingVariables
                                        formData    = {{
                                            productData,
                                            natalRecord,
                                            houseSystem,
                                            zodiac
                                        }}
                                        onProductDataChange = {handleSubmitProductData}
                                        onNatalRecordChange = {handleSubmitNatalRecord}
                                        onHouseSystemChange = {handleSubmitHouseSystem}
                                        onZodiacChange      = {handleSubmitZodiac}
                                    />
                                </Paper>
                            </StickyContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={8} style={{height:'100%', /*overflow:'scroll',*/ display:"flex",flexDirection:'column'}}>
                            <RootContainer>
                                <Paper>
                                    <Grid container direction="row-reverse">
                                        {
                                            stitching &&
                                            <Grid item xs={12}>
                                                <FormAlert severity="warning">
                                                    { t('components.generateReading.stitchingTakesTime') }
                                                </FormAlert>
                                            </Grid>
                                        }
                                        {
                                            error &&
                                            <Grid item xs={12}>
                                                <FormAlert severity="error">
                                                    {error}
                                                </FormAlert>
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={3}>
                                            <Typography variant='h5' component="h3">
                                                {t('common.product')}
                                            </Typography>
                                            <Box style={{zoom:'0.5'}}>
                                                {
                                                    productData?.id 
                                                        ? <ProductCard actionsHeight={0} productId={productData?.id} showDescription={false} showAddToCart={false}/>
                                                        : <SkeletonProductCard typographyComponent={SelectProductReminder}/>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <Box display="flex" flexDirection="column" height="100%">
                                                <Box flexGrow={1}>
                                                    <Box display="flex">
                                                        <Box flexGrow={1}>
                                                            <Typography variant='h5' component="h2">
                                                                {t('common.natalData')}
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{transform:'translatey(4px)'}}>
                                                            <Typography component="div" gutterBottom>
                                                                {t('common.houseSystem')}: <Chip size="small" color="primary" label={houseSystem.houseSystem}/> {t('common.zodiac')}: <Chip size="small" color="primary" label={zodiac.zodiac}/>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <NatalSummary
                                                        lat             = {natalRecord?.lat} 
                                                        lng             = {natalRecord?.lng} 
                                                        address         = {natalRecord?.address} 
                                                        unknownTime     = {natalRecord?.unknownTime} 
                                                        birthDateTime   = {natalRecord?.birthDateTime}
                                                        localTime       = {typeof natalRecord?.localTime === 'boolean' ? natalRecord?.localTime : true}
                                                    />
                                                </Box>  
                                                {allowStitching && fileName &&
                                                    <Box pt={2}>
                                                        <Typography variant='h5' component="h2">
                                                            {t('common.outputFile')}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {fileName}
                                                        </Typography>
                                                    </Box>
                                                }
                                                <Box display={"flex"}>
                                                    <Box flexGrow={1} align="right" mt={2} style={{color:theme.palette.warning.main}}>
                                                        {
                                                            allowStitching && result &&
                                                            <ConfirmationButton 
                                                                startIcon   = {(working || stitching) ? <RefreshIcon loading={true}/> : <MergeTypeIcon/>} 
                                                                disabled    = {Boolean(stitching || working || !isAuthenticated || !hasPlaylist)} 
                                                                color       = "inherit" 
                                                                variant     = "text" 
                                                                onClick     = {handleStitch}
                                                            >
                                                                {
                                                                    hasPlaylist 
                                                                        ? t('common.stitch')
                                                                        : t('common.nothingToStitch')
                                                                }
                                                            </ConfirmationButton>
                                                        }
                                                    </Box>
                                                    <Box align="right" mt={2} ml={2} style={{color:theme.palette.error.main}}>
                                                        <Button 
                                                            startIcon   = {<UndoIcon/>}
                                                            disabled    = {Boolean(stitching || working || !isAuthenticated || !result)} 
                                                            color       = "inherit" 
                                                            variant     = "text" 
                                                            onClick     = {handleClear}
                                                        >
                                                            {t('common.clearReset')}
                                                        </Button>
                                                    </Box>
                                                    <Box align="right" mt={2} ml={2}>
                                                        <Button 
                                                            startIcon   = {working ? <RefreshIcon loading={true}/> : <CheckIcon />} 
                                                            disabled    = {Boolean(stitching || working || !isAuthenticated)} 
                                                            color       = "primary" 
                                                            variant     = "contained" 
                                                            onClick     = {handleSubmit}
                                                        >
                                                            { 
                                                                working 
                                                                    ? t('common.working')
                                                                    : t('common.generate')
                                                            }
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                {
                                    downloadUrl &&
                                    <Paper>
                                        <Typography variant='h5' component="h2">
                                            {t('components.generateReading.signedDownloadUrl')}
                                        </Typography>
                                        <Typography gutterBottom>
                                            {t('components.generateReading.signedDownloadUrlDescription', {expireDays:7,purgeDays:30})}
                                        </Typography>
                                        <CopyField value={downloadUrl}/>
                                    </Paper>
                                }

                                <Paper style={{minHeight:400}}>
                                    <Grid container>
                                        {
                                            result && (!hasFiles || hasMissing) && 
                                            <Grid item xs={12} sx={{'& > * + *' : {mt : 1}}}>
                                                {
                                                    !hasFiles && 
                                                    <FormAlert severity="error">
                                                        {t('components.generateReading.errorNoFiles')} (<MasterLibraryLink/>)
                                                    </FormAlert>
                                                }
                                                {
                                                    hasMissing &&
                                                    <FormAlert severity="warning">
                                                        {t('components.generateReading.warningMissingFiles', {quantity : qtyMissing })} (<MasterLibraryLink/>)
                                                    </FormAlert>
                                                }
                                            </Grid>
                                        }
                                        <Grid item xs={12} lg={4}>
                                            <StickyContainer ref={ref} style={{maxWidth:400,margin:'auto'}}>
                                                <Radix 
                                                    id      = "radix"
                                                    width   = {width}
                                                    data    = {result?.horoscopeData}
                                                />
                                            </StickyContainer>
                                        </Grid>
                                        {   
                                            result && 
                                            <Grid item xs={12} lg={8}>
                                                <RootContainer>
                                                    <RootContainer display="flex" flexDirection="row">
                                                        <MYAuto>
                                                            <Typography variant='h6'>
                                                                {t('common.result')}
                                                            </Typography>
                                                        </MYAuto>
                                                        {
                                                            hasPlaylist && 
                                                            <MYAuto>
                                                                {
                                                                    playing 
                                                                        ? <PlayArrowIcon color="secondary" style={{transform:'translatey(3px)'}}/>
                                                                        : <PauseIcon color="secondary" style={{transform:'translatey(3px)'}} />
                                                                }
                                                            </MYAuto>
                                                        }
                                                        {
                                                            hasPlaylist && playing &&
                                                            <MYAuto>
                                                                <Typography component="span" variant="body2">
                                                                    {prettySeconds(currentTime,false)}
                                                                </Typography>
                                                            </MYAuto>
                                                        }
                                                        <Box flexGrow={1}/>
                                                        <MYAuto>
                                                            <Typography component="span" variant="body2">
                                                                {t('components.generateReadings.topicsSelectedQuantity',{quantity:quantitySelected})}
                                                            </Typography>
                                                        </MYAuto>
                                                    </RootContainer>

                                                    <MasterLibraryProvider>
                                                        
                                                        {/* Chapters Playlist */}
                                                        <PlayList 
                                                            data        = {chapters} 
                                                            onChange    = {setChapters} 
                                                            {...playListArgs} 
                                                            chapterName = {t('common.chapter')} 
                                                        />

                                                        {/* Appendices Playlist */}
                                                        <PlayList 
                                                            data        = {appendices} 
                                                            onChange    = {setAppendices} 
                                                            {...playListArgs}
                                                            prefix      = {'A'} 
                                                            chapterName = {t('common.appendix')} 
                                                        />

                                                    </MasterLibraryProvider>

                                                    {
                                                        false && 
                                                        <Box fontSize={'0.65rem'}>
                                                            <JSONViewer src={result}/>
                                                        </Box>
                                                    }
                                                </RootContainer>
                                            </Grid>
                                        }

                                        {
                                            !result && 
                                            <Box p={2} width="100%">
                                                <SkeletonPlaylist typographyComponent={SelectPlaylistReminder}/>
                                            </Box>
                                        }
                                    </Grid>
                                </Paper>
                            </RootContainer>
                        </Grid>
                    </>
                }
            </Grid>
            {   
                hasPlaylist &&
                <LibraryJinkePlayer 
                    hidden                  = { false }
                    playList                = { playList }
                    playListIndex           = { playListIndex }
                    onAudioInstanceChange   = { setInstance }
                    onAudioPlay             = { handleAudioPlay}  
                    onAudioPause            = { handleAudioPause }
                    onAudioEnded            = { handleAudioEnded }
                    onAudioAbort            = { handleAudioAbort}   
                    onPlayIndexChange       = { handlePlayIndexChange }
                    onAudioListChange       = { handleAudioListChange }
                    onAudioProgress         = { handleAudioProgress }
                />
            }
        </>
    )
});

export default GenerateReading;
