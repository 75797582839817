/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin -- User
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th August 2022

*******************************************************************************************/
import React                            from 'react';
import moment                           from 'moment';
import { titleCase }                    from 'title-case';
import {
    styled,
    useTheme,
    Chip,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as TableCellMUI,
    MenuItem,
}                                       from '@mui/material';
import {
    Toll                as TollIconMUI,
    Map                 as MapIconMUI
}                                       from '@mui/icons-material';
import { 
    RootContainer,
    AddressBlock,
    CheckCrossIcon,
    DraggableDialog,
    Gravatar, 
    GoogleMapsStatic,
    ObjectId,
    IconMenu,
    JSONViewer,
    IconButton,
    FeatureBox,
    GoogleMapsDynamic,
    PublicUserName
}                                       from 'components'
import { useAccountCredits, useLocale }                    from 'contexts';
import { useSize }                      from 'hooks';
import SortDirectionIcons               from './SortDirectionIcons';

const noop      = () => {}

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'header' && prop !== 'key'
})(({theme, header = false, key = false})=>({
    verticalAlign   : 'top',
    padding         : 1, 
    fontSize        : '0.6rem',
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0.5),
    whiteSpace      : 'nowrap', 
    '& > * + *' : {  
        marginLeft : theme.spacing(0.5)
    },
    ...(header && {
        fontWeight : 800  
    }),
    ...(key && {
        paddingLeft : [theme.spacing(1) + 2, '!important'],
    })
}))

const ClickableType = styled(Typography,{
    shouldForwardProp : prop => prop !== 'selected'
})(({selected = false}) => ({
    fontSize : '0.6rem',
    ...(selected && {
        fontWeight : 600,
    })
}))

const UserPhoto = ({src = undefined, alt = undefined, ...props}) => {
    const [url, setUrl] = React.useState(undefined)
    React.useEffect(() => {
        const image = new Image();
        image.onload = function(){
            setUrl(src);
        }
        image.onerror = function(){
            setUrl(undefined);
        }
        image.src = src;
    },[src])
    if(!url)
        return null;
    return (
        <img src={url} alt={alt} {...props} />
    )
}

const TinyChip = styled(Chip)(() => ({
    height      : 12, 
    fontSize    : 8
}))

const MapIconButton = styled(IconButton)(({theme}) => ({
    color : theme.palette.info.dark
}))

const MapIcon = styled(MapIconMUI)(({theme}) => ({
    fontSize : 12
}))

const TollIcon = styled(TollIconMUI)(({theme}) => ({
    fontSize : 12
}))

const TableScrollContainer = styled(Box,{
    shouldForwardProp : prop => prop !== 'scrollable'
})(({theme, scrollable = true}) => ({
    ...(scrollable && {
        position    : 'absolute',
        top         : 0,
        left        : 0,
        right       : 0,
        bottom      : 0,
        overflow    : 'scroll'
    })
}))

const ViewGeoMap = ({disabled = false, fullScreen = true, latitude, longitude, dialogProps = {}, iconProps = {}, iconButtonProps = {}, ...props}) => {
    
    const {formatCoords}    = useLocale();
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback(() => setOpen(true), []);
    const handleClose       = React.useCallback(() => setOpen(false),[]);

    if(!latitude || !longitude)
        return null;

    return (
        <>
            <MapIconButton disabled={disabled} size="small" {...iconButtonProps} onClick={handleOpen}>
                <MapIcon color="inherit" {...iconProps}/>
            </MapIconButton>
            {   
                open && 
                <DraggableDialog
                    title               = "GeoIP Viewer"
                    subtitle            = {formatCoords({latitude,longitude})}
                    open                = {open}
                    onClose             = {handleClose}
                    onOk                = {handleClose}
                    showButtonsCancel   = {false}
                    fullScreen          = {fullScreen}
                    maxWidth            = "sm"
                    fullWidth           = {true}
                    {...dialogProps}
                >
                    <RootContainer position="relative" height="100%" minHeight="200px">
                        <GoogleMapsDynamic 
                            lat     = {latitude}
                            lng     = {longitude}
                        />
                    </RootContainer>
                </DraggableDialog>
            }
        </>
    )
}

const ViewAddressMap = ({disabled = false, fullScreen = true, address = undefined, dialogProps = {}, iconProps = {}, iconButtonProps = {}, ...props}) => {
    
    const [open, setOpen]   = React.useState(false);
    const handleOpen        = React.useCallback(() => setOpen(true), []);
    const handleClose       = React.useCallback(() => setOpen(false),[]);

    if(!address)
        return;

    return (
        <>
            <MapIconButton disabled={disabled} size="small" {...iconButtonProps} onClick={handleOpen}>
                <MapIcon color="inherit" {...iconProps}/>
            </MapIconButton>
            {   
                open && 
                <DraggableDialog
                    title               = "Address Viewer"
                    subtitle            = {address.kind ? `${titleCase(address.kind.toLowerCase())} Address` : undefined}
                    open                = {open}
                    onClose             = {handleClose}
                    onOk                = {handleClose}
                    showButtonsCancel   = {false}
                    fullScreen          = {fullScreen}
                    maxWidth            = "sm"
                    fullWidth           = {true}
                    {...dialogProps}
                >
                    <RootContainer display="flex" height="100%" minHeight="200px">
                        <Box flexShrink={1}>
                            <FeatureBox>
                                <AddressBlock {...address} sx={{minHeight:'unset'}}/>
                            </FeatureBox>
                        </Box>
                        {
                            address?.geolocation?.lat && address?.geolocation?.lng &&
                            <RootContainer flexGrow={1} position="relative">
                                <GoogleMapsDynamic 
                                    lat     = {address.geolocation.lat}
                                    lng     = {address.geolocation.lng}
                                    {...address.geolocation}
                                />
                            </RootContainer>
                        }
                    </RootContainer>
                </DraggableDialog>
            }
        </>
    )
}

export const User = ({
    disabled                    = true,
    readOnly                    = false,
    user : userIn               = undefined, 
    allowViewRaw                = true, 
    sortKeys                    = [],
    sort                        = undefined,                                                       
    sortOrder                   = "ascending",
    onChange    : handleChange  = noop,
    onRefresh   : handleRefresh = noop,
    scrollable                  = true
}) => {

    const theme                         = useTheme();
    const {
        formatNumber,
        formatDateTime,
        formatLatitude,
        formatLongitude
    }                                   = useLocale();
    const ref                           = React.useRef();
    const {width:size}                  = useSize(ref);
    const tableRef                      = React.useRef();
    const {
        // height  : tableHeight,
        width   : tableWidth
    }                                   = useSize(tableRef);
    const natalRef                      = React.useRef();
    const {height}                      = useSize(natalRef);

    const user                          = React.useMemo(() => userIn, [userIn]);
    const [rawData,     setRawData]     = React.useState(undefined);
    const openRawData                   = React.useMemo(() => Boolean(rawData), [rawData]);
    const handleCloseRawData            = React.useCallback(() => setRawData(undefined), []);
    const handleOpenRawData             = React.useCallback(() => setRawData(user), [user]);
    const {issueCredits}                = useAccountCredits();
    const {
        _id,
        // lastName,
        // firstName,
        // namePublic,
        createdAt,
        updatedAt,
        openid,
        email,
        email_verified,
        emailHealth,
        addresses,
        orderCount,
        natalRecords,
        creditsBalance = 0,
        userProfile
    }                                   = user || {};
    const {geoip, roles}                = user?.metadata || {};
    const {
        latitude,
        longitude,
        city_name,
        cityName,
        country_name,
        countryName
    }                                   = geoip || {};


    const isSocial                      = openid && !openid.includes('auth0');
    const isUserPass                    = openid &&  openid.includes('auth0');
    const isDescending                  = sortOrder === 'descending';
    const hasAddresses                  = Array.isArray(addresses) && addresses.length > 0;
    const hasNatalRecords               = Array.isArray(natalRecords) && natalRecords.length > 0;


    const ClickableSort = React.useCallback( ({children, value, ...props}) => {
        const isClickable   = Boolean(value && sortKeys.includes(value));
        const isThis        = Boolean(sort && value === sort);
        const colorDesc     = disabled || readOnly ? theme.palette.divider  : theme.palette.error.main;
        const colorAsc      = disabled || readOnly ? theme.palette.divider  : theme.palette.info.main;

        const handleClick = () => {
            if(value === sort){
                handleChange({sort, sortOrder : sortOrder === 'ascending' ? 'descending' : 'ascending'});
            }else{
                handleChange({sort : value, sortOrder});
            }
        }

        return (
            <Box display={"inline-block"} {...props}>

                {
                    disabled && 
                    <ClickableType>
                        {children}
                    </ClickableType>
                }

                {
                    !disabled &&
                    <Box 
                        display = {"flex"}
                        onClick = {isClickable && !disabled && !readOnly ? handleClick : null}
                        style   = {{
                            cursor      : isClickable && !disabled && !readOnly ? 'pointer' : 'default', 
                            position    : 'relative'
                        }}
                    >
                        {
                            isClickable && isThis && 
                            <Box position="absolute" style={{ left : 0, width : 0, transform : 'translatex(-10px)' }}>
                                <SortDirectionIcons disabled={disabled || readOnly} descending={isDescending}/>
                            </Box>
                        }
                        {   
                            isClickable &&
                            <Box 
                                position    = "absolute" 
                                style       = {{
                                    left: 0, bottom:1, width:'100%', height:0.25,
                                    background : isThis 
                                        ? (isDescending ? colorDesc : colorAsc)
                                        : theme.palette.warning.light
                                }}
                            />
                        }
                        <Box ml={isThis && isClickable ? 0 : 0}>
                            <ClickableType selected={isThis}>
                                {children}
                            </ClickableType>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }, [disabled, handleChange, isDescending, readOnly, sort, sortKeys, sortOrder, theme.palette.divider, theme.palette.error.main, theme.palette.info.main, theme.palette.warning.light])

    if(!user) 
        return null;

    return (
        <Box sx={{'& > * + *' : {ml : 1}, width : '100%', display : 'flex'}}>
            
            <Box ref={ref} sx={{'& > * + *' : {mt : 1} }}>

                <Box>
                    <Gravatar emailMD5={user.emailMD5} />
                </Box>

                {
                    user.picture &&
                    <Box position="relative" width={size} height={size}>
                        <Box sx = {{position : 'absolute', top : 0, left : 0}}>
                            <UserPhoto 
                                width   = {size} 
                                height  = {size} 
                                src     = {user.picture} 
                                alt     = "userphoto"
                            />
                        </Box>
                    </Box>
                }

            </Box>

            <Box flexGrow={1} sx={{'& > * + *' : {mt : 1} }}>
                <Box>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography component="div" style={{fontWeight:400}}>
                                {[...new Set([userProfile?.lastName,userProfile?.firstName])].filter(Boolean).join(', ') } (<PublicUserName user={user} />)
                            </Typography>
                        </Box>
                        <Box position="relative">
                            {
                                allowViewRaw && 
                                <Box style={{position:'absolute',top:0,right:0}}>
                                    <IconMenu size="small">
                                        <MenuItem onClick={handleOpenRawData}>
                                            View Raw Data
                                        </MenuItem>
                                    </IconMenu>
                                </Box>
                            }
                        </Box>
                    </Box>
                    {
                        true &&
                        <Typography component="div" variant="body2">
                            {email} {typeof email_verified === 'boolean' && <CheckCrossIcon size={12} checked={email_verified} tooltip="Verified"/>}
                        </Typography>
                    }
                    <Typography component="div" variant="body2">
                        Last seen at {[city_name || cityName, country_name || countryName].filter(Boolean).join(', ') || "UNKNOWN"}
                    </Typography>
                </Box>

                {
                    latitude && longitude &&
                    <Box display="flex" sx={{'& > * + *' : {ml : 1} }}>
                        <Box width="60%">
                            <GoogleMapsStatic 
                                lat     = {latitude} 
                                lng     = {longitude} 
                                width   = {300} 
                                height  = {100}
                                zoom    = {12}
                            />
                        </Box>
                        <Box width="40%">
                            <GoogleMapsStatic 
                                lat     = {latitude} 
                                lng     = {longitude} 
                                width   = {200} 
                                height  = {100}
                                zoom    = {3}
                            />
                        </Box>
                    </Box>
                }
                
            
                <Box mb={2} height={scrollable ? 0.71*tableWidth : 'fit-content'} position = 'relative'>
                    <TableScrollContainer scrollable={scrollable}>
                        <TableContainer ref={tableRef}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell header={true} key={true}>Attribute</TableCell>
                                        <TableCell header={true} width = "100%">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'id'}>
                                                ID
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell><ObjectId value={user.id || user._id} length={16}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'openid'}>
                                                OpenID
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {openid || 'N/A'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'createdAt'}>
                                                Created
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>{createdAt && formatDateTime(moment(createdAt))}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'updatedAt'}>
                                                Updated
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>{updatedAt && formatDateTime(moment(updatedAt))}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'email'}>
                                                Email
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>{email}</TableCell>
                                    </TableRow>

                                   
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'email_verified'}>
                                                Verified
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {typeof email_verified === 'boolean' && <CheckCrossIcon size={12} checked={email_verified} tooltip="Verified"/>}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            Health
                                        </TableCell>
                                        <TableCell>
                                            <ClickableSort value={'emailHealth.delivered'}> 
                                                Delivered {emailHealth.delivered}x
                                            </ClickableSort>
                                            <ClickableSort value={'emailHealth.bounce'} pl={sort === 'emailHealth.bounce' ? 1 : 0}>
                                                Bounce {emailHealth.bounce}x
                                            </ClickableSort>
                                            <ClickableSort value={'emailHealth.complaint'} pl={sort === 'emailHealth.complaint' ? 1 : 0}>
                                                Complaint {emailHealth.complaint}x
                                            </ClickableSort>
                                        </TableCell>
                                    </TableRow>

                                    
                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'userProfile.handle'}>
                                                Handle
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {userProfile?.handle ? `@${userProfile?.handle}` : ''}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'userProfile.public'}>
                                                Public Profile
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {typeof userProfile?.public === 'boolean' && <CheckCrossIcon size={12} checked={userProfile?.public} tooltip="Public Profile"/>}
                                        </TableCell>
                                    </TableRow>


                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'userProfile.firstName'}>
                                                First Name
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(userProfile?.firstName || '')}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'userProfile.lastName'}>
                                                Last Name
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {titleCase(userProfile?.lastName || '')}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'userProfile.displayName'}>
                                                Public Name
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {userProfile?.displayName || ''}
                                        </TableCell>
                                    </TableRow>

                                    
                                    <TableRow>
                                        <TableCell key={true}>
                                           Last Seen
                                        </TableCell> 
                                        <TableCell>
                                            {
                                                (city_name || cityName) && 
                                                <>
                                                    <ClickableSort value={'metadata.geoip.city_name'}>
                                                        {titleCase(city_name || cityName || '')}
                                                    </ClickableSort>{(country_name || countryName) && ", "}
                                                </>
                                            }
                                            {
                                                (country_name || countryName) &&
                                                <ClickableSort value={'metadata.geoip.country_name'} pl={sort === 'metadata.geoip.country_name' ? 1 : 0}>
                                                    {titleCase(country_name || countryName)}
                                                </ClickableSort>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            GeoIP
                                        </TableCell> 
                                        <TableCell>
                                            {
                                                latitude && longitude &&
                                                <Box display="flex" width="100%">
                                                    <Box flexGrow={1}>
                                                        <ClickableSort value={'metadata.geoip.latitude'}> 
                                                            {formatLatitude({latitude})}
                                                        </ClickableSort>
                                                        <ClickableSort value={'metadata.geoip.longitude'} pl={sort === 'metadata.geoip.longitude' ? 2 : 1}>
                                                            {formatLongitude({longitude})}
                                                        </ClickableSort>
                                                    </Box>
                                                    <Box>
                                                        <ViewGeoMap disabled={disabled} latitude={latitude} longitude={longitude}/>
                                                    </Box>
                                                </Box>
                                            }
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'orderCount'}>
                                                Order Count
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell>
                                            {orderCount ? `${orderCount}x`: 'No Orders Placed'}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            <ClickableSort value={'creditsBalance'}>
                                                Credits Balance
                                            </ClickableSort>
                                        </TableCell>
                                        <TableCell> 
                                            <Box display="flex" width="100%">
                                                <Box flexGrow={1}>
                                                    {creditsBalance && <TollIcon sx={{transform:'translatey(2px)'}}/>} {creditsBalance ? formatNumber(creditsBalance) : 'No Credits Balance'}
                                                </Box>
                                                <Box>
                                                    <IconMenu iconProps={{sx:{fontSize:'0.75rem'}}} iconButtonProps={{size:"small",sx:{p:0}}}>
                                                        <MenuItem onClick={() => issueCredits(_id,100).then(handleRefresh).catch()}>
                                                            Promo +100
                                                        </MenuItem>
                                                        <MenuItem onClick={() => issueCredits(_id,200).then(handleRefresh).catch()}>
                                                            Promo +200
                                                        </MenuItem>
                                                        <MenuItem onClick={() => issueCredits(_id,500).then(handleRefresh).catch()}>
                                                            Promo +500
                                                        </MenuItem>
                                                        <MenuItem onClick={() => issueCredits(_id,1000).then(handleRefresh).catch()}>
                                                            Promo +1000
                                                        </MenuItem>
                                                    </IconMenu>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell key={true}>
                                            Auth Type
                                        </TableCell>
                                        <TableCell>
                                            {isSocial   && <TinyChip size="small" color="primary"   label="Social"/>}
                                            {isUserPass && <TinyChip size="small" color="secondary" label="User/Pass"/>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                            <TableCell key={true}>
                                                Roles
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    (roles || []).filter(Boolean).map((role,ix) => (
                                                        <TinyChip
                                                            size        = "small" 
                                                            color       = "primary" 
                                                            key         = {ix} 
                                                            label       = {titleCase(role || '')}
                                                        />
                                                    ))
                                                }
                                            </TableCell>
                                    </TableRow>
                                    {
                                        hasAddresses && 
                                        addresses.map((address,ix,arr) => (
                                            <TableRow key={ix}>
                                                <TableCell key={true}>
                                                    {titleCase((address?.kind || '').toLowerCase())}
                                                </TableCell>
                                                <TableCell>
                                                    <Box display="flex">
                                                        <Box flexGrow={1}>
                                                            <AddressBlock {...address} plain={true}/>
                                                        </Box>
                                                        <Box>
                                                            <ViewAddressMap disabled={disabled} address={address} />
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }

                                    { 
                                        hasNatalRecords &&
                                        natalRecords.slice(0,1).map((natalRecord,ix) => (
                                            <TableRow key={ix} ix={ix}>
                                                <TableCell key={true}>
                                                    Natal Data
                                                </TableCell>
                                                <TableCell>
                                                    <Box display="flex">
                                                        <Box flexGrow={1} display="flex" flexDirection={"column"}>
                                                            <Box>
                                                                { natalRecord.description }, { formatDateTime(moment(natalRecord.birthDateTime)) } <CheckCrossIcon size={12} checked={!natalRecord.unknownTime} tooltip="Knows Time of Birth"/>
                                                            </Box>
                                                            <Box 
                                                                height      = {height} 
                                                                width       = "100%" 
                                                                position    = {"relative"} 
                                                            >
                                                                <Box 
                                                                    ref         = {natalRef} 
                                                                    position    = {"absolute"} 
                                                                    style       = {{
                                                                        top             : 0,
                                                                        left            : 0,
                                                                        right           : 0,
                                                                        overflow        :'hidden',
                                                                        textOverflow    : 'ellipsis',
                                                                        whiteSpace      : 'nowrap'
                                                                    }}
                                                                >
                                                                    { natalRecord.address }
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <ViewGeoMap disabled={disabled} latitude={natalRecord.lat} longitude={natalRecord.lng}/>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))   
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableScrollContainer>
                </Box>
            
            
            </Box>

            {   
                allowViewRaw && openRawData && 
                <DraggableDialog
                    title               = "User Data"
                    open                = {openRawData}
                    onClose             = {handleCloseRawData}
                    onOk                = {handleCloseRawData}
                    showButtonsCancel   = {false}
                    maxWidth            = "md"
                    fullWidth
                >
                    <JSONViewer 
                        src     = {rawData} 
                        style   = {{wordBreak: 'break-all'}}
                    />
                </DraggableDialog>
            }

        </Box>
    )
}

export default User;