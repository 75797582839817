/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Pages -- Health
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th December 2021

*******************************************************************************************/
import React                from 'react';
import moment               from 'moment';
import { 
    Box, 
}                           from '@mui/material';
import { 
    Title,
    PageContainer,
    RootContainer,
    // NatalSummary
}                           from 'components'
import {
    useNetwork
}                           from 'contexts';
import { 
    useQueryParam,
    withDefault,
    NumberParam,
    // StringParam,
    // BooleanParam
}                           from 'hooks/useQueryParams';

export const Radix = () => {

    const {axios}                   = useNetwork();
    const [day     /*setDay*/]      = useQueryParam('day',      withDefault(NumberParam,19));
    const [month   /*setMonth*/]    = useQueryParam('month',    withDefault(NumberParam,11));
    const [year    /*setYear*/]     = useQueryParam('year',     withDefault(NumberParam,1980));

    React.useEffect(() => {
        if(axios && day && month && year){
            const args = { 
                birthDateTime   : moment.utc(
                    `${day.toString().padStart(2,'0')}-${month.toString().padStart(2,'0')}-${year.toString().padStart(4,'0')} 08:30 PM`, 
                    'DD-MM-YYYY hh:mm A',
                    true
                ).toISOString(),
                houseSystem     : 'auto', 
                zodiac          : 'tropical', 
                lat             : -33.8216, 
                lng             : 151.1915 
            }

            axios
                .post('/api/radix', args ,{responseType: 'blob'})
                .then(response => {
                    let imageNode   = document.getElementById('radixImage');
                    let imgUrl      = URL.createObjectURL(response.raw.data)
                    imageNode.src   = imgUrl
                })
        }
    },[axios, day, month, year])

    return (
        <RootContainer>
            <PageContainer>
                <Title>
                    Radix
                </Title>
                <Box sx={{border:theme => `1px solid ${theme.palette.divider}`,borderRadius : theme => theme.spacing(1)}}>
                    <img id="radixImage" alt=""/>
                </Box>
            </PageContainer>
        </RootContainer>
    )
}

export default Radix;