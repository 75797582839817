/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
usePages hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd July 2022

*******************************************************************************************/
import React                            from 'react';
import {
    Settings            as SettingsIcon,
    ConfirmationNumber  as TicketIcon,
    Shop                as ShopIcon,
    ShoppingCart        as ShoppingCartIcon,
    ConfirmationNumber  as CouponIcon,
    Dashboard           as DashboardIcon,
    LibraryBooks        as LibraryIcon,
    AllInbox            as DeliveryIcon,
    Email               as EmailIcon,
    Home                as HomeIcon,
    LiveHelp            as LiveHelpIcon,
    LocalLibrary        as ResourcesIcon,
    Info                as InfoIcon,
    Person              as PersonIcon,
    Games               as GamesIcon,
    Archive             as ArchiveIcon,
    BurstMode           as BurstModeIcon,
    EmojiSymbols        as EmojiSymbolsIcon,
    Layers              as LayersIcon,
    Comment             as CommentIcon,
    Reviews             as ReviewsIcon,
    Shop2               as Shop2Icon,
    Book                as BookIcon,
    Preview             as PreviewIcon,
    InsertPhoto         as NakshatrasIcon
}                                       from '@mui/icons-material';
import { useLibrary, useOrders }        from 'contexts';
import { 
    HomeLocation,
    AboutLocation,
    FAQLocation,
    ShopLocation,
    ResourceCollectionLocation,
    ExamplesCollectionLocation,
    BlogLocation,
    FoundersLocation,
    
    AdminDalleLocation,
    AdminSymbolsLocation,
    // AdminSymbolsPlaygroundLocation,
    AdminCouponsLocation,
    AdminLocation,
    AdminLibraryLocation,
    AdminProductsLocation,
    AdminFeaturesLocation,
    AdminDashboardLocation,
    AdminTicketsLocation,
    AdminDeliveriesLocation,
    AdminEmailsLocation,
    AdminUsersLocation,
    AdminPlayGroundLocation,
    AdminExamplesCollectionLocation,
    AdminResourcesLocation,
    AdminCommentsLocation,
    AdminReviewsLocation,
    AdminOrdersLocation,
    AdminNakshatrasLocation,


    UserLocation,
    UserSettingsLocation,
    UserTicketsLocation,
    UserOrderCollectionLocation,

    PreviewSignLocation,
    PreviewReadingLocation,
}                                       from 'router/locations';

export const usePages = () => {

    const {libraryPath, qtyPending} = useLibrary();
    const {quantityUnpaid}          = useOrders();

    const mainPages     = React.useMemo(() => (
        [
            {   
                name            : 'pages.home',
                link            : HomeLocation.path,                             
                Icon            : HomeIcon,  
                exclude         : true,
                index           : true
            },
            {   
                name            : 'pages.about',
                link            : AboutLocation.path,                             
                Icon            : InfoIcon,
            },
            {   
                name            : 'pages.founders',
                link            : FoundersLocation.path,                             
                Icon            : InfoIcon,
            },
            {   
                name            : 'pages.faq',
                link            : FAQLocation.path,        
                Icon            : LiveHelpIcon,
            },
            {
                name            : 'pages.shop',
                link            : ShopLocation.path,                                
                Icon            : ShopIcon,
            },
            {
                name            : 'pages.blog',
                link            : BlogLocation.path,                                
                Icon            : BookIcon,
            },
        ]
    ), [])

    const previewPages  = React.useMemo(() => (
        [
            {   
                name            : "pages.readingPreview",
                link            : PreviewReadingLocation.path,                                  
                Icon            : PreviewIcon,
            },
            {   
                name            : 'pages.preview.sign',
                link            : PreviewSignLocation.path,                                  
                Icon            : PreviewIcon,
            },
        ]
    ), [])

    const otherPages     = React.useMemo(() => (
        [
            /*{   
                name            : 'pages.preview',
                link            : PreviewLocation.path,                                  
                Icon            : PreviewIcon,
            },*/
            {
                name            : 'pages.examples',
                link            : ExamplesCollectionLocation.path,                             
                Icon            : ArchiveIcon,
            },
            {   
                name            : 'pages.books',
                link            : ResourceCollectionLocation.path,                                  
                Icon            : ResourcesIcon,
            },
        ]
    ), [])

    const userPages     = React.useMemo(() => (
        [
            {   
                name            : 'pages.user.home',
                link            : UserLocation.path,                             
                Icon            : HomeIcon,
                index           : true
            },
            {
                name            : 'pages.user.library',
                link            : libraryPath,     
                Icon            : LibraryIcon,     
                feature         : true,
                badge           : qtyPending
            },
            {   
                name            : 'pages.user.orders',
                link            : UserOrderCollectionLocation.path,         
                Icon            : ShoppingCartIcon,
                feature         : true,
                badge           : quantityUnpaid
            },
            {
                name            : 'pages.user.settings',
                link            : UserSettingsLocation.path,                   
                Icon            : SettingsIcon,
            },
            {
                name            : 'pages.user.tickets',
                link            : UserTicketsLocation.path,              
                Icon            : TicketIcon,      
            },
        ]
    ),[libraryPath, qtyPending, quantityUnpaid]);

    const adminPages    = React.useMemo(() => (
        [
            {   
                name            : 'pages.admin.home',
                link            : AdminLocation.path,              
                Icon            : HomeIcon,
                home            : true,
                index           : true
            },
            {   
                name            : 'pages.admin.dashboard',
                link            : AdminDashboardLocation.path, 
                Icon            : DashboardIcon,
            },
            {   
                name            : 'pages.admin.orders',
                link            : AdminOrdersLocation.path, 
                Icon            : Shop2Icon,
            },
            {   
                name            : 'pages.admin.deliveries',
                link            : AdminDeliveriesLocation.path,  
                Icon            : DeliveryIcon,
            },
            {   
                name            : 'pages.admin.tickets',
                link            : AdminTicketsLocation.path, 
                Icon            : TicketIcon, 
            },
            {
                name            : 'pages.admin.comments',
                link            : AdminCommentsLocation.path,  
                Icon            : CommentIcon,
            },
            {
                name            : 'pages.admin.reviews',
                link            : AdminReviewsLocation.path,  
                Icon            : ReviewsIcon,
            },
            {   
                name            : 'pages.admin.users',
                link            : AdminUsersLocation.path,      
                Icon            : PersonIcon,
            },
            {   
                name            : 'pages.admin.products',
                link            : AdminProductsLocation.path,        
                Icon            : ShoppingCartIcon,
            },
            {   
                name            : 'pages.admin.coupons',
                link            : AdminCouponsLocation.path,            
                Icon            : CouponIcon,      
            },
            {  
                name            : 'pages.admin.library',
                link            : AdminLibraryLocation.path,            
                Icon            : LibraryIcon,
            },
            {   
                name            : 'pages.admin.features',
                link            : AdminFeaturesLocation.path,                   
                Icon            : SettingsIcon,
            },
            {   
                name            : 'pages.admin.emails',
                link            : AdminEmailsLocation.path,                   
                Icon            : EmailIcon,       
            },
            {   
                name            : 'pages.admin.examples',
                link            : AdminExamplesCollectionLocation.path,                         
                Icon            : ArchiveIcon,
            },
            {
                name            : 'pages.admin.resources',
                link            : AdminResourcesLocation.path,                     
                Icon            : LayersIcon,       
            },
            {   
                name            : 'pages.admin.playground',
                link            : AdminPlayGroundLocation.path,                       
                Icon            : GamesIcon,       
            },
            {   
                name            : 'pages.admin.dalle',
                link            : AdminDalleLocation.path,                       
                Icon            : BurstModeIcon,
            },
            {
                name            : 'pages.admin.symbols',
                link            : AdminSymbolsLocation.path,               
                Icon            : EmojiSymbolsIcon,
            },
            {
                name            : 'pages.admin.nakshatras',
                link            : AdminNakshatrasLocation.path,
                Icon            : NakshatrasIcon
            }
        ]
    ),[])

    const memoisedResult = React.useMemo(() => ({
        mainPages,
        otherPages,
        userPages,
        previewPages,
        adminPages
    }), [mainPages, otherPages, userPages, previewPages, adminPages] );

    return memoisedResult;
}

export default usePages;