/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Show More Replies Button Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { Box,Button as ButtonMUI}       from '@mui/material';
import AddIcon                          from '@mui/icons-material/Add';
import { v4 as uuidv4 }                 from 'uuid';
import { RefreshIcon }                  from 'components';
import { useStateEphemeral }            from 'hooks';
import withActionButton                 from './hoc/withActionButton';
import withTranslation                  from '../hoc/withTranslation';
import { withDisabledOffline }          from 'components/hoc';


const ButtonBase    = withActionButton(ButtonMUI);
const Button        = withDisabledOffline(ButtonBase);
const noop          = () => {};

export const CommentsShowMoreRepliesButton = withTranslation(({t : tBase, loading = false, hasMore = false, hasPostedReplies = false, hasDeletedReplies = false, allowRefresh = false, onClick = noop, onClickRefresh = noop, ...props}) => {
    
    const uuid                                  = React.useMemo(() => uuidv4(), []);
    const [idShowMore,idRefresh]                = React.useMemo(() => ([ `showmore-${uuid}`, `refresh-${uuid}` ]),[uuid]);
    const disabled                              = React.useMemo(() => !hasMore && !hasPostedReplies && !hasDeletedReplies, [hasDeletedReplies, hasMore, hasPostedReplies]);
    const [remaining, setRemaining]             = React.useState(0);
    const [refreshClicked, setRefreshClicked]   = useStateEphemeral(false, 5000, false, setRemaining);
    
    const handleRefreshClicked                  = React.useCallback(() => setRefreshClicked(true), [setRefreshClicked]);

    const handleShowMoreRepliesClick = React.useCallback(() => {
        Promise
            .resolve()
            .then(onClick)
            .catch(noop)
    },[onClick])

    const handleRefreshClick = React.useCallback(() => {
        Promise
            .resolve()
            .then(handleRefreshClicked)
            .then(onClickRefresh)
            .catch(noop)
    },[handleRefreshClicked, onClickRefresh]);

    const t = React.useCallback((suffix) => tBase(`components.comments.${suffix}`),[tBase])

    const labelShowMore = React.useMemo(() => {
        switch (true) {
            case loading:           return t('loading');
            case hasPostedReplies:  return t('reload');
            case hasMore:           return t('showMoreReplies');
            default:                return t('noMoreReplies');
        }
    }, [t, loading, hasMore, hasPostedReplies]);    

    return (
        <Box style={{display:'inline-flex'}}>
            {
                labelShowMore && 
                <Box>
                    <Button 
                        id          = {idShowMore}
                        disabled    = {disabled || loading} 
                        startIcon   = {
                            loading 
                                ? <RefreshIcon loading={loading}/> 
                                : <AddIcon sx={{fontSize : 15}}/>
                        }
                        size        = "small" 
                        onClick     = {handleShowMoreRepliesClick}
                    > { labelShowMore } </Button>
                </Box>
            }
            {
                allowRefresh && 
                <Box>
                    <Button 
                        id          = {idRefresh}
                        disabled    = { loading || refreshClicked} 
                        size        = "small" 
                        onClick     = {handleRefreshClick}
                    >
                        {
                            remaining > 0 
                                ? t('refreshThreadIn', { in : parseInt(remaining/1000) + 1})
                                : t('refreshThread')
                        }
                    </Button>
                </Box>
            }
        </Box>
    )
});

export default CommentsShowMoreRepliesButton;

