/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Radix Compoonent
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       11th October 2021

*******************************************************************************************/
import React                            from 'react';
import { Box, Typography}               from '@mui/material';
import { 
    Radix, 
    LoadingData
}                                       from 'components';
import withFeatureTypography            from 'components/hoc/withFeatureTypography';
import SkeletonRadix                    from 'components/skeletons/SkeletonRadix';
import { LibrarySubtitle }              from './LibrarySubtitle';
import {
    useLibraryViewer,
    useUser,
    useNetwork,
}                                       from 'contexts';
import { RootContainer }                from 'components'; 
import { withTranslation }              from 'components/hoc';
import { 
    useSize, 
    useHoroscopeDescriptor
}                                       from 'hooks';
import { useLibraryHoroscopeData }      from './LibraryHoroscopeDataContext';


const SkeletonRadixLoading = withFeatureTypography(SkeletonRadix);

const Loading = withTranslation( ({t,...props}) => (
    <Box sx={{width:'100%'}}>
        <LoadingData {...props} sx={{textAlign:'center',mx:'auto',width:'fit-content'}}>
            { t('common.loading') }
        </LoadingData>
    </Box>
));

const WaitingForData = withTranslation( ({t}) => (
    <Box sx={{width:'100%'}}>
        <Typography align="center">
            { t('common.waitingForData') }
        </Typography>
    </Box>
));

export const LibraryRadix = withTranslation(({
    t,
    id                      = 'radix', 
    title                   = undefined,
    showDescriptor          = false,
    ...props
}) => {

    const {isNetworkReady}              = useNetwork();
    const {ready}                       = useUser();
    const {
        loading : libraryLoading,
        data    : libraryData
    }                                   = useLibraryViewer();
    const ref                           = React.useRef(null);
    const {width}                       = useSize(ref)

    // Hold LibraryID State
    const {
        data, 
        loading : horoscopeDataLoading 
    }                                   = useLibraryHoroscopeData();
    
    const {descriptor}                  = useHoroscopeDescriptor(data, !!libraryData?.metadata?.userFieldsData?.natalRecord?.unknownTime);

    // Is Page Loading
    const isPageLoading                 = React.useMemo(() => libraryLoading || !ready || !isNetworkReady, [libraryLoading, ready, isNetworkReady]);

    return (
        <RootContainer ref={ref} sx={{position:'relative', m : 'auto'}}>
            <Box>
                {
                    !horoscopeDataLoading  && !isPageLoading && title !== null && // null explicitly if there is NO TITLE
                    <LibrarySubtitle>
                        {title || t('common.natalChart')}
                    </LibrarySubtitle>
                }
                {
                    (horoscopeDataLoading || !data) && 
                    <SkeletonRadixLoading 
                        typographyComponent = {horoscopeDataLoading  ? Loading : WaitingForData} 
                        showFeature         = {true}
                    />
                }
                {
                    (!horoscopeDataLoading && data) && 
                    <>
                        <Radix
                            id      = {id}
                            data    = {data}
                            show    = {true}
                            width   = {width}
                            {...props}
                        />
                        {
                            showDescriptor && descriptor && 
                            <Box>
                                <LibrarySubtitle>
                                    {t('common.summary')}
                                </LibrarySubtitle>
                                <Typography component="div" sx={{fontSize:'0.75rem'}} gutterBottom>
                                    {descriptor}
                                </Typography>
                            </Box>
                        }
                    </>
                }
            </Box>
        </RootContainer >
    )
})

export default LibraryRadix;