/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Use Query Params
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th October 2024

*******************************************************************************************/
// Re-export everything from 'use-query-params'
export * from 'use-query-params';

// Custom Boolean Param to handle both 1/0 and true/false
export const BooleanParamRevised = {
    encode: (bool) => {
        return (bool ? "true" : "false") // Or use "1" / "0" if preferred
    },
    decode: (value) => {
        return ["true", "1", "yes"].includes((value || "").toLowerCase()) // Convert truthy values to true
    }
};

// export { BooleanParam as BooleanParamOriginal } from 'use-query-params';

// Import and re-export specific items from sub-paths as needed
export { ReactRouter5Adapter as Adapter } from 'use-query-params/adapters/react-router-5';

// No default keyword needed here, since it's a named export
export { useQueryParam } from 'use-query-params';