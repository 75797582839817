/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Credits Required Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                        from 'react';
import { useHistory}                from "react-router-dom";
import {
    Box,
}                                   from '@mui/material';
import TollIcon                     from '@mui/icons-material/Toll';
import { 
    FormAlert,
    Button,
}                                   from 'components';
import { withTranslation }          from 'components/hoc';
import { 
    ShopLocation
}                                   from 'router/locations/Locations';
import {
    useAccountCredits
}                                   from 'contexts';

export const ExampleCreditsRequiredAlert = withTranslation(({t}) => {
    const history = useHistory();
    const { working : accountBalanceWorking } = useAccountCredits();
    return (
        <FormAlert 
            severity    = "warning" 
            action      = {
                <Box sx={{my:'auto'}}>
                    <Button disabled={accountBalanceWorking} startIcon={<TollIcon />} color="warning" size="small" variant="contained" sx={{whiteSpace:'nowrap'}} onClick={() => history.push(ShopLocation.path)} >
                        {t('components.example.buyCredits')}
                    </Button>
                </Box>
            }
        >
            {t('components.example.previewInsufficientCredits')}
        </FormAlert>
    )
});

export default ExampleCreditsRequiredAlert;
