/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Alert Disclaimer
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       13th February 2024

*******************************************************************************************/
import React                    from 'react';
import { MainAlert }            from 'components';
import { withTranslation }      from './hoc';
import { useUser }              from 'contexts';

export const AlertDisclaimer = withTranslation( ({t}) => {
    
    const {isLoading, isAuthenticated, isAdmin} = useUser();

    // Short Circuit for Admins
    if(isLoading || (isAuthenticated && isAdmin))
        return null;

    // Done
    return (
        <MainAlert dismissable={true} severity = "info">
            <strong>{t('components.alertDisclaimer.salulation')}:</strong> {t('components.alertDisclaimer.message')}
        </MainAlert>
    )
});

export default AlertDisclaimer;