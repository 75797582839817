/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Social Accounts
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       12th May 2022

*******************************************************************************************/
import React                            from 'react';
import { styled, Box, IconButton }      from '@mui/material';
import { SocialIcon }                   from 'react-social-icons';
import { XIcon}                         from "react-share";


const BlueskyIcon = ({ fgColor = "white", style = {} }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        style={style} // Apply passed style
    >
        <path
            fill={fgColor}
            d="M0,0H64V64H0ZM0 0v64h64V0Zm45.498 17.766a2.84 2.84 0 0 1 1.354.312c.578.296.932.915 1.103 1.92.085.513.046 2.326-.086 3.666-.013.125-.038.494-.064.822-.02.322-.054.696-.067.82-.013.125-.039.434-.058.69-.027.25-.06.585-.073.736-.02.158-.046.408-.066.559-.099.92-.118 1.07-.13 1.104-.014.02-.041.244-.067.494-.171 1.662-1.354 3.376-2.938 4.263-1.261.703-2.667 1.045-4.336 1.051-.801.007-.927.041-.447.133.94.17 2.005.518 2.86.945 2.247 1.11 3.041 2.747 2.331 4.778-.118.322-.25.636-.302.695-.053.052-.092.131-.092.164 0 .066-.617 1.025-.8 1.242-.06.072-.219.264-.35.428-.355.434-1.004 1.097-1.346 1.373-.164.131-.31.25-.33.27-.184.203-1.333.946-1.938 1.261-.808.42-1.425.591-2.135.598-.722.006-.953-.033-1.439-.256-1.386-.63-2.418-2.34-3.39-5.586-.421-1.426-.54-1.826-.598-2.082-.066-.276-.131-.218-.256.223-.381 1.38-1.117 3.344-1.623 4.363-.92 1.853-1.927 2.937-3.11 3.357-.499.178-1.45.166-2.029-.025-1.452-.486-2.899-1.623-4.357-3.443-1.426-1.774-2.116-3.292-2.037-4.475.046-.69.118-.933.447-1.426.302-.453.841-.914 1.44-1.236.426-.23 1.28-.584 1.609-.676.111-.026.375-.099.592-.158.216-.066.453-.126.525-.139.867-.17 1.255-.255 1.295-.281.026-.02-.302-.04-.723-.047-.42 0-.947-.026-1.164-.053-.453-.059-1.445-.276-1.642-.36a6 6 0 0 0-.46-.17c-1.241-.441-2.378-1.33-3.087-2.427-.316-.486-.659-1.221-.73-1.576a10 10 0 0 1-.19-1.123c-.027-.217-.054-.466-.067-.558a33 33 0 0 1-.197-1.873c-.026-.29-.053-.632-.066-.756a72 72 0 0 1-.073-.756c-.02-.29-.05-.631-.064-.756-.164-1.938-.172-3.68-.008-4.238.302-1.019.73-1.466 1.623-1.715.29-.08 1.157-.078 1.531.008 1.183.25 3.576 1.655 5.008 2.93.072.065.237.21.361.322.927.828 2.543 2.522 3.358 3.521.23.29.447.553.486.592.033.04.139.17.23.289.093.118.196.25.23.29.098.104 1.065 1.43 1.486 2.042.492.71 1.201 1.847 1.378 2.229.138.282.256.387.256.236 0-.105.836-1.485 1.434-2.365 1.866-2.76 4.257-5.488 6.353-7.262.566-.473.769-.63 1.63-1.22 1.648-1.126 2.937-1.676 4.015-1.688"
        />
    </svg>
);

const ButtonsContainer = styled(Box)(({theme}) => ({
    marginTop       : theme.spacing(2),
    marginBottom    : theme.spacing(2),
    '& > *' : {
        margin  : theme.spacing(0.15)
    }
}));

const   SOCIAL_FACEBOOK     = "cosmicprogramdotcom",
        SOCIAL_INSTAGRAM    = "cosmicprogramdotcom",
        SOCIAL_TWITTER      = "CosmicProgram",
        SOCIAL_PINTEREST    = "cosmicprogram",
        SOCIAL_YOUTUBE      = "@cosmicprogram",
        // SOCIAL_TIKTOK       = "@cosmicprogram",
        SOCIAL_TIKTOK       = "@cosmic.program",
        SOCIAL_MEDIUM       = "@cosmicprogram.com",
        SOCIAL_BSKY         = "cosmicprogram"

export const SocialAccounts = ({iconSize=32}) => {
    const args      = React.useMemo(() => ({fgColor:"white", style:{ height: iconSize, width: iconSize}}), [iconSize]);
    const argsBsky  = React.useMemo(() => ({...args,style:{...args?.style,background:'#0085ff',borderRadius:'50%'}}), [args]);
    const navigate  = React.useCallback( url => () => window.open(url, "_blank" /*, "noreferrer"*/ ),[]);
    return (         
        <ButtonsContainer>
            <IconButton onClick={navigate(`https://x.com/${SOCIAL_TWITTER}`)}>
                <SocialIcon {...args} network={"x"}>
                    <XIcon {...args} round={true} />
                </SocialIcon>
            </IconButton>
            <IconButton onClick={navigate(`https://www.facebook.com/${SOCIAL_FACEBOOK}`)}>
                <SocialIcon {...args} network="facebook" />
            </IconButton>
            <IconButton onClick={navigate(`https://www.instagram.com/${SOCIAL_INSTAGRAM}`)}>
                <SocialIcon {...args} network="instagram" />
            </IconButton>
            <IconButton onClick={navigate(`https://pinterest.com/${SOCIAL_PINTEREST}`)}>
                <SocialIcon {...args} network="pinterest" />
            </IconButton>
            <IconButton onClick={navigate(`https://medium.com/${SOCIAL_MEDIUM}`)}>
                <SocialIcon {...args} network="medium"/>
            </IconButton>
            <IconButton onClick={navigate(`https://youtube.com/${SOCIAL_YOUTUBE}`)}>
                <SocialIcon {...args} network="youtube" />
            </IconButton>
            <IconButton onClick={navigate(`https://www.tiktok.com/${SOCIAL_TIKTOK}`)}>
                <SocialIcon {...args} network="tiktok"/>
            </IconButton>
            <IconButton onClick={navigate(`https://bsky.app/profile/${SOCIAL_BSKY}.bsky.social`)}>
                <SocialIcon {...argsBsky} network="bsky">
                    <BlueskyIcon round={true} />
                </SocialIcon>
            </IconButton>
        </ButtonsContainer>
    )
};

export default SocialAccounts;