/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Admin Inactive Notice
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       10th October 2024

*******************************************************************************************/
import React                        from 'react';
import { useNetwork, useUser }      from 'contexts';
import { withTranslation }          from 'components/hoc';
import { FormAlert }                from 'components';
import { useExample }               from './ExampleContext';

export const ExampleAdminInactiveNotice = withTranslation(({t,...props}) => {
    
    const { queried, data }     = useExample();
    const { isNetworkReady }    = useNetwork();
    const { isAdmin }           = useUser();
    
    // Short Circuit
    if(!isAdmin || !isNetworkReady || !queried || data?.active)
        return null;

    // Done
    return (
        <FormAlert severity="error" {...props}>
            { t('components.example.adminError404Note') } 
        </FormAlert>
    )
});

export default ExampleAdminInactiveNotice;