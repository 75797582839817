/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Aspects Grid
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th October 2024

*******************************************************************************************/
import React                            from 'react';
import { 
    Table, TableBody, TableCell as TableCellMUI, TableContainer, 
    TableHead, TableRow, Paper, styled, alpha
}                                       from '@mui/material';
import { grey }                         from '@mui/material/colors'; // Import MUI grey colors
import { Glyph, SkeletonTable }         from 'components';
import { 
    useCelestialBody, 
    useCelestialPoint,
    useNetwork, 
}                                       from 'contexts';
import { useGlyphs }                    from 'hooks';

// Styled TableCell component with common styles
const TableCell = styled(TableCellMUI)(({ theme, numColumns, border = true, isHeader = false, isDiagonal = false, isLowerTriangle = false}) => {
    const isDark = theme.palette.mode === 'dark'; // Determine if the theme is dark
    return {
        padding             : 0,                                    // Removes padding
        textAlign           : 'center',                             // Centers the content
        width               : `${100 / (numColumns)}%`,             // Set width dynamically based on the number of columns
        border              : (                                     // Conditional border
            border 
                ? `1px solid ${theme.palette.divider}` 
                : 'none'
        ),  
        backgroundColor     : 
            isHeader 
                ? alpha(theme.palette.primary.light,0.25)           // Header Background
                : isLowerTriangle
                    ? (isDark ? grey[600] : grey[400])              // Lower triangle shading (dark grey for dark mode, light grey for light mode)
                    : isDiagonal
                        ? (isDark ? grey[700] : grey[300])          // Diagonal shading (medium grey for dark mode, lighter grey for light mode)
                        : 'transparent',                            // No shading for upper triangle and headers
        backgroundImage     : 
            isDiagonal
                ? `repeating-linear-gradient(
                        135deg, 
                        transparent, 
                        transparent 2px,  /* Transparent part of each stripe */
                        ${isDark ? grey[800] : grey[400]} 2px, /* Darker part of each stripe */
                        ${isDark ? grey[800] : grey[400]} 4px  /* Make stripes fine */
                    )`
                : 'none',                                           // Apply slanted hatch pattern to diagonal cells only
        backgroundSize  : '6px 6px',                                // Size of the finer hatch pattern
    }
});

const makeDictFunction      = collection => collection.reduce((acc,cur) => ({...acc, [cur?.name] : cur }),{});
const makeRowKey            = (rowIndex) => `row-${rowIndex}`;
const makeCellKey           = (rowIndex,colIndex) => `cell-${rowIndex}-${colIndex}`;
const aspectMatchFunction   = (nameA = '', nameB = '') => (
    aspectData => (
        nameA &&            // Has Value
        nameB &&            // Has Value
        nameA !== nameB &&  // Values Differ
        (                   // Match point1key and point2key in either order
            (aspectData?.point1Key === nameA && aspectData?.point2Key === nameB) || 
            (aspectData?.point1Key === nameB && aspectData?.point2Key === nameA)
        )
    )
);

const obj = {};

export const AspectsGrid = ({
    loading                     = false,
    dataHoroscope               = undefined,
    cellProps                   = obj
}) => {

    const { isNetworkReady }    = useNetwork();
    const glyphDict             = useGlyphs();

    const {
        data        : celestialBodies, 
        enabled     : enabledCB,   
        enable      : enableCBFun, 
        working     : workingCB
    } = useCelestialBody();

    const {
        data        : celestialPoints, 
        enabled     : enabledCP, 
        enable      : enableCPFun, 
        working     : workingCP
    } = useCelestialPoint();
    
    React.useEffect(() => {
        if(!enabledCB)  enableCBFun();
        if(!enabledCP)  enableCPFun();
    },[enableCBFun, enabledCB, enableCPFun, enabledCP]);

    const names = (
        [celestialBodies, celestialPoints]
            .map(makeDictFunction)
            .flatMap(Object.keys)
    );

    const gridSize = React.useMemo(() => (
        names.length + 1
    ), [names?.length]);

    if(!dataHoroscope)
        return null;

    if(loading || !isNetworkReady || workingCB || workingCP)
        return <SkeletonTable rowQuantity={3}/>

    return (
        <TableContainer component={Paper}>
            <Table>
                {/* Table Head: Column headers */}
                <TableHead>
                    <TableRow key={makeRowKey(0)}>

                        {/* Empty cell at the top-left corner */}
                        <TableCell 
                            key             = { makeCellKey( 0, 0 ) } 
                            numColumns      = { gridSize } 
                            border          = { false } 
                            isDiagonal      = { false } 
                            isLowerTriangle = { false } 
                            isHeader        = { false } 
                        /> 

                        {
                            names.map((name,colIndex) => (
                                <TableCell 
                                    key             = { makeCellKey( 0, colIndex + 1 ) } 
                                    {...cellProps} 
                                    numColumns      = { gridSize } 
                                    border          = { true } 
                                    isDiagonal      = { false } 
                                    isLowerTriangle = { false } 
                                    isHeader        = { true } 
                                >
                                    <Glyph src = {glyphDict[name]} alt = {name} />
                                </TableCell>
                            ))
                        }

                    </TableRow>
                </TableHead>
        
                {/* Table Body: Row headers and upper triangle cells */}
                <TableBody>
                    {
                        names.map((rowName, rowIndex) => (

                            <TableRow key={makeRowKey( rowIndex + 1 )}>
                                
                                {/* Row header */}
                                <TableCell 
                                    key             = { makeCellKey(rowIndex + 1, 0) } 
                                    {...cellProps} 
                                    numColumns      = { gridSize }  
                                    border          = { true }  
                                    isDiagonal      = { false }  
                                    isLowerTriangle = { false }  
                                    isHeader        = { true }
                                >
                                    <Glyph src = {glyphDict[rowName]} alt = {rowName} />
                                </TableCell>

                                {/* Upper triangle cells */}
                                {
                                    names.map((colName, colIndex) => {
                                        const isDiagonal        = colIndex === rowIndex;
                                        const isLowerTriangle   = colIndex < rowIndex;
                                        if(isDiagonal || isLowerTriangle){
                                            return (
                                                <TableCell 
                                                    key             = { makeCellKey(rowIndex + 1, colIndex + 1) }
                                                    {...cellProps} 
                                                    numColumns      = { gridSize }
                                                    border          = { true }  
                                                    isDiagonal      = { isDiagonal }  
                                                    isLowerTriangle = { isLowerTriangle }  
                                                    isHeader        = { false }
                                                />
                                            )
                                        }
                                        
                                        // Extract Data
                                        const points            = dataHoroscope?.Aspects?.points;
                                        const aspectsData       = [rowName, colName]
                                            .flatMap(key => Array.isArray(points?.[key]) ? points[key] : [])
                                            .filter(aspectMatchFunction(rowName, colName));
                                        
                                        // Extract Name
                                        const aspectName    = aspectsData[0]?.aspectKey;
                                        const tooltip       = aspectName && `${rowName} ${aspectName} ${colName}`

                                        // Render Cell
                                        return (
                                            <TableCell 
                                                key             = { makeCellKey(rowIndex + 1, colIndex + 1) }
                                                {...cellProps} 
                                                numColumns      = { gridSize }
                                                border          = { true } 
                                                isDiagonal      = { false } 
                                                isLowerTriangle = { false } 
                                                isHeader        = { false }
                                            >
                                                {
                                                    aspectName && 
                                                    <Glyph 
                                                        src         = {glyphDict[aspectName]} 
                                                        alt         = {aspectName} 
                                                        tooltip     = {tooltip}
                                                    />
                                                }
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AspectsGrid;