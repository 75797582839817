/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Archive Cover
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd December 2022

*******************************************************************************************/

import React                    from 'react';
import { Link }                 from 'react-router-dom';
import { 
    styled,
    alpha, 
    Box,
    Card,
    CardMedia,
    useMediaQuery
}                               from '@mui/material';
import { 
    Button, 
    Skeleton,
    Overlay
}                               from 'components';
import { withFeatureLoading }   from 'components/hoc';
import { useIsTouchScreen }     from 'hooks';

const noop = () => {}

const ButtonContainer = styled(Box)(({theme}) => ({
    color           : theme.palette.warning.main,
    textAlign       : "center" 
}))

const CardMediaMod = withFeatureLoading(CardMedia);

const CardMediaWithLoading = ({ onLoaded = noop, ...props }) => {
    const [loaded, setLoaded] = React.useState(false);
    const handleImageLoad = () => {
        setLoaded(true);
        onLoaded();  // Trigger callback after image is loaded
    };
    return (
        <CardMediaMod
            loading={!loaded}
            onLoad={handleImageLoad}
            loadingProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden' } }}
            {...props}
        />
    );
};

const ButtonInner = (props) => {
    const variant = useMediaQuery(theme => theme.breakpoints.up('sm')) ? 'outlined' : 'text';
    return (
        <Button
            variant={variant}
            {...props}
            sx={{
                '&.MuiButton-root': {
                    minWidth        : 0,
                    width           : 'fit-content',
                    fontSize        : '0.5rem',
                    color           : '#ffffff',
                    borderColor     : '#ffffff',
                    padding         : theme => theme.spacing(0.5),
                    background      : theme => alpha(theme.palette.warning.main, 0.25),
                    '&:hover': {
                        background  : theme => alpha(theme.palette.warning.main, 0.35),
                        borderColor : '#ffffff',
                    },
                },
                ...props.sx,
            }}
        />
    );
};

export const ImageLinkWithOverlay = ({
    children,
    to,
    name,
    alt,
    photo,
    aspectRatio = '0.71',
    overlayEnabled = true,
    renderButton,
    onLoaded = noop,
    ...props
  }) => {
    const isTouch               = useIsTouchScreen();
    const [loaded, setLoaded]   = React.useState(false);
    const [over, setOver]       = React.useState(false);
  
    const handleHover = React.useCallback((action) => {
        if (!isTouch) {
            setOver(action === 'enter');
        }
    }, [isTouch]);
  
    // Default to current location URL if 'to' is not provided
    const toUrl = to || window.location.pathname + window.location.search;
  
    return (
      <Card
        component       = {to ? Link : Box}
        to              = {toUrl}
        {...props}
        onMouseEnter    = {() => handleHover('enter')}
        onMouseLeave    = {() => handleHover('leave')}
        style={{
          position: 'relative',
          cursor: 'pointer',
          ...props?.style,
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, aspectRatio }}>
          <Skeleton />
        </Box>
        <CardMediaWithLoading
            component = "img"
            alt       = {alt || name}
            image     = {photo}
            style     = {{ aspectRatio }}
            onLoaded  = {() => { 
                setLoaded(true);  // Update the loaded state
                onLoaded();       // Call the onLoaded callback
            }}
        />
        {
            children
        }
        {
            loaded && overlayEnabled && over && !isTouch &&
            <Overlay>
                <Box display="flex" flexDirection="column" height="100%">
                    <Box flexGrow={1} />
                    <ButtonContainer>
                        {!renderButton && name && <ButtonInner>{name}</ButtonInner>}
                        {renderButton && renderButton()}
                    </ButtonContainer>
                    <Box flexGrow={1} />
                </Box>
            </Overlay>
        }
      </Card>
    );
};

export default ImageLinkWithOverlay;