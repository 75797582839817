/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Admin Box
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       24th July 2021

*******************************************************************************************/

import React                    from 'react';
import {
    styled,
    Box,
    Grid,
    Typography
}                               from '@mui/material';
import AdminIcon                from '@mui/icons-material/SupervisorAccount';
import {
    ItemPaper
}                               from 'components'
import { withTranslation }      from './hoc';
import { 
    useUser,
}                               from 'contexts';

const Container = styled(Box)(({theme}) => ({
    position        : 'absolute',
    left            : 0,
    top             : 0,
    marginRight     : theme.spacing(1),
    marginLeft      : theme.spacing(1),
    transform       : 'translatey(-50%)',
    background      : theme.palette.error.main,
    padding         : 1,
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0.5),
    borderRadius    : theme.spacing(0.5),
    color           : theme.palette.primary.contrastText
}));

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'withBorder',
})(({ theme, withBorder = true}) => ({
    ...(withBorder && {
        padding       : theme.spacing(2),
        paddingTop    : theme.spacing(3),
        border        : '1px dashed red',
        borderRadius  : theme.spacing(2),
    }),
}));

export const AdminBox = withTranslation( ({t, children, withBorder = true, disabled=false, label=undefined, ...props}) => {
    
    const {isAuthenticated,isAdmin} = useUser();
    
    if(!isAuthenticated || !isAdmin)
        return null;

    return (
        <StyledBox component={ItemPaper} p={2} withBorder={withBorder} style={{position:'relative'}} sx={{...props?.sx}}>
            {
                !disabled &&
                <Container>
                    <Grid container spacing={0} direction="row" alignItems="center">
                        <Grid item>
                            <AdminIcon style={{fontSize:'1rem',transform:`translatey(2px)`}}/>
                        </Grid>
                        <Grid item>
                            <Typography style={{fontSize:'0.75rem',paddingLeft:2}} color="inherit">
                                {label || t('components.adminBox.label')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            }
            {children}
        </StyledBox>
    )
});

export default AdminBox;