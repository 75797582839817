/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useSteps Hook
********************************************************************************************
Keep track of steps, 0 based.

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th March 2021

********************************************************************************************/
import React from 'react';

export const useSteps = ({
    initialStep = 0, 
    steps       = 1
}) => {

    // Message and Set Message hook
    const [maxStep,     setMaxStep] = React.useState(Math.max(1,steps));
    const [step,        setStep]    = React.useState(Math.min(Math.max(initialStep,0),maxStep - 1));
    const [highest,     setHighest] = React.useState(step);
    const [lowest,      setLowest]  = React.useState(step);

    const currentStep   = (v)   => setStep(Math.min(Math.max(v,0), maxStep));
    const nextStep      = ()    => setStep(prev => Math.min(prev + 1,maxStep));
    const prevStep      = ()    => setStep(prev => Math.max(prev - 1,0));
    const lastStep      = ()    => setStep(maxStep - 1);
    const firstStep     = ()    => setStep(0);

    // Max Steps has Changed
    React.useEffect(()=>{
        setMaxStep(Math.max(1,steps));
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[steps])

    // If initial Step or Max Step changes, Clamp Step.
    React.useEffect(() => {
        setStep(Math.min(Math.max(initialStep,0),maxStep - 1));
    },[initialStep,maxStep]);

    // If step Changes, then change higest or lowest
    React.useEffect(()=>{
        if(step > highest)  setHighest(step);
        if(step < lowest)   setLowest(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[step])

    // Binary Flags
    const isLastStep    = step === maxStep - 1;
    const isFirstStep   = step === 0;

    // Return the Message and SetMessage hook
    return {
        step,
        currentStep,
        firstStep,
        nextStep,
        prevStep,
        lastStep,
        isFirstStep,
        isLastStep,
        highest,
        lowest,
    };
};

export default useSteps;