/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Frosted Box
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th January 2024

*******************************************************************************************/
import React                    from 'react';
import {
    styled,
    Box,
}                               from '@mui/material';

const FrostedOverlay = styled(Box,{
    shouldForwardProp : prop => prop !== 'blurSize'
})(({theme, blurSize = 3}) => ({
        position            : 'absolute',
        top                 : 0,
        left                : 0,
        right               : 0,
        bottom              : 0,
        backdropFilter      : `blur(${blurSize.toString().replace('px','')}px)`
}))

export const FrostedBox = ({children, blurSize = 3, hide = false, ...props}) => {
    return (
        <Box {...props} sx={{position: 'relative',...props.sx}}>
            {children}
            {
                hide && 
                <FrostedOverlay hide={hide} blurSize={blurSize}/>
            }
        </Box>
    )
}

export default FrostedBox;

