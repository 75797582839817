/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Actions Box
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th November 2020

*******************************************************************************************/
import React    from 'react';
import { Box }  from '@mui/material';

export const ActionsBox = ({children, actions, ...props}) => (
    <Box display="flex" {...props} >
        <Box flexGrow={1}>
            {children}
        </Box>
        {
            actions && 
            <Box sx={{pl:1}}>
                {actions}
            </Box>
        }
    </Box>
)

export default ActionsBox;