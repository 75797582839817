/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order Table Components
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th July 2021

*******************************************************************************************/
import React                            from 'react';
import { titleCase }                    from 'title-case';
import {
    styled,
    useTheme,
    Box,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell as TableCellMUI,
    TableRow,
}                                       from '@mui/material';
import ShippingIcon                     from '@mui/icons-material/FlightTakeoff';
import { 
    RootContainer,
    Currency,
    Title,
    ViewProductButton,
    JSONViewer,
    ItemPaper
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useOrderViewer }               from './context/OrderViewerContext';

const OrderTaxInvoiceButton  = React.lazy(() => import('components/order/OrderTaxInvoiceButton'));

const noop = () => {};
const formatType = value => titleCase((value || '').toLowerCase());

const ITEM_TYPES = [
    'PRODUCT',
    "TIP"
];

const TableCell = styled(TableCellMUI,{
    shouldForwardProp : prop => prop !== 'lastRow' && prop !== 'totalRow'
})(({theme, lastRow = false, totalRow = false}) => ({
    // padding     : 0,
    // fontSize    : '0.5rem'
    ...(lastRow && {
        borderBottom : 'none',
    }),
    ...(totalRow && {
        fontWeight  : 400,
    }),
}));

export const OrderTableHead = withTranslation( ({t, includeType}) => {
    return (
        <TableRow>
            {
                includeType && 
                <TableCell align="left">
                    {t('common.type')}
                </TableCell>
            }
            {
                includeType && 
                <TableCell   align="center">
                    {t('common.invoice')}
                </TableCell>
            }
            <TableCell   width="50%">
                {t('common.description')}  
            </TableCell>
            <TableCell   align="right">
                {t('common.price')}
            </TableCell>
            <TableCell   align="center">
                {t('common.qty')}
            </TableCell>
            <TableCell   align="right">
                {t('common.subtotal')}     
            </TableCell>
            <TableCell   align="center" colSpan={2}>
                {t('common.tax')}
            </TableCell>
            <TableCell   align="right">
                {t('common.total')}         
            </TableCell>
        </TableRow>
    )
});

export const OrderTableEmptyRow = ({
    includeType,
    name, 
    indentLevel     = 0
}) => {
    const theme     = useTheme();
    const indent    = theme.spacing((1 + indentLevel) * 2);
    return (
        <TableRow>
            {
                includeType && 
                <TableCell align="right"/>
            }
            { 
                includeType && 
                <TableCell align="left" />
            }
            <TableCell style = {{ paddingLeft : indent}}> 
                {name} 
            </TableCell>
            <TableCell align="right" colSpan={7}/>
        </TableRow>
    )
}

export const OrderTableRow = ({Action, onClick = noop, onDoubleClick = noop, name, includeType, type, quantity, unitPrice, invoice, price, taxCode, taxRate, taxes, total, requiresShipping=false, indentLevel=0, ...props}) => {
    const theme     = useTheme()
    const indent    = theme.spacing((indentLevel + 1) * 2);
    return (
        <TableRow style={props.style} onClick={onClick} onDoubleClick={onDoubleClick}>
            {
                includeType && 
                <TableCell align="left">
                    { formatType(type) }
                </TableCell>
            }
            {
                includeType && 
                <TableCell align="center" sx={{p:0}}>
                    {
                        invoice && 
                        <OrderTaxInvoiceButton invoiceId={invoice?._id} color="secondary" variant="text" size="small" showEmail={false}>
                            {invoice?._number}
                        </OrderTaxInvoiceButton>
                    }
                </TableCell>
            }
            <TableCell style={{paddingLeft : indent, paddingRight:0, minWidth:250}}>
                <Box display={'flex'}>
                    <Box flexShrink={1} style={{margin:'auto'}}>
                        {name}
                    </Box>
                    {
                        requiresShipping &&
                        <Box flexShrink={1} style={{margin:'auto'}}>
                            <ShippingIcon color="default"/>
                        </Box>
                    }
                    <Box flexGrow={1}/>
                    { Action && 
                        <Box flexShrink={1}>
                            {Action}
                        </Box>
                    }
                </Box>
            </TableCell>
            <TableCell align="right"> 
                <Currency value={unitPrice} />
            </TableCell>
            <TableCell align="center"> 
                {quantity} 
            </TableCell>
            <TableCell align="right"> 
                <Currency value={price} />
            </TableCell>
            <TableCell align="right" style={{whiteSpace: 'nowrap'}}> 
                {taxCode} ({(100*taxRate).toFixed(0)}%)
            </TableCell>
            <TableCell align="right"> 
                <Currency value={taxes}/>
            </TableCell>
            <TableCell align="right"> 
                <Currency value={total}/>
            </TableCell>
        </TableRow>
    )
}

export const OrderTableTotal = withTranslation( ({
    t,
    name            = undefined,
    includeType     = false,
    type            = undefined,
    invoice         = undefined,
    onClick         = noop, 
    onDoubleClick   = noop, 
    price           = undefined,
    quantity        = undefined,
    totalEx, 
    taxes, 
    total, 
    lastRow         = false, 
    totalRow        = false,
    indentLevel     = 0, 
    ...props
}) => {
    const theme     = useTheme()
    const indent    = theme.spacing((1 + indentLevel) * 2);
    const args      = React.useMemo(() => ({lastRow,totalRow}), [lastRow,totalRow]);
    return (
        <TableRow style={{borderBottom:undefined, ...props.style}} onClick={onClick} onDoubleClick={onDoubleClick}>
            {
                includeType && 
                <TableCell {...args} align="left">
                    { formatType(type) }
                </TableCell>
            }
            {
                includeType && 
                <TableCell {...args} align="center" sx={{p:0}}>
                    {
                        invoice &&
                        <OrderTaxInvoiceButton invoiceId={invoice?._id} color="secondary" variant="text" size="small" showEmail={false}>
                            {invoice?._number}
                        </OrderTaxInvoiceButton>
                    }
                </TableCell>
            }
            <TableCell {...args} style={{paddingLeft : indent}}> 
                {name || t('common.total')}      
            </TableCell>
            <TableCell {...args} align="center">
                {
                    !isNaN(price) && 
                    <Currency value={price}/>
                }
            </TableCell>
            <TableCell {...args} align="center">
                {quantity}
            </TableCell>
            <TableCell {...args} align="right"> 
                <Currency value={totalEx}/>
            </TableCell>
            <TableCell {...args} align="right"/>
            <TableCell {...args} align="right"> 
                <Currency value={taxes}/>
            </TableCell>
            <TableCell {...args} align="right"> 
                <Currency value={total} />
            </TableCell>
        </TableRow>
    )
})

export const OrderTable = withTranslation( ({t, includeType = true, ...props}) => {

    const theme                             = useTheme();
    const {data:order, orderId}             = useOrderViewer();

    const isCancelled                       = React.useMemo(() => order && order.isCancelled && order.paymentStatus.startsWith('CANCELLED'),[order])
    const isExpired                         = React.useMemo(() => order && order.isCancelled && order.paymentStatus.startsWith('EXPIRED'),[order])
    const items                             = React.useMemo(() => (order?.items || []).filter(x =>  ITEM_TYPES.includes(x.__t)), [order?.items]);
    const adjustments                       = React.useMemo(() => (order?.items || []).filter(x => !ITEM_TYPES.includes(x.__t) && x.total !== 0), [order?.items]);
    const [ast,atx,ato]                     = React.useMemo(() => (['subtotal','taxes','total'].map(x => adjustments.reduce((a,c) => a + (c[x] || 0),0) )),[adjustments]);

    if(!order || !orderId)
        return null;

    return (
        <RootContainer component={ItemPaper} id="orderTable">
            <Title variant="h5" gutterBottom>
                {
                    (isExpired || isCancelled)
                        ? t('components.order.orderTable.itemsIncludedInOriginal') 
                        : t('components.order.orderTable.itemsIncludedInThis')
                }
            </Title>
            <TableContainer style={{margin:-0,width:'calc(100% + 0px)'}}>
                {
                    order && 
                    <Table>
                        <TableHead>
                            <OrderTableHead includeType = {includeType}/>
                        </TableHead>
                        <TableBody>
                            {
                                false && 
                                <OrderTableEmptyRow 
                                    includeType = {includeType}
                                    name = {
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                {t('common.allItemsQuantity', {quantity : items.length})}
                                            </Box>
                                        </Box> 
                                    }
                                    indentLevel = {0}
                                />
                            }
                            {
                                items.map((item,ix) => {

                                    return (
                                        <OrderTableRow
                                            key                 = {ix}
                                            name                = {item?.description}
                                            Action              = { 
                                                <Box sx={{m : -1}}>
                                                    <ViewProductButton modal={true} productId={item.product} size="small" sx={{p:1}}>
                                                        {t('common.view')}
                                                    </ViewProductButton>
                                                </Box> 
                                            }
                                            includeType         = {includeType}
                                            type                = {item?.__t}
                                            requiresShipping    = {item?.requiresShipping}
                                            quantity            = {item?.quantity}
                                            unitPrice           = {item?.price}
                                            price               = {item?.subtotal}
                                            taxCode             = {item?.taxCode}
                                            taxRate             = {item?.taxRate}
                                            taxes               = {item?.taxes}
                                            total               = {item?.total}
                                            invoice             = {item?.metadata?.invoice}
                                            indentLevel         = {0}
                                        /> 
                                    )
                                })
                            }
                            
                            {Boolean(adjustments.length) &&
                                <>
                                    <OrderTableTotal 
                                        includeType = {includeType}
                                        indentLevel = {0} 
                                        isSubtotal  = {true} 
                                        totalEx     = {(order?.subtotal || 0) - ast} 
                                        taxes       = {(order?.taxes || 0) - atx} 
                                        total       = {(order?.total || 0) - ato} 
                                        name        = { t('common.subtotalItems') }
                                    />

                                    {false && 
                                        <OrderTableEmptyRow 
                                            includeType = {includeType}
                                            name        = {
                                                t('components.order.orderTable.allAdjustmentsQuantity', { quantity : adjustments.length }) 
                                            }
                                            indentLevel = {0} 
                                        /> 
                                    }
                                    {
                                        adjustments.map( ({price, quantity, subtotal, taxes, total, description, metadata, error, __t}, ix) => {
                                            let name = description ||  t('components.order.orderTable.djustmentNumber', { number : ix + 1 }) ;
                                            return (
                                                <OrderTableTotal
                                                    name        = { <> {name}{error ? <>, <span style={{color:theme.palette.error.main}}>{error}</span></> : null} </> } 
                                                    includeType = {includeType}
                                                    key         = {ix}
                                                    invoice     = {metadata?.invoice}
                                                    type        = {__t}
                                                    quantity    = {quantity}
                                                    price       = {price}
                                                    indentLevel = {0} 
                                                    isSubtotal  = {true} 
                                                    totalEx     = {subtotal} 
                                                    taxes       = {taxes} 
                                                    total       = {total} 
                                                />
                                            )
                                        })
                                    }
                                    {
                                        adjustments.length > 0 && 
                                        <OrderTableTotal 
                                            name        = { t('common.subtotalAdjustments') }
                                            includeType = {includeType}
                                            indentLevel = {0} 
                                            isSubtotal  = {true} 
                                            totalEx     = {ast} 
                                            taxes       = {atx} 
                                            total       = {ato} 
                                        />
                                    }
                                </>
                            }

                            <OrderTableTotal 
                                name        = { t('common.total') }
                                includeType = {includeType}
                                indentLevel = {0} 
                                isSubtotal  = {true} 
                                totalEx     = {order?.subtotal || 0} 
                                taxes       = {order?.taxes || 0} 
                                total       = {order?.total || 0}
                                lastRow
                                totalRow
                            />
                        </TableBody>
                    </Table>
                }
            </TableContainer>

            {
                false && 
                <JSONViewer src={order} />
            }
        </RootContainer>
    )
})

export default OrderTable;