/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Biography Title
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th October 2024

*******************************************************************************************/
import React                    from 'react';
import { Title }                from 'components';
import { withTranslation }      from 'components/hoc';

export const ExampleBiographyTitle = withTranslation(({t}) => {
    return (
        <Title variant="h5" component="h3">
            { t('common.biography') }
        </Title>
    )
});

export default ExampleBiographyTitle;