/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Radix
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th September 2022

*******************************************************************************************/
import React                            from 'react';
import scriptjs                         from 'scriptjs';
import omit                             from 'lodash/omit';
import { 
    styled, 
    lighten, 
    useTheme, 
    Box 
}                                       from '@mui/material';
import { JSONViewer }                   from 'components';
import { useAspect, useNetwork}         from 'contexts';
import { uniqueId }                     from 'lodash';
import config                           from '../config';

const {
    staticRootUrl
} = config;

const   DEBUG         = false,
        USE_ECLIPTIC  = true;

const processHoroscopeData = (result, ecliptic = USE_ECLIPTIC) => {
  
    const position  = Boolean(ecliptic) ? "Ecliptic" : "Horizon";

    const planetMapping = {
        "northnode" : "NNode",
        //"southnode" : null,
        "sun"       : undefined,
        "moon"      : undefined,
        "mercury"   : undefined,
        "venus"     : undefined,
        "mars"      : undefined,
        "jupiter"   : undefined,
        "saturn"    : undefined,
        "uranus"    : undefined,
        "neptune"   : undefined,
        "pluto"     : undefined,
        //"sirius"    : null,
        "chiron"    : undefined,
        "lilith"    : undefined,
    }
    let planets = {};
    planets     = result.CelestialBodies
                    .all
                    .filter(item => Object.keys(planetMapping).includes(item.key))
                    .reduce((acc,cur) => ({...acc, [planetMapping[cur.key] || cur.label]: [cur.ChartPosition[position].DecimalDegrees]}), planets);
    planets     = result.CelestialPoints
                    .all
                    .filter(item => Object.keys(planetMapping).includes(item.key))
                    .reduce((acc,cur) => ({...acc, [planetMapping[cur.key] || cur.label]: [cur.ChartPosition[position].DecimalDegrees]}), planets);              
    let cusps   = []
    cusps       = result.Houses.map(item => item.ChartPosition.StartPosition[position].DecimalDegrees);
    return {
        planets, 
        cusps
    }
};

const Root = styled(Box)({
    width           : '100%',
    textAlign       : 'center',
    position        : 'relative'
})

const RadixContainer = styled(Box,{
    shouldForwardProp : prop => !['width','display'].includes(prop)
})(({theme, width = 400, display = 'block'}) => ({
    position        : 'relative',
    // marginBottom    : theme.spacing(-2),
    textAlign       : 'center',
    width           : width,
    height          : 'fit-content',
    display         : display
}))

//  const PRODUCTION_MODE = process?.env?.NODE_ENV === 'production';
//  { /* <script src={`${staticRootUrl || ''}/static/js/astrochart.min.js`}></script> */ }

export const Radix = ({data = undefined, id : idIn = undefined, show = true, width = 600, debug = DEBUG, ecliptic = true, ...props}) => {
    
    const theme                 = useTheme();
    const {isNetworkReady}      = useNetwork();
    const id                    = React.useMemo(() => idIn || uniqueId('radix'), [idIn]);
    const [loaded, setLoaded]   = React.useState(false)
    const { 
        data    : aspectData, 
        enable  : enableAspects
    }                           = useAspect();

    // Remove children of id
    const removeOldChartInstances = React.useCallback((id) => {
        if(id){
            const parent = document.getElementById(id);
            if(parent){
                while (parent.firstChild) {
                    parent.removeChild(parent.firstChild);
                }
            }
        }
    },[])
    
    // Enable Aspects
    React.useEffect(enableAspects, [enableAspects]);

    // Load Script, Set Loaded on Complete
    React.useEffect(() => {
        setLoaded(false);
        scriptjs(`${staticRootUrl || ''}/static/js/astrochart.min.js`, () => {
            setLoaded(true);
        })
    }, [])

    // Redraw
    React.useEffect( () => {

        try{

            // Script Not Loaded
            if(!window.astrology || !loaded || !data || !isNetworkReady){
                // Cleanup
                return () => {
                    removeOldChartInstances(id);
                }
            }

            const RADIX_OPTIONS = {
                MARGIN            : 20,
                PADDING           : 0, 
                SYMBOL_SCALE      : 0.6, 
                STROKE_ONLY       : false,
                SHIFT_IN_DEGREES  : 180,
                DEBUG             : false,
                RULER_RADIUS      : 2,
                ASPECTS           : { //Default only, will be retrieved from server
                    "conjunction" : {"degree":0,   "orbit":10,  "color":"red"}, 
                    "sextile"     : {"degree":60,  "orbit": 5,  "color":"magenta"},
                    "square"      : {"degree":90,  "orbit":10,  "color":"#0000FF"}, 
                    "trine"       : {"degree":120, "orbit":10,  "color":"#27AE60"},
                    "opposition"  : {"degree":180, "orbit":10,  "color":"#FF4500"}
                },
                // COLOR_BACKGROUND : 'red',
                SYMBOL_AXIS_FONT_COLOR : theme.palette.mode === 'dark' ? 'white' : 'black',
                // LINE_COLOR : theme.palette.text.secondary, //theme.palette.mode === 'dark' ? 'white' : 'black',
                POINTS_COLOR : 'black',
                // COLOR_BACKGROUND : theme.palette.background.paper
            }

            function standardize_color(str){
                var ctx = document.createElement("canvas").getContext("2d");
                ctx.fillStyle = str;
                return ctx.fillStyle;
            }

            // Updated options
            const optionsLocal = {
                ...RADIX_OPTIONS,
                ASPECTS : aspectData 
                    ? aspectData.reduce((acc,cur) => ({
                        ...acc, 
                        [cur.name] : {
                            degree  : cur.degree, 
                            orbit   : cur.orb, 
                            color   : theme.palette.mode === 'dark' ? lighten(standardize_color(cur.color),0.5) : cur.color
                        } 
                    }),{})
                    : RADIX_OPTIONS.ASPECTS
            }

            let chart           = new window.astrology.Chart(id, width - 24, width - 24, optionsLocal);

            // console.log(data);

            // Remove Bad Aspects
            const omitted       = ['northnode','southnode','lilith'];
            if(false && omitted.length > 0){
                const filterFun     = x => !omitted.includes(x.point1Key) && !omitted.includes(x.point2Key);
                data.Aspects.all    = data.Aspects.all.filter(filterFun);
                data.Aspects.points = omit(data.Aspects.points, omitted);
                data.Aspects.types  = Object.entries(data.Aspects.types).reduce((acc,[key,value]) => ({
                    ...acc,
                    [key] : value.filter( filterFun )
                }),{})
            }
            
            const dataRadix     = processHoroscopeData(data,ecliptic);
            const radix         = chart.radix(dataRadix);		
            const customPoints  = {
                "As":[dataRadix.cusps[0]],
                "Ic":[dataRadix.cusps[3]],
                "Ds":[dataRadix.cusps[6]],
                "Mc":[dataRadix.cusps[9]]
            };
            const omitCustomPlanets = ['NNode','SNode','Lilith'];
            const customPlanets = (
                Object
                    .entries(dataRadix.planets)
                    .reduce((acc,[key,val]) => (omitCustomPlanets.includes(key) ? acc : {...acc,[key]:val}),{})
            );
            const customAspects = (
                new  window.astrology
                    .AspectCalculator( {
                        ...customPoints,
                        ...customPlanets
                    } )
                    .radix(
                        customPlanets
                    )
            );
            // radix.addPointsOfInterest(customPoints);
            radix.aspects( 
                customAspects
            );

        }catch(err){
            console.error(err)
        }

        // Cleanup
        return () => {
            removeOldChartInstances(id);
        }

    },[id, loaded, aspectData, width, data, theme.palette.mode, removeOldChartInstances, ecliptic, isNetworkReady])

    if(!data || !aspectData)
        return null;

    return (
        <>
            <Root>
                <RadixContainer id = {id} width = {width} display = {show ? 'block' : 'none'} />
            </Root>
            {debug && <JSONViewer src={data}/> }
            {debug && <JSONViewer src={aspectData}/> }
        </>
    )
}

export default Radix