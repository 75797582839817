/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Delivery Review Modal
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       28th November 2024

*******************************************************************************************/
import React                            from 'react';
import { 
    DraggableDialog,
}                                       from 'components';
import { ProductReviewForm }            from 'components/forms';
import { withTranslation }              from '../hoc';
import config                           from '../../config';

const {reviews : {forcePublicReview = true} = {}} = config;

const noop = () => {};

export const DeliveryReviewModal = withTranslation( ({
    t, 
    working,
    editReview          = false, 
    handleEditStop      = noop,
    handleCreateOrEdit  = noop,
    formData            = null,
}) => {
    return (
        <DraggableDialog
            title                   = { t('components.deliveryReview.reviewProductDelivery') }
            open                    = {editReview}
            showButtons             = {false}
            onCancel                = {handleEditStop}
            onClose                 = {handleEditStop}
            disableBackdropClick    = {true}
        >
            <ProductReviewForm 
                disabled            = {working}
                onCancel            = {handleEditStop}
                onSubmit            = {handleCreateOrEdit}
                formData            = {formData}
                allowPrivacyControl = {!forcePublicReview}
                FormProps           = {{
                    disabledAfterSubmit    : true,
                    changeRequiredToSubmit : true,
                    submitText             : t('components.deliveryReview.submitReview')
                }}
                sticky              = {true}
            />
        </DraggableDialog>
    )
})

export default DeliveryReviewModal;