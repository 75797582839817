
/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
UndoSaveButtons
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd May 2021

*******************************************************************************************/

import React                        from "react"
import {Box}                        from "@mui/material";
import { UndoButton, SaveButton}    from "components/buttons";

export const UndoSaveButtons = ({
    size                = "small",
    variant             = "contained",
    UndoButtonProps     = {},
    SaveButtonProps     = {},
    ...props
}) => {
    return (
        <Box sx={{'& > * + *' : {ml : 1}}}>
            <UndoButton 
                color       = "primary" 
                size        = {size} 
                variant     = {variant} 
                {...props} 
                {...UndoButtonProps} 
                sx          = {{p:0,...props.sx,...UndoButtonProps.sx}}
            />
            <SaveButton 
                color       = "secondary" 
                size        = {size} 
                variant     = {variant} 
                {...props}
                {...SaveButtonProps}
                sx          = {{p:0,...props.sx,...SaveButtonProps.sx}}
            />
        </Box>
    )
};

export default UndoSaveButtons;