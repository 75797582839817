/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Archive Cross Sell
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       8th October 2024

*******************************************************************************************/
import React                        from 'react';
import { ArchiveCrossSell }         from 'components';
import { useExample }               from './ExampleContext';

export const ExampleArchiveCrossSell = () => {
    const { data, crossSellQuantity, crossSellRows} = useExample();
    return (
        <ArchiveCrossSell 
            title       = {"Related by Category"}
            id          = {data?.id} 
            quantity    = {crossSellQuantity} 
            rows        = {crossSellRows}
        />
    )
}

export default ExampleArchiveCrossSell;