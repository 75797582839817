/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Page Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       14th July 2021

*******************************************************************************************/
import React                        from 'react';
import {isNil}                      from 'lodash';
import { useLocation }              from 'react-router-dom';
import { styled, lighten, Box}      from '@mui/material';
import { useInternet }              from 'hooks/services';
import { NotFound as BaseNotFound}  from './errors';

const BreadCrumbs     = React.lazy(() => import('./BreadCrumbs'));
const LoadingData     = React.lazy(() => import('./LoadingData'));
const NetworkOffline  = React.lazy(() => import('./NetworkOffline'));

const Root = styled(Box)({
    position            : 'relative',
    width               : '100%',
    height              : '100%',
    display             : 'flex',   /* very important rule */
    flexDirection       : 'column', /* very important rule */
    border              : undefined,
    background          : 'inherit',
});

const BreadCrumbsContainer = styled(Box)(({theme}) => ({
    position            : 'relative',
    background          : lighten(theme.palette.background.paper, theme.palette.tonalOffset),
    borderBottom        : `0.5px dashed`,
    borderBottomColor   : theme.palette.divider,
}));


const LoadingContainer = styled(Box)(({theme}) => ({
    position            :'absolute', 
    top                 : '50%', 
    right               : 0, 
    transform           : 'translatey(-50%)',
    paddingLeft         : theme.spacing(2),
    paddingRight        : theme.spacing(2)
}));

const ContentContainer = styled(Box)(({theme}) => ({
    marginTop           : 0,
    padding             : theme.spacing(2),
    display             : 'flex',
    flexDirection       : 'column',
    flexGrow            : 1,
    position            : 'relative',
    background          : 'inherit',
    minHeight           : '100%'
}));

const obj = {};

const NotFound = () => {
    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Box flexGrow={1}/>
            <Box>
                <BaseNotFound />
            </Box>
            <Box flexGrow={1}/>
        </Box>
    )
}

const ScrollToTop = () => {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);  // Scrolls to the top of the page when URL changes
    }, [location]);
    return null;
};
  

/*
 A page container with Breadcrumbs along the top bar, and showing a loading spinner on 
 the top right when the loading argument is true. THe child container is a flexbox which 
 occupies 100% of the available vertical space
*/
export const PageContainer = ({
    children, 
    loading : loadingIn = undefined, 
    breadcrumbs         = true,
    allowOffline        = true,
    scrollToTop         = false,
    paperProps          = obj,
    notFound            = false,
    ...props
}) => {

    // Network
    const {isOnline} = useInternet();

    // Loading Function
    const [loading, setLoading] = React.useState(loadingIn); 
    
    // Listen to changes in Loading
    React.useEffect(() => (
        setLoading(loadingIn)
    ),[loadingIn]);

    // Timer
    React.useEffect(()=>{
        if(isNil(loading)){
            setLoading(true);
            let timeout = setTimeout(()=>{
                setLoading(undefined);
            },250)
            return () => {
                clearTimeout(timeout);
            }
        }
    // eslint-disable-next-line
    },[])

    return (
        <Root id="pagecontainer" {...props}>
            {
                breadcrumbs &&
                <BreadCrumbsContainer flexShrink={1} id='breadCrumbsContainer' p={0} >
                    <BreadCrumbs />
                    {
                        loading && 
                        <LoadingContainer id="loadingContainer">
                            <LoadingData color="secondary" size={12}/>
                        </LoadingContainer>
                    }
                </BreadCrumbsContainer>
            }
            <ContentContainer
                id              = "pageContainerContent"
                flexGrow        = {1}
                {...props}
                {...paperProps}
            >
                {
                    notFound && 
                    <NotFound />
                }
                {
                    !notFound && 
                    <>
                        {
                            (isOnline || allowOffline) && 
                            children     
                        }
                        {
                            !isOnline && !allowOffline &&
                            <NetworkOffline />
                        }
                    </>
                }
            </ContentContainer>
            {
                scrollToTop &&
                <ScrollToTop />
            }
        </Root> 
    )
}

export default PageContainer;