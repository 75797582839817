/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Graphics
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    useTheme,
    Box,
    Typography,
}                               from '@mui/material';
import LoginIcon                from '@mui/icons-material/Login';
import { 
    Title,
    Radix,
    NatalSummary,
    Skeleton,
    Sticky,
    AspectsGrid,
    Login,
}                               from 'components';
import { withTranslation }      from 'components/hoc';
import withFeatureTypography    from 'components/hoc/withFeatureTypography';
import SkeletonRadix            from 'components/skeletons/SkeletonRadix';
import {
    useNetwork,
    useUser,
}                               from 'contexts';
import { useExample }           from './ExampleContext';

const SkeletonRadixLoading = withFeatureTypography(SkeletonRadix);

export const ExampleGraphics = withTranslation( ({
    t, 
    visible = true,
    sticky  = false, 
    width   = 100
}) => {
    const {
        data,
        dataHoroscope,
        queried                 : queriedA,
        queriedDataHoroscope    : queriedB,
        workingDataHoroscope    : working,
    }                       = useExample();
    const {isAuthenticated} = useUser();
    const {isNetworkReady}  = useNetwork();
    const theme             = useTheme();
    const Component         = React.useMemo(() => sticky ? Sticky : Box, [sticky]);
    const queried           = queriedA && queriedB;
    
    if(!visible)
        return null;

    return (
        <Component style={ sticky ? {paddingTop : theme.spacing(1)} : undefined}>

            {
                isNetworkReady && queried &&
                <Title variant="h5" component="h3">
                    { t('common.natalData') }
                </Title>
            }

            <Box position = "relative" width="100%">
                {
                    (!queried || working || !data) &&
                    <Box style={{aspectRatio:'5'}}>
                        <Skeleton />
                    </Box>
                }
                {
                    queried && isNetworkReady &&
                    <NatalSummary approxAddress = {false} {...data} />
                }
            </Box>

            <Box position = "relative" width="100%">
                {
                    (!queried || working || !dataHoroscope) &&
                    <SkeletonRadixLoading />
                }
                
                {
                    dataHoroscope && 
                    <Radix 
                        id      = {'radix'}
                        width   = {width}
                        data    = {dataHoroscope}
                    />
                }

                { 
                    isNetworkReady && queried && !dataHoroscope && !working &&
                    <Typography align="center">
                        {t('common.noNatalData')}
                    </Typography>
                }         
            </Box>

            <Box position = "relative" width="100%">
                {
                    isNetworkReady && queried && dataHoroscope &&
                    <Box>
                        {
                            isAuthenticated &&
                            <Box>
                                <Title variant="h5" component="h3" gutterBottom>
                                    {t('common.aspects')}
                                </Title>  
                                <AspectsGrid 
                                    loading         = {!queried || working || !dataHoroscope}
                                    dataHoroscope   = {dataHoroscope} 
                                />
                            </Box>
                        }
                        {
                            false && !isAuthenticated &&
                            <Box sx={{mx:'auto',width:'fit-content'}}>
                                <Login variant="contained" startIcon={<LoginIcon/>} size="small" popup={true}>
                                    {t('common.loginToView')}
                                </Login>
                            </Box>
                        }
                    </Box>
                }
            </Box>

        </Component>
    )
});

export default ExampleGraphics;