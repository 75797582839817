/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Transaction Steps
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       29th October 2021

*******************************************************************************************/

import React                from 'react';
import {
    useMediaQuery, 
    Box,
    Typography,
    Stepper,
    Step,
    StepLabel
}                           from '@mui/material';
import { 
    Title,
}                           from 'components';

const STEP_DATA = [
    {
        title       : 'Place Order',   
        description : "There are several product variants to choose from, with different inclusions and features depending on price"
    },
    {
        title       : 'Provide Info',  
        description : "You are required to supply certain minimum information, such as birth date and location. Once provided, order commences"
    },
    {
        title       : 'Wait',          
        description : "Delivery typically takes up to 48hrs for us to prepare your reading, which is deposited in your user library"
    }
]

export const TransactionSteps = () => {
    const hidden        = useMediaQuery(theme => theme.breakpoints.up('md'));
    const vertical      = useMediaQuery(theme => theme.breakpoints.down('md'));
    const orientation   = React.useMemo(() => vertical ? 'vertical' : 'horizontal', [vertical]);
    return (
        <Box>
            {
                hidden &&
                <Box p={2} pb={2}>
                    <Typography variant="h5">
                        <em>Once registered, it's as easy as 1, 2 and 3 ...</em>
                    </Typography>
                </Box>
            }
            <Box align="center" pb={2}>
                <Stepper alternativeLabel activeStep={STEP_DATA.length} orientation={orientation} style={{background:'none'}}>
                    {
                        STEP_DATA.map(({title,description},ix) => (
                            <Step key={ix} style={{width:'100%'}}>
                                <StepLabel optional={
                                    <Box align="center">
                                        <Typography align="center" style={{maxWidth:300}} gutterBottom>
                                            {description}
                                        </Typography>
                                    </Box>
                                }>
                                    <Title>
                                        {ix+1}. {title}
                                    </Title>
                                </StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Box>
        </Box>
    );
}

export default TransactionSteps;