/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
React Glyphs Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st October 2024

*******************************************************************************************/
import React                from 'react';
import { useTheme }         from '@mui/material';
import glyphs               from 'components/glyphs'

// Lookup Component
export const useGlyphs = () => {
    const theme         = useTheme();
    const oppositeMode  = React.useMemo(() => theme.palette.mode === 'dark' ? 'light' : 'dark', [theme.palette.mode]);
    const glyphDict     = React.useMemo(() => glyphs[oppositeMode], [oppositeMode]);
    return glyphDict
};

export default useGlyphs;