/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Photo Container Sticky
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024
*******************************************************************************************/
import React                    from 'react';
import { Box }                  from '@mui/material';
import { 
    FeatureBox,
    Sticky,
}                               from 'components';
import ExamplePhotoAttribution  from './ExamplePhotoAttribution';
import ExamplePhotoTitle        from './ExamplePhotoTitle';
import { useExample }           from './ExampleContext';

export const ExamplePhotoContainerSticky = ({children, showTitle = false}) => {
    const {queried, data}   = useExample();
    return (
        <Sticky sx={{'& > * + *' : {mt:2},mt:-2}}>
            <Box sx={{pt:2}}>
                <FeatureBox sx={{borderRadius:2,p:0,position:'relative'}}>
                    {
                        showTitle && queried && data?.name &&
                        <ExamplePhotoTitle title={data?.name}/>
                    }
                    <Box>
                        {children}
                    </Box>
                </FeatureBox>
                <ExamplePhotoAttribution compact={true} content={queried && data?.photoAttribution ? data?.photoAttribution : undefined} />
            </Box>
        </Sticky>
    )
};

export default ExamplePhotoContainerSticky;