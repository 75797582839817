/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Viewed Times
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React            from 'react';
import { 
    Box, 
    Typography 
}                       from '@mui/material';
import { useExample }   from './ExampleContext';

export const ExampleViewedTimes = (props) => {
    const { data } = useExample();
    return (
        <Box>
            <Typography variant="body2" {...props} sx={{fontStyle:'italic', fontSize:12, ...props?.sx}}> 
                This example has been viewed {(data?.views || 0) + 1}x times
            </Typography>
        </Box>
    )
};

export default ExampleViewedTimes;