/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
AstroVenusian Application -- Axios
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       22nd December 2020

*******************************************************************************************/
import React                            from 'react';
import {omit}                           from 'lodash';
import axiosBase                        from 'axios';
import { FORM_ERROR }                   from 'final-form';

// Defautl Axios
const axios         = axiosBase.create();

// Expose CancelToken and isCancel Methods
axios.CancelToken   = axiosBase.CancelToken;
axios.isCancel      = axiosBase.isCancel;

const scrubErrors = (errors, message) => {
    if(typeof errors !== 'object')
        throw new Error('errors must be an object');

    // Add the message to the FORM_ERROR item
    errors[FORM_ERROR] = errors[FORM_ERROR] || errors['FORM_ERROR'] || message;

    // Remove vanilla 'FORM_ERROR', if necessary and return
    return FORM_ERROR !== 'FORM_ERROR' 
        ? omit(errors, 'FORM_ERROR') 
        : errors;
}

const handleResponse = (response) => new Promise((resolve,reject) => {

    if(!response || typeof response !== 'object'){
        let success = false;
        let status  = 500;
        let message = "Invalid response object";
        let errors  = scrubErrors({},message);
        return reject({raw : response, success, status, statusCode : status, message, errors})
    }

    // Data from response;
    let {data} = response;

    // Default Empty Errors
    let errors = {};
    
    // Default Success
    let success = true;

    // Default Status Code
    let status = parseInt(response?.status || response?.statusCode);

    // Default Message
    let message = data?.message || response?.statusText;

    // 4XX series (or missing status) are ERROR results
    if(isNaN(status) || status >= 400){
        success = false;
        status  = status || 500; // if missing
        message = message || `Error, Status ${status}`
        errors  = scrubErrors(data?.errors || {}, message);

        reject( {raw : response, success, status, statusCode : status, message, errors}); // Errors
    
    // 2XX series are OK results
    }else {
        message = message || `OK, Status ${status}`
        resolve({raw : response, ...data, success, status, statusCode : status, message, errors}); // No Errors
    }
})

// Interceptor to handle the response
axios.interceptors.response.use(handleResponse,(error) => {
    // console.log("Handle Respone Interceptor");
    if(error.response){
        return handleResponse(error.response);
    }else{
        return Promise.reject(error);
    }
});


const useAxios = () => {
    
    const axiosRef              = React.useRef(axios);
    const [token, setAuthToken] = React.useState(undefined);

    /**
     * Function to set the auth token
     * @param {token} the bearer token
     */
    const setToken = React.useCallback( (token) => {
        axiosRef.current.defaults.headers.common['Authorization'] = `Bearer ${token || "null"}`;
        setAuthToken(token);
    }, [])

    // Revoke the Token
    const revokeToken =  React.useCallback( () => (
        setToken(undefined)
    ), [setToken]);

    // Set Empty if not set yet
    React.useEffect(() => {
        if(!axiosRef.current.defaults.headers.common['Authorization'])
            setToken(undefined);
    }, [setToken])

    return {
        axios: axiosRef.current,
        token,
        setToken,
        revokeToken
    };
}

// Named exports
export {
    axios,
    useAxios
}

// Default export
export default useAxios;