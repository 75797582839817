/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Waiting For Internet Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th July 2021

*******************************************************************************************/
import React                            from 'react';
import {useTheme}                       from '@mui/material';
import { 
    MainAlert, 
    LoadingData
}                                       from 'components';
import { withTranslation }              from './hoc';
import { useNetwork }                   from 'contexts';
import { HomeLocation }                 from 'router/locations';
import { useLocation }                  from 'react-router-dom';

export const AlertWaitingForNetwork = withTranslation( ({t}) => {
    const {isOnline, isNetworkReady}    = useNetwork();
    const location                      = useLocation();
    const theme                         = useTheme()
    
    if(!isOnline || isNetworkReady || location.pathname === HomeLocation.path)
        return null;

    return (
        <MainAlert 
            severity    = "warning" 
            icon        = { 
                <LoadingData color={theme.palette.warning.dark} size={15}/> 
            } 
        >
            { t('components.alertWaitingForNetwork.message') }
        </MainAlert>
    )
});

export default AlertWaitingForNetwork;