/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Contentful
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import moment                   from 'moment';
import { 
    ContentfulRichText,
}                               from 'components';
import {
    useContentful,
}                               from 'contexts';
import { useExample }           from './ExampleContext';

export const ExampleContentful = () => {
    const {data : dataExample}      = useExample();
    const {getArchive}              = useContentful();
    const [queried, setQueried]     = React.useState(undefined);
    const [loading, setLoading]     = React.useState(false);
    const [data,    setData]        = React.useState(undefined);

    React.useEffect(() => {
        if(!dataExample?.slug){
            setData(undefined);
            return;
        }
        setLoading(true);
        setData(undefined);
        getArchive(dataExample?.slug)
            .then(items => items[0])
            .then(setData)
            .finally(() => {
                setLoading(false)
                setQueried(moment())
            })
    },[dataExample?.slug, getArchive])

    if(!dataExample?.slug || loading || !queried)
        return null;
    return (
        <ContentfulRichText content={data?.fields?.description} />
    )
}

export default ExampleContentful;