/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useUserPublicName Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       30th September 2024

*******************************************************************************************/
import React                from 'react';
import { useUser }          from 'contexts';

export const useUserPublicName = (userData) => {
    
    const { 
        userId,
        isAuthenticated
    }                               = useUser();

    // Extract user properties with memoization
    const {

        _id         = undefined,
        namePublic  = undefined,
        firstName   = undefined,
        lastInitial = undefined,
        displayName = undefined,
        handle      = undefined

    } = React.useMemo(() => ({

        // Default user Details
        _id         : userData?._id,
        namePublic  : userData?.namePublic,
        firstName   : userData?.firstName,
        lastInitial : userData?.lastInitial,

        // From UserProfile
        displayName : userData?.userProfile?.displayName,
        handle      : userData?.userProfile?.handle,
    }), 
    [
        userData?._id,
        userData?.namePublic,
        userData?.firstName,
        userData?.lastInitial,
        userData?.userProfile?.displayName,
        userData?.userProfile?.handle,
    ]);

    // Compute visible name with memoization
    const name = React.useMemo(() => (
        [
            displayName,
            handle ? `@${handle}` : undefined,
            namePublic,
            firstName && lastInitial ? `${firstName} ${lastInitial}` : undefined,
            "Someone"
        ].filter(Boolean)[0]
    ), [firstName, lastInitial, namePublic, displayName, handle]);

    // Determine whether edit is permitted
    const isCurrentUser = Boolean(isAuthenticated && userId && userId === _id);

    // Done
    return {
        name,
        isCurrentUser
    }
};

export default useUserPublicName;