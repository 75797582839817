/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Order User Input Alert
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       5th August 2021

*******************************************************************************************/
import React                            from 'react';
import {
    FormAlert,
    Button
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import { useOrderViewer }               from './context/OrderViewerContext';
import { 
    useQueryParam, 
    StringParam
}                                       from 'hooks/useQueryParams';

const USER_INPUT_STATUS = 'USER_INPUT';
const USER_INPUT_PARAM  = 'userInput';
const DELIVERIES_ID     = 'deliveries';

export const OrderUserInputAlert = withTranslation( ({t, ...props}) => {

    // Query Parameter
    const [userInput, setUserInput]                     = useQueryParam(USER_INPUT_PARAM, StringParam);

    // Data from Order Viewer
    const { data : order, orderId, queried, isOwner }   = useOrderViewer();

    // Extract the delivery data from the orders context
    const deliveries = React.useMemo(() => (
        (order ? order.deliveries : []).filter(d => d?.status === USER_INPUT_STATUS)
    ), [order]);

    // Scroll to User Input
    React.useEffect(() => {
        if(orderId && queried && userInput){
            const timeout = setTimeout(() => {
                const el = document.getElementById(userInput);
                if(el) el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
                setUserInput(undefined);
            },[500])
            return () => {
                clearTimeout(timeout);
            }
        }
    },[orderId, queried, setUserInput, userInput]);

    // Handle Button Click
    const handleClick = React.useCallback( () => {
        const el = document.getElementById(DELIVERIES_ID);
        if(el) setUserInput(deliveries[0]?.id);
    }, [deliveries, setUserInput]);

    // Navigate Button
    const NavigateButton = React.useCallback(() => (
        <Button onClick={handleClick} variant="contained" color="primary" size="small" sx={{transform:'translatey(-1px)'}}>
            { t('components.order.orderUserInputAlert.provideInformation') }
        </Button>
    ), [t, handleClick])
        
    // No OrderID
    if(!orderId)
        return null;

    // No Order
    if(!order) 
        return null;

    // No Deliveries
    if(!deliveries.length) 
        return null;

    // Order Should Not Process
    if(!order.shouldProcess)
        return null;

    // Only Show for Owner
    if(!isOwner)
        return null;

    // Has Order, Has Deliveries
    return (
        <FormAlert severity = "info" {...props} action = {<NavigateButton/>}>
            { t('components.order.orderUserInputAlert.message', {quantity : deliveries.length}) }
        </FormAlert>
    )
})

export default OrderUserInputAlert;