/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Root Container
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       3rd December 2022

*******************************************************************************************/
import React                from 'react';
import PropTypes            from 'prop-types';
import { 
    styled, 
    Box as BoxMUI 
}                           from '@mui/material';

const Box = styled(BoxMUI, { 
    shouldForwardProp : prop => prop !== 'flexDirection' && prop !== 'spacing'
})(({theme, flexDirection = 'column', spacing = 2}) => ({
    width : '100%',
    flexDirection,
    ...(spacing && {
        '& > * + *' : {
            [flexDirection === 'column' ? 'marginTop' : 'marginLeft'] : theme.spacing(spacing),
        },
    })
}));

Box.propTypes = {
    flexDirection   : PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    spacing         : PropTypes.number,
};

export const RootContainer = React.forwardRef( 
    (props,ref) => <Box ref={ref} {...props} />
)

export default RootContainer