/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Public LIbrary
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       1st June 2022

*******************************************************************************************/

import React                            from 'react';
import {
    useMediaQuery, 
    Box,
    Typography 
}                                       from '@mui/material';
import CollectionsBookmarkIcon          from '@mui/icons-material/CollectionsBookmark';
import { useHistory }                   from 'react-router-dom';
import { 
    Title, 
    Button, 
    ContentfulRichText
}                                       from 'components';
import Section                          from '../components/Section';
import SectionContainer                 from '../components/SectionContainer';
import constants                        from '../constants';
import { ArchiveCrossSell }             from 'components';
import { ArchiveCollectionLocation }    from 'router/locations';

export const WidgetArchives = ({data=undefined}) => {
    const history                   = useHistory();
    const mdUp                      = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const jumpToArchive             = React.useCallback( () => history.push(ArchiveCollectionLocation.path), [history]);

    if(!data)
        return null;
    
    return (
      <SectionContainer id={constants.ids.examplesArchives}>
        <Section 
          direction = {data?.fields?.flexDirection}
          feature   = {
            <Box>
                <ArchiveCrossSell 
                    quantity    = {mdUp ? 8 : 6} 
                    rows        = {2} 
                    showTitle   = {false} 
                    auto        = {false}
                />
            </Box>
          }
        >
            <Title component="h2" variant="h2" gutterBottom>
                {data?.fields?.title}
            </Title>
            <Typography paragraph variant="h5">
                {data?.fields?.subtitle}
            </Typography>
            <ContentfulRichText content={data?.fields?.content} />
            <Box mt={2}>
                <Button startIcon={<CollectionsBookmarkIcon/>} variant="contained" color="primary" onClick={jumpToArchive}>
                    Visit Archives
                </Button>
            </Box>
        </Section>
      </SectionContainer>
    )
}

export default WidgetArchives;