/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Playlist Component
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       16th October 2021

*******************************************************************************************/
import React                            from 'react';
import {isNil}                          from 'lodash';
import moment                           from 'moment';
import momentDurationFormatSetup        from 'moment-duration-format';
import {
    styled,
    useTheme,
    useMediaQuery,
    Box,
    Button,
    Chip,
    Typography,
    IconButton,
    Tooltip,
}                                       from '@mui/material';
import PauseIcon                        from '@mui/icons-material/PauseCircleFilled';
import EditIcon                         from '@mui/icons-material/Edit';
import SearchIcon                       from '@mui/icons-material/Search';
import CopyIcon                         from '@mui/icons-material/FileCopy';
import AddShoppingCartIcon              from '@mui/icons-material/AddShoppingCart';
import InfoIcon                         from '@mui/icons-material/Info';
import ShareIcon                        from '@mui/icons-material/Share';
import {useHistory}                     from 'react-router-dom';
import {
    Title,
    DraggableDialog,
    DigitalFont,
    CountDown,
    PublicPrivateChip as PublicPrivateChipOrig,
    ViewProductButton,
    SocialSharePage,
    Playlist,
    RootContainer,
    Like,
    TermsAndConditions
}                                       from 'components';
import { withTranslation }              from 'components/hoc';
import {
    SkeletonFancyNoLibrariesSelected
}                                       from 'components/skeletons';
import SkeletonPlaylist                 from 'components/skeletons/SkeletonPlaylist';
import {
    IconModal
}                                       from 'components/modals';
import { 
    useLibrary,
    useLibraryViewer,
    useLocale,
    useUser,
    useCart,
    useNetwork
}                                       from 'contexts';
import { useCopyToClipboard }           from 'hooks';
import { 
    UserLibraryLocation
}                                       from 'router/locations/Locations';
import PublicLibraryForm                from 'components/forms/PublicLibraryForm';
import { FORM_ERROR }                   from 'final-form';

// Set up moment duration format
momentDurationFormatSetup(moment);

const DURATION_FORMATTER_DEFINITION = undefined; //"hh:mm:ss"

const noop = () => {}

const PublicContainer = styled(Box)(({theme}) => ({
    paddingLeft : theme.spacing(1),
    display     : 'inline-block'
}))

const TimerContainer = styled(Box)(({theme}) => ({
    fontSize        : '2em',
    overflow        : 'hidden',
    top             : 0,
    right           : 0,
    lineHeight      : 'normal',
    [theme.breakpoints.down('xl')] : {
        fontSize : '1.75em'
    },
    [theme.breakpoints.down('xl')] : {
        fontSize : '1.5em'
    },
    [theme.breakpoints.down('lg')] : {
        fontSize : '1.25rem'
    },
    [theme.breakpoints.down('md')] : {
        fontSize : '1rem'
    }
}))

const LibraryCountDown = withTranslation(({t,...props}) => {
    const { data, loading, isAvailable} = useLibraryViewer();
    if(isAvailable || !data?.releaseAfter || loading) 
        return null;
    return (
        <TimerContainer>
            <DigitalFont>
                <CountDown 
                    prefix          = "Due" 
                    {...props} 
                    to              = {moment(data?.releaseAfter)} 
                    finishedValue   = {t('common.availableSoon')} 
                />
            </DigitalFont>
        </TimerContainer>
    )
})

const PublicShareRoot = styled(Box)(({theme}) => ({
    width       : 400, 
    paddingTop  : 0,
    padding     : theme.spacing(2),
    '& > * + *' : {
        marginTop : theme.spacing(1)
    }
}))

const PublicShare = withTranslation(({t}) => {
    
    const { data }              = useLibraryViewer();
    const isPublic              = Boolean(data?.public);
    const [url, setUrl]         = React.useState(undefined);
    if(!isPublic)
        return null;
    return (
        <IconModal 
            title   = {t('components.library.libraryPlaylist.publicShare.title')}
            Icon    = {ShareIcon} 
            tooltip = {t('components.library.libraryPlaylist.publicShare.share')}
        >
            <PublicShareRoot>
                <Box>
                    <Typography>
                        {t('components.library.libraryPlaylist.publicShare.summary')}
                    </Typography>
                </Box>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <SocialSharePage 
                            showUrl         = {true}
                            url             = {url} 
                            textFieldProps  = {{
                                InputProps : {
                                    endAdornment : (
                                        <PublicLibraryAddressIcons onChange={setUrl}/>
                                    )
                                }
                            }}
                            renderCopyButtonsxx ={() => {
                                return (
                                    <PublicLibraryAddressIcons onChange={setUrl}/>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </PublicShareRoot>
        </IconModal>
    )
})

const PublicInformationModal = withTranslation(({t}) => {
    const { 
        data, 
        isOwner, 
        loading,
        isAvailable
    }                           = useLibraryViewer();
    const {ready, isAdmin}      = useUser();
    const isPublic              = Boolean(data?.public);
    if(!isAvailable)
        return null;
    if(!ready)
        return null;
    if(!isPublic || loading)
        return null;
    return (
        <PublicContainer>
            <IconModal Icon={InfoIcon} tooltip={t('components.library.libraryPlaylist.publicInformationModal.infoOnPublicListings')}>
                {
                    !isOwner && 
                    <Typography>
                        {t('components.library.libraryPlaylist.publicInformationModal.notOwner')}
                    </Typography>
                }
                {
                    isOwner &&
                    <>
                        <Typography paragraph>
                            {t('components.library.libraryPlaylist.publicInformationModal.publicOwner')}
                        </Typography>
                        {
                            !isAdmin &&
                            <>
                                <Typography paragraph>
                                    {t('components.library.libraryPlaylist.publicInformationModal.publicOwnerNotAdmin')}
                                </Typography>
                                <Typography>
                                    {t('components.library.libraryPlaylist.publicInformationModal.publicOwnerNotAdminReview')}
                                </Typography>
                            </>
                        }
                        {
                            isAdmin &&     
                            <Typography>
                                {t('components.library.libraryPlaylist.publicInformationModal.publicOwnerAdmin')}
                            </Typography>
                        }
                    </>
                }
            </IconModal>
        </PublicContainer>
    )
})

const PublicPrivateChip = withTranslation(({t}) => {
    const { 
        loading,
        isOwner,
        libraryId,
        isAvailable,
        isPublic
    }                           = useLibraryViewer();
    const { ready, isAdmin }    = useUser();
    const { loadingPublic }     = useLibrary();
    if(!isAvailable)
        return null;
    if((!isOwner && !isAdmin) || !ready)
        return null;
    if(!libraryId || loading)
        return null;
    return (
        <PublicContainer>
            <PublicPrivateChipOrig 
                isPublic    = { isPublic }
                label       = { loadingPublic ? t('common.loading')  : undefined } 
                color       = { loadingPublic ? "default"            : undefined }
                size        = "small"
            />
        </PublicContainer>
    )
})

const PublicPrivateEditButton = withTranslation( ({ t }) => {
    const {
        isOwner,
        loading, 
        libraryId, 
        isAvailable,
        isPublic,
        commentsEnabled
    }                           = useLibraryViewer();
    const { 
        loadingPublic, 
        postUpdatePrivacy 
    }                           = useLibrary();
    const { ready }             = useUser();
    const {isNetworkReady}      = useNetwork();
    const [open, setOpen]       = React.useState(false);
    const handleOpen            = () => setOpen(libraryId);
    const handleClose           = () => setOpen(undefined);
    const handleSubmit          = ({id,value,commentsEnabled}) => (
        postUpdatePrivacy({id,value,commentsEnabled})
            .then(handleClose)
            .catch(({message}) => ({[FORM_ERROR]:message}))
    )
    if(!isAvailable)
        return null;
    if(!isOwner || !ready)
        return null;
    if(!libraryId || loading)
        return null;
    return (
        <PublicContainer>
            <IconButton disabled={loadingPublic || !isNetworkReady} size="small" onClick={handleOpen}>
                <Tooltip title={t('components.library.libraryPlaylist.publicPrivateEditButton.togglePublicPrivate')}>
                    <EditIcon/>
                </Tooltip>
            </IconButton>
            <DraggableDialog 
                title           = {t('components.library.libraryPlaylist.publicPrivateEditButton.privacySettings')}
                showButtons     = {false} 
                open            = {Boolean(open)} 
                onClose         = {handleClose} 
                onOk            = {handleClose}
                maxWidth        = "xs"
                fullWidth
            >
                <PublicLibraryForm 
                    disabled    = {!isNetworkReady} 
                    formData    = {{
                        id              :   open,
                        value           :   isPublic,
                        commentsEnabled :   commentsEnabled 
                    }} 
                    onCancel        = {handleClose}
                    onSubmit        = {handleSubmit}
                />
            </DraggableDialog>
        </PublicContainer>
    )
})

// TODO
const PublicLibraryAddressIcons = withTranslation( ({t, onChange = noop}) => {
    const history               = useHistory();
    const copy                  = useCopyToClipboard();
    const { data }              = useLibraryViewer();
    const {
        loading, 
        libraryId, 
    }                           = useLibraryViewer();
    const [to, setTo]           = React.useState(undefined);
    const [url, setUrl]         = React.useState(undefined);
    const isPublic              = Boolean(data?.public);

    React.useEffect(() => {
        setUrl(`${window.location.origin}${to}`)
    },[to,onChange])

    React.useEffect(() => {
        onChange(url)
    },[url,onChange])

    // Update the 'to' address
    React.useEffect(() => setTo(libraryId ? UserLibraryLocation.toUrl({id:libraryId}) : '/'), [libraryId]);

    // Copy Handler
    const handleCopy = React.useCallback( () => (
        copy(url, t('components.library.libraryPlaylist.publicLibraryAddressIcons.publicAddressCopied') )
    ), [copy, t, url]);

    // Jump Handler
    const handleJump = React.useCallback( () => {
        history.push(to)
    }, [history, to])

    // Abort
    if(loading || !isPublic)
        return null;

    return (
        <>
            {
                window.location.pathname !== to &&
                <PublicContainer>
                    <IconButton onClick={handleJump} size="small">
                        <Tooltip title={t('common.view')}>
                            <SearchIcon/>
                        </Tooltip>
                    </IconButton>
                </PublicContainer>
            }

            <PublicContainer>
                <IconButton onClick={handleCopy} size="small">
                    <Tooltip title={t('common.copy')}>
                        <CopyIcon/>
                    </Tooltip>
                </IconButton>
            </PublicContainer>
        </>
    )
})


const LibraryPlayingSummary = withTranslation( ({t, playing = false, variant="body2", currentIndex = 0, files=[], includeDetails = true, compact=false}) => {

    const { isAvailable, loading }  = useLibraryViewer();
    const totalQuantity             = Array.isArray(files) ? files.length : 0;
    const hasFiles                  = totalQuantity > 0;
    const totalDuration             = hasFiles ? files.reduce((acc,cur) => acc + (cur.duration || 0),0) : 0;
    if(loading)         return null;
    if(!hasFiles)       return null;
    if(!isAvailable)    return null;
    return (
        <Box display="flex">
            <Box flexGrow = {1}>
                <Typography variant={variant} gutterBottom>
                    {
                        !compact 
                            ? t('components.library.libraryPlaylist.libraryPlayingSummary.durationWithTopics', 
                                { quantity : totalQuantity, duration : moment.duration(totalDuration,'seconds').format(DURATION_FORMATTER_DEFINITION) }
                            )
                            : t('components.library.libraryPlaylist.libraryPlayingSummary.duration', 
                                { duration : moment.duration(totalDuration,'seconds').format(DURATION_FORMATTER_DEFINITION)}
                            )
                    }
                </Typography>
            </Box>
            {
                includeDetails && !isNil(currentIndex) && playing && hasFiles &&
                <Box>
                    <Typography variant={variant} gutterBottom>
                        {t('components.library.libraryPlaylist.libraryPlayingSummary.fileXofN', {index:currentIndex + 1, quantity:totalQuantity})}
                    </Typography>
                </Box>
            }
        </Box>
    )
})

/*
const HumanizeDates = ({start, finish, ...props}) => {
    const humanize = React.useMemo(() => {
        return !moment.isMoment(start) || !moment.isMoment(finish)
            ? undefined
            : moment.duration(finish.diff(start)).humanize()
    }, [finish,start]);
    if(!moment.isMoment(start) || !moment.isMoment(finish))
        return null;
    return (
        <React.Fragment>
            {finish > start  && <>which is in <strong>about {humanize}.</strong></>}
            {finish <= start && <><strong>about {humanize}</strong> ago.</>}
        </React.Fragment>
    )
}
*/
// sheduled release is <Chip size="small" label={formatDateTime(moment(data?.releaseAfter))}/> <HumanizeDates start={now} finish={moment(data?.releaseAfter)}/>

const LibraryAvailability = withTranslation(({t}) => {

    const { data, isAvailable, loading }    = useLibraryViewer();
    const { formatDateTime }                = useLocale();
    const show                              = React.useMemo(() => Boolean(!loading && data && !isAvailable), [data, isAvailable, loading])

    if(!show)    
        return null;

    return (
        <Typography gutterBottom component="div" variant='body2'>
            {
                data?.releaseAfter
                    ? (
                        <Box display="flex">
                            <Box flexGrow = {1}>
                                { t('components.library.libraryPlaylist.libraryAvailability.stillPreparingDueBy') }
                            </Box>
                            <Box>
                                <Chip size="small" label={formatDateTime(moment(data?.releaseAfter))}/>
                            </Box>
                        </Box>
                    )
                    : t('components.library.libraryPlaylist.libraryAvailability.stillPreparing')
            }
        </Typography>
    )
})

const LibraryTitle = withTranslation( ({t, hasChapters = false, onPlayToggle : handlePlayToggle=noop, playing, ...props}) => {
    
    const {addProductToCart}            = useCart();
    const { 
        data, 
        loading,
        isAvailable
    }                                   = useLibraryViewer();
    const { 
        product : productId = undefined 
    } = data?.delivery || {};

    const handleAddToCartAgain          = React.useCallback( () => { 
        if(productId) 
            addProductToCart({productId, quantity:1}); 
    }, [addProductToCart, productId])
    const lgUp = useMediaQuery(theme => theme.breakpoints.down('lg'));
    
    return (
        <Title variant="h5">
            {   t('common.playlist') }
            {   
                !loading &&
                <ViewProductButton productId={productId} sx={{ml:1}}/>
            }
            {
                playing && !hasChapters &&
                <Box style = {{display:'inline'}} ml={1}>
                    <PauseIcon 
                        onClick = {handlePlayToggle} 
                        color   = "primary" 
                        style   = {{transform:'translatey(3px)',fontSize:20, cursor: 'pointer' }}
                    />    
                </Box>
            }
            {
                false && !loading && productId && isAvailable && lgUp &&
                <Button startIcon={<AddShoppingCartIcon/>} size="small" variant="text" color="secondary" onClick = {handleAddToCartAgain} style={{marginLeft:5}}>
                    { t('components.library.libraryPlaylist.libraryTitle.orderAnother') }
                </Button>
            }
        </Title>
    );
})

// Library Playlist
// ItemPaper
export const LibraryPlaylist = ({component : Component = Box, componentProps={}, sensitive=false, force=false,...props}) => {
    const theme                                     = useTheme();
    const {isNetworkReady}                          = useNetwork();
    const {
        data, 
        loading,
        libraryId, 
        isAvailable,
        isPublic
    }                                               = useLibraryViewer();
    // Player Instance
    const [instance, setInstance]                   = React.useState(undefined);

    // The actual playlist
    const [playing,         setPlaying]             = React.useState(false);
    const [playListIndex,   setPlayListIndex]       = React.useState(undefined);

    // Check has files
    const hasFiles                                  = React.useMemo(() => Boolean(data) && Array.isArray(data?.files) && data?.files?.length > 0, [data]);
    const hasChapters                               = React.useMemo(() => hasFiles && (data?.files || []).map(f => f?.metadata?.chapterTitle).map(Boolean).every(Boolean), [data?.files, hasFiles]);
    const files                                     = React.useMemo(() => hasFiles ? data?.files : [], [data?.files, hasFiles]);

    const handlePlayToggle                          = React.useCallback(() => {
        try{
            if(!instance) throw new Error('No Instance');
            playing 
                ? instance.pause() 
                : instance.play();
        }catch(err){
            // silent
        }
    },[instance, playing]);
    
    return (
        <RootContainer 
            id = 'libraryPlaylist' 
            sx = {{
                height          : '100%', 
                overflow        : 'hidden',
                [theme.breakpoints.down('lg')] : {
                    minHeight : '400px',
                }
            }}
        >
            {   
                (!libraryId || loading || !isNetworkReady || !hasFiles) && 
                <Box id="librarySetLoading" display="flex" flexDirection="column" position="relative" sx={{height:'100%'}}>
                    <Box>               
                        <SkeletonPlaylist quantity={0}/>
                    </Box>
                    <Box id="nolibs" flexGrow={1} mt={1}>
                        <SkeletonFancyNoLibrariesSelected showCircle={false}/>
                    </Box>
                </Box>
            }

            {
                libraryId && !loading && isNetworkReady && hasFiles &&
                <Component {...componentProps}>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <LibraryTitle hasChapters={hasChapters} onPlayToggle={handlePlayToggle} playing={playing}/>
                        </Box>
                        <Box flexShrink={1} align="right" style={{whiteSpace:'nowrap'}}>
                            <LibraryCountDown/>
                        </Box>
                        {
                            isPublic && 
                            <Box>
                                <Like reference={data?.id} size="small"/>
                            </Box>
                        }
                        <Box flexShrink={1}>
                            <PublicPrivateChip/>
                            <PublicInformationModal/>
                            <PublicShare/>
                            <PublicPrivateEditButton />
                        </Box>
                    </Box>
                    
                    <Box>
                        <TermsAndConditions title={null} showCheckbox={false} action="listening to this playlist"/>
                    </Box>
                    <Box>
                        <LibraryPlayingSummary playing={playing} currentIndex={playListIndex} files={files} includeDetails={!hasChapters}/>
                    </Box>
                    <Box>
                        <LibraryAvailability />
                    </Box>

                    <Box id="chapters" style={{position:'relative'}}> 
                        <Playlist 
                            playlistId              = { libraryId }
                            data                    = { files }
                            sensitive               = { sensitive }
                            force                   = { force }
                            isAvailable             = { isAvailable } 
                            loading                 = { loading }
                            autoscroll              = { true }
                            onAudioInstanceChange   = { setInstance }
                            onPlayChange            = { setPlaying }
                            onPlayIndexChange       = { setPlayListIndex }
                        />
                    </Box>
                </Component>
            }
        </RootContainer>
    )
}

export default LibraryPlaylist
