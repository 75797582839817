/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Library Player
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th October 2021

*******************************************************************************************/

import React                            from 'react';
import ReactJkMusicPlayer               from 'react-jinke-music-player'
import { 
    Box, useTheme, FormControl, 
    Select, MenuItem 
}                                       from '@mui/material';
import { RootContainer }                from 'components';
import { withTranslation }              from 'components/hoc';
import { useModalSpacing }              from 'contexts';
import config                           from '../../config';

// Styling
import 'react-jinke-music-player/assets/index.css'

const {company : COMPANY } = config;

const enforceArray      = x => Array.isArray(x) ? x : [x]
const noop              = () => {};
const DEFAULT_ZINDEX    = 1000000;

class BaseErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }
  
    render() {
        const { t } = this.props;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <h1>
                    { t('components.libraryJinkePlayer.errorBoundary.somethingWentWrong') }
                </h1>
            );
        }
        return this.props.children; 
    }
}

const ErrorBoundary = withTranslation(BaseErrorBoundary);

// Library Jinke Player Custom Component
export const LibraryJinkePlayer = ({
    hidden                                                  = false,
    playList                                                = undefined,
    playListIndex                                           = 0, 
    onAudioInstanceChange   : handleAudioInstanceChange     = noop,
    onAudioPlay             : handleAudioPlay               = noop,
    onAudioPause            : handleAudioPause              = noop,
    onAudioEnded            : handleAudioEnded              = noop,
    onAudioAbort            : handleAudioAbort              = noop,
    onPlayIndexChange       : handlePlayIndexChange         = noop,
    onAudioSeeked           : handleAudioSeeked             = noop,
    onAudioProgress         : handleAudioProgress           = noop,
    onAudioListChange       : handleAudioListChange         = noop,
    zIndex                                                  = DEFAULT_ZINDEX,
    sortable                                                = false,
    fadems                                                  = 0,
    ...props
}) => {
    // const {i18n}                                = useTranslation();
    const theme                                 = useTheme();
    const [audioInstance,setAudioInstance]      = React.useState(undefined);
    const [playbackSpeed,setPlaybackSpeed]      = React.useState(1);
    const {
        top,
        bottom,
        setBottom,
        reset,
        defaults : {
            bottom : defaultBottom
        }
    }                                           = useModalSpacing();
    const [audioList,   setAudioList]           = React.useState([]);

    React.useEffect(() => {
        const handler = function(e) {
            const elem = e.target.nodeName;
            if( elem !== 'TEXTAREA' && elem !== 'INPUT' ) { // Not Input
                if(e.keyCode === 32){ // SpaceBar
                    e.preventDefault();
                }
            }
        }
        window.addEventListener('keydown', handler );
        return () => {
            window.removeEventListener('keydown', handler);
        }
    },[])

    React.useEffect(() => {
        handleAudioInstanceChange(audioInstance);
    },[audioInstance, handleAudioInstanceChange])
    
    React.useEffect(() => {
        if(audioInstance){
            audioInstance.pause();
            return () => {
                audioInstance.pause();
            }
        }
    }, [audioInstance])

    // Helper function 
    const makeAudioList = React.useCallback( (playList) => {
        let singer = COMPANY || window.location.origin;
        return playList.map((data,ix) => {
            let name        = `File Number ${ix + 1}`,
                musicSrc    = data;
            let suffix      = name;
            if(typeof data === 'object'){
                let {file, description} = data;
                musicSrc    = file;
                suffix      = description || name;
            }
            return {
                musicSrc    : musicSrc, 
                singer      : singer, 
                name        : `${ix+1}. ${suffix}`
            }
        })
    }, []);

    // Override Jinke Styles
    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            .react-jinke-music-player {
                right   : ${theme.spacing(4)};
                bottom  : ${theme.spacing(4)};
                left    : unset!important;
                top     : unset!important;
                z-index : ${zIndex}!important;
            }
            .audio-lists-panel.show {
                z-index : ${zIndex}!important;
            }
            .music-player-panel {
                display : ${hidden ? 'none' : 'initial'};
                z-index : ${zIndex}!important;
            }
            .audio-lists-panel-content .audio-item .player-singer {
                width   : 90px!important;
            }
        `
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        }
    },[theme, hidden, zIndex, sortable])

    // Set the AudioList once Layout is Complete
    React.useEffect(() => {
        if(!audioInstance)
            return
            
        // Set to Empty
        setAudioList([]);

        // SET AUDIO LIST ON SLIGHT DELAY TO PREVENT AUTOPLAY
        const timeout = setTimeout(() => {
            const newAudioList = makeAudioList( 
                enforceArray(playList) 
            );
            setAudioList( newAudioList );
        },1000)

        // Cleanup
        return () => {
            clearTimeout(timeout);
            setAudioList([]);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioInstance, makeAudioList, JSON.stringify(playList)])
    
    // Make Spacing
    React.useEffect(() => {
        if(!hidden){
            setBottom(defaultBottom + 80);
            return () => {
                reset();
            };
        }

    },[defaultBottom, hidden, reset, setBottom]);

    const handleSpeedChange = React.useCallback( (event) => {
        setPlaybackSpeed(
            parseFloat(event.target.value)
        );
    }, []);

    React.useEffect(() => {
        if(audioInstance && playbackSpeed)
            audioInstance.playbackRate = playbackSpeed;
    },[playbackSpeed, audioInstance])

    return (
        <RootContainer>
            <ErrorBoundary>
                <ReactJkMusicPlayer 
                    getAudioInstance        = {setAudioInstance}
                    extendsContent          = {[
                        <FormControl key={'speed'}>
                            <Select
                                value       = {playbackSpeed}
                                onChange    = {handleSpeedChange}
                                color       = {'secondary'}
                                MenuProps={{
                                    style: {
                                        zIndex: zIndex + 1
                                    }
                                }}
                            >
                                {
                                    [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((value) => (
                                        <MenuItem key={value} value={value}>
                                          {value}x
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    ]}
                    quietUpdate                 = {false}
                    audioLists                  = {audioList}
                    playIndex                   = {playListIndex}
                    defaultPlayIndex            = {0}
                    playMode                    = {'order'} // order,orderLoop,singleLoop,shufflePlay
                    glassBg                     = {true}
                    responsive                  = {false}
                    remember                    = {false}
                    preload                     = {true}
                    theme                       = {theme.palette.mode}
                    mode                        = {"full"}
                    once                        = {false}
                    autoPlay                    = {false}
                    // autoPlayInitLoadPlayList    = {false}
                    remove                      = {false}
                    toggleMode                  = {false}
                    showDownload                = {false}
                    showReload                  = {false}
                    showThemeSwitch             = {false}
                    showMediaSession            = {true}
                    showLyric                   = {false}
                    autoHiddenCover             = {true}
                    restartCurrentOnPrev        = {true}
                    loadAudioErrorPlayNext      = {false}
                    clearPriorAudioLists        = {true}
                    showMiniProcessBar          = {true}
                    showMiniModeCover           = {true}
                    drag                        = {false}
                    spaceBar                    = {true}
                    onAudioPlay                 = { handleAudioPlay }
                    onAudioPause                = { handleAudioPause }
                    onAudioEnded                = { handleAudioEnded }
                    onAudioAbort                = { handleAudioAbort }
                    onPlayIndexChange           = { handlePlayIndexChange }
                    onAudioSeeked               = { handleAudioSeeked }
                    onAudioProgress             = { handleAudioProgress } 
                    onAudioListsChange          = { handleAudioListChange }
                    sortableOptions             = {{ 
                        disabled: !Boolean(sortable) 
                    }}
                    // locale                  = {i18n.language} // https://github.com/lijinke666/react-music-player#bulb-internationalization
                    volumeFade                  = {{
                        fadeIn  : fadems, 
                        fadeOut : fadems
                    }}
                    {...props}
                />
                {   
                    false &&
                    <Box position={"fixed"} style={{top:200,left:0,background:'red'}}>
                        {top} {bottom} {hidden ? "hidden" : "not hidden"}
                    </Box>
                }
            </ErrorBoundary>
        </RootContainer>
    )
}

export default LibraryJinkePlayer;