/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useUpdateQueryParams
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       26th November 2024

*******************************************************************************************/
import React from 'react';

const obj = {};

// Utility to update multiple query parameters
export const useUpdateQueryParams = (setQuery) => (
    React.useCallback(
        (params = obj) => {
            if (typeof setQuery !== 'function') {
                console.error('Invalid argument: setQuery is not a function');
                return;
            }
            if (!params || typeof params !== 'object') {
                console.error('Invalid argument: params should be an object');
                return;
            }
            setQuery((prevQuery) => ({
                ...prevQuery,
                ...Object.fromEntries(
                    Object.entries(params).filter(([, value]) => value !== undefined) // Prune undefined values
                ),
            }))
        },
        [setQuery]
    )
);

export default useUpdateQueryParams;
