/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Global Loader
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       31st January 2021

*******************************************************************************************/

import React                            from 'react';
import { styled, Box, Typography }      from '@mui/material';
import { LoadingData }                  from 'components';
import { useNetwork }                   from 'contexts';
import { useLoader }                    from 'hooks';
import { withTranslation }              from './hoc';

const ChildContainer = styled(Box)(({theme}) => ({
    paddingLeft     : theme.spacing(1),
    marginTop       : 'auto',
    marginBottom    : 'auto',
    display         : 'inline-block' 
}));

export const GlobalLoader = withTranslation( ({t, color="inherit"}) => {
    const {isNetworkReady}  = useNetwork();
    const [loading,count]   = useLoader();
    if(!loading || !isNetworkReady)          
        return null;
    return (
        <Box align="center" sx={{p:1}}>
            <Box display="flex" flexDirection="row" justifyContent="center">
                <ChildContainer>
                    <LoadingData color={color} size={20}/>
                </ChildContainer>
                <ChildContainer style={{color}}>
                    <Typography variant="body2" color={color}>
                        { t('components.globalLoader.loadingCount',{count}) }
                    </Typography>
                </ChildContainer>
            </Box>
        </Box>
    );
});

export default GlobalLoader;

