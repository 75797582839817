/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Loading Data
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       19th July 2021

*******************************************************************************************/
import React                            from 'react';
import { 
    Box, 
    Typography, 
    CircularProgress
}    
                                        from '@mui/material';
import { useTranslation }               from 'contexts'; 

const obj = {};

export const LoadingData = ({size = 10, color="secondary", label=undefined, TypographyProps=obj, ...props}) => {
    const {t}                   = useTranslation();
    const {children, ...rest}   = props
    return (
        <Box {...rest}>
            <Box display="inline-block" algin="center">
                <CircularProgress size={size} sx={{color}}/>
            </Box>
            {
                (children || label) && 
                <Box display="inline-block" ml={0.5}>
                    <Typography component="div" pt={0} {...TypographyProps} style={{fontSize:size}}>
                        {children || label || t('common.loading')}
                    </Typography>
                </Box>
            }
        </Box>
    )
}

export default LoadingData;