/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
User Outer Click Hook
********************************************************************************************
Alternate to MUI's clickawaylistener
https://stackoverflow.com/a/54292872/1834057

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th July 2021

*******************************************************************************************/
import React from 'react';

export const useOuterClick = (callback) => {
    const callbackRef   = React.useRef(); // initialize mutable callback ref
    const innerRef      = React.useRef(); // returned to client, who sets the "border" element
  
    // update callback on each render, so second useEffect has most recent callback
    React.useEffect(() => { callbackRef.current = callback; });
    React.useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if (innerRef.current && callbackRef.current && 
                !innerRef.current.contains(e.target)
            ) callbackRef.current(e);
        }
    }, []); // no dependencies -> stable click listener
        
    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

export default useOuterClick;