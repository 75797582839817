/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Biography Skeleton
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Skeleton as SkeletonMUI
}                               from '@mui/material';
import { 
    VerticalSpaceContainer
}                               from 'components';

const SkeletonParagraph = ({lines = 5, lastWidth = "75%", ...props}) => {
    return (
        <Box>
            {  
                lines > 1 && 
                Array(lines - 1).fill(undefined).map((_,ix) => (
                    <SkeletonMUI variant="text" key={ix} {...props}/>
                ))
            }
            <SkeletonMUI variant="text" {...props} width={lastWidth}/>
        </Box>
    )
}

export const ExampleBiographySkeleton = () => {
    return (
        <VerticalSpaceContainer>
            <SkeletonParagraph lines={3}    lastWidth="40%"/>
            <SkeletonParagraph lines={10}   lastWidth="75%"/>
            <SkeletonParagraph lines={5}    lastWidth="50%"/>
        </VerticalSpaceContainer>
    )
};

export default ExampleBiographySkeleton;