/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
useWindowSize Hook
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       20th July 2022

*******************************************************************************************/

import React from 'react';

export const useWindowSize = () => {

    const hasWindow         = React.useMemo(() => typeof window !== 'undefined', []);
    const [size, setSize]   = React.useState({width:null, height:null});
    const handleResize      = React.useCallback(() => {
        const width  = hasWindow ? window.innerWidth  : null;
        const height = hasWindow ? window.innerHeight : null;
        setSize({
            width, 
            height
        })
    }, [hasWindow]);

    React.useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize]);

    return size;
}

export default useWindowSize;