/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
Example Biography Content
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       6th October 2024

*******************************************************************************************/
import React                    from 'react';
import {
    Box,
    Typography,
}                               from '@mui/material';
import { 
    Linkify,
}                               from 'components';
import { useExample }           from './ExampleContext';

export const ExampleBiographyContent = () => {
    const {data} = useExample();
    if(!data?.biography)
        return null;
    return (
        <Box>
            <Linkify disabled={false} sanitize={false}>
                {
                    (data?.biography || '')
                        .split('\n')
                        .filter(Boolean)
                        .map((paragraph,ix) => (    
                            <Typography key={ix} paragraph sx={{textAlign:'justify'}}>
                                {paragraph}
                            </Typography>
                        ))
                }
            </Linkify>
        </Box>
    )
};

export default ExampleBiographyContent;