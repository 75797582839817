/*******************************************************************************************
   _____            __              ____   ____                         .__               
  /  _  \   _______/  |________  ___\   \ /   /____   ____  __ __  _____|__|____    ____  
 /  /_\  \ /  ___/\   __\_  __ \/  _ \   Y   // __ \ /    \|  |  \/  ___/  \__  \  /    \ 
/    |    \\___ \  |  |  |  | \(  <_> )     /\  ___/|   |  \  |  /\___ \|  |/ __ \|   |  \
\____|__  /____  > |__|  |__|   \____/ \___/  \___  >___|  /____//____  >__(____  /___|  /
        \/     \/                                 \/     \/           \/        \/     \/ 
********************************************************************************************
withFeatureBox HOC
********************************************************************************************

Author:     Nicholas Hamilton, PhD
Email:      nicholasehamilton@gmail.com
Date:       9th May 2021

*******************************************************************************************/
import React            from 'react';
import { FeatureBox }   from 'components';

export const withFeatureBox = title => Component => {
    return function WrappedComponent(props) {
        return (
            <div sx={{width:'100%',color : theme => theme.palette.primary.main}}>
                <FeatureBox title={title}>
                    <Component {...props} />
                </FeatureBox>
            </div>
        )
    }
}

export default withFeatureBox;